// import node module libraries
import { Card, Form, Button, Spinner } from 'react-bootstrap';

import { useFetchData } from 'hooks/useFetchData';
import { useEffect } from 'react';
import { usePostData } from 'hooks/usePostData';
import AileadButton from 'components/marketing/common/button/AileadButton';


const UpdateCoursePrices = (props) => {
	const { next, previous, formData, setFormData, handleInputChange } = props;
    const {data} = useFetchData('/course/currencies');
    const {response: validationResponse, loading: loadingValidation, error: validationErrors, postData} = usePostData('/course/validate/prices');

    const nextStep = () => {
		postData({prices: formData.step2});
	}

    useEffect(() => {
		if(validationResponse !== null && validationResponse?.status){
			next();
		}

	}, [validationResponse])


	return (
		<Form>
            {/* Card */}
            <Card className="mb-3">
                <Card.Header className="border-bottom px-4 py-3">
                <h4 className="mb-0">Course Prices</h4>
                </Card.Header>
                {/* Card body */}
                <Card.Body>
                    { formData.step2?.map((currency, index) => (
                        <div key={index}>
                            {/* Currency */}
                            <Form.Group className="mb-3">
                                <Form.Label>{currency.name} ({currency.symbol})</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Enter Amount"
                                    value={currency.amount}
                                    onChange={(e) => handleInputChange(index, 'amount', e.target.value)}
                                />

                            </Form.Group>
                            {validationErrors && (
                                <Form.Text className="col-sm-6 text-danger mb-4 mt-4">
                                    {validationErrors['prices.'+ index +'.amount']}
                                </Form.Text>
                            )}
                        </div>
                    ))}
                </Card.Body>
            </Card>
            {/* Button */}
            <div className="d-flex justify-content-between">
                <AileadButton text="Previous" variant="secondary" onClick={previous} type="button" />
                <AileadButton type="button" text="Next"  variant="primary" onClick={nextStep} disabled={loadingValidation} />
            </div>
		</Form>
	);
};
export default UpdateCoursePrices;
