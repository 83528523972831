
import { Card } from 'react-bootstrap';
import { Fragment, useState } from 'react';
import Modules from './Modules';
import { AddModuleModal } from '../steps/modals/AddModuleModal';
import { curriculumData } from 'helper/utils';
import DropArea from './DropArea';

const CurriculumCard = ({ formData, setFormData }) => {

    const [activeModule, setActiveModule] = useState(null);
    const [activeLesson, setActiveLesson] = useState(null);
    
    const addModule = (newSection) => {
		  curriculumData("addModule", setFormData, null, null, newSection);
	  }; 

    const onLessonDrop = (targetModulePosition, lessonPosition) => {
      if (activeLesson === null || activeLesson === undefined) return;
    
      const modulesCopy = [...formData.step4.modules]; // Make a copy of the modules array
    
      // Get the lesson to move from the active module
      const lessonToMove = modulesCopy[activeModule].lessons[activeLesson];
    
      if (activeModule === targetModulePosition) {
        // Moving lesson within the same module
        const updatedLessons = [...modulesCopy[activeModule].lessons];
        const sourceIndex = activeLesson;
        let targetIndex = lessonPosition;
    
        // Adjust target index if moving the lesson within the same module
        if (sourceIndex < targetIndex) {
          targetIndex--; // Decrement target index if source index is less than target index
        }
    
        // Remove the lesson from its original position and insert it at the new position
        updatedLessons.splice(sourceIndex, 1);
        updatedLessons.splice(targetIndex, 0, lessonToMove);
    
        // Update the lessons array of the active module
        modulesCopy[activeModule].lessons = updatedLessons;
      } else {
        // Moving lesson to a different module
        const updatedSourceModuleLessons = modulesCopy[activeModule].lessons.filter((lesson, index) => index !== activeLesson);
        const updatedTargetModuleLessons = [...modulesCopy[targetModulePosition].lessons];
        updatedTargetModuleLessons.splice(lessonPosition, 0, lessonToMove);
    
        // Update the lessons array of the active module and the target module
        modulesCopy[activeModule].lessons = updatedSourceModuleLessons;
        modulesCopy[targetModulePosition].lessons = updatedTargetModuleLessons;
      }
    
      // Update the modules array
      const updatedModules = modulesCopy.map((module, index) => {
        if (index === activeModule || index === targetModulePosition) {
          return {
            ...module,
            lessons: modulesCopy[index].lessons.map((lesson, i) => {
              return {
                ...lesson,
                order: i + 1
              }
            })
          };
        } else {
          return module;
        }
      });
    
      // Update the form data with the modified modules array
      setFormData(prevFormData => ({
        ...prevFormData,
        step4: {
          ...prevFormData.step4,
          modules: updatedModules
        }
      }));
    };
    

    const onModuleDrop = (targetModulePosition) => {
      const modulesCopy = [...formData.step4.modules]; // Make a copy of the modules array
    
      // Remove the module from its original position
      const [movedModule] = modulesCopy.splice(activeModule, 1);
    
      // Insert the module into the target position
      modulesCopy.splice(targetModulePosition, 0, movedModule);
    
      // Update the modules array in the form data
      setFormData(prevFormData => ({
        ...prevFormData,
        step4: {
          ...prevFormData.step4,
          modules: modulesCopy.map((module, index) => {
            return {
              ...module,
              order: index + 1
            }
          })
        }
      }));
    };
    
    return (
      <Card.Body>
        <Fragment>
          <DropArea title={"Module"} onDrop={() => onModuleDrop(0)} />
          {formData.step4.modules.map((module, index) => (
              <Fragment key={index}>
                <Modules 
                  key={index}
                  module={module} 
                  moduleIndex={index} 
                  formData={formData} 
                  setFormData={setFormData} 
                  handleActiveModule={setActiveModule}
                  handleActiveLesson={setActiveLesson}
                  onLessonDrop={onLessonDrop}
                  
                />
                <DropArea title={"Module"} onDrop={() => onModuleDrop(index + 1)} />
              </Fragment>
          ))}
        </Fragment>     
        <AddModuleModal addModule={addModule} formData={formData} />
      </Card.Body>
    );    
};
export default CurriculumCard;
