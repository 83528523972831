// import node module libraries
import React, { Fragment } from 'react';
import { Col, Row, Table } from 'react-bootstrap';

// import custom components
import NewPagination from 'components/elements/advance-table/NewPagination';
import { InstructorData, CourseInitialColumn, PurchaseWithSubcriptionCredit, CourseStatus } from './common/Components';

const SubscriptionBasedCourse = ({
    courses_data,
    handlePageChange,
    currentPage,
    lastPage,
    searchValue,
    onSearchChange,
    creditBalance
}) => {
	
	return (
		<Fragment>
			<div className="overflow-hidden">
				<Row>
                    {
                        creditBalance && 
                            <Col lg={12} md={12} sm={12} style={{
                                display: "flex", 
                                alignItems: "center", 
                                justifyContent: "center",
                                padding: 8
                            }}>
                                <div style={{textAlign: "center"}}>
                                    <h3>Credit Balance</h3>
                                    <span style={{
                                        fontSize: "24px",
                                        color: "#4CAF50", 
                                    }}>
                                        {creditBalance}
                                    </span>
                                </div>
                            </Col>
                    }

					<Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 py-4 px-5 ">
                        <input
                            type="search"
                            className="form-control"
                            placeholder={"Search Course"}
                            value={searchValue}
                            onChange={onSearchChange}
                        />
					</Col>
				</Row>
			</div>

			<div className="table-responsive overflow-y-hidden bg-light">
				<Table
					hover
					className="text-nowrap table-centered"
				>
					<thead className="table-light">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Course</th>
                            <th scope="col">Instructor</th>
                            <th scope="col">Status</th>
                            <th scope="col"></th>
                        </tr>
					</thead>
					<tbody>
                        {courses_data?.data?.data && courses_data?.data?.data.map((course, index) => (
                            <tr key={course.id}>
                                <td>{index + 1}</td>
                                <td><CourseInitialColumn course={course} /></td>
                                <td><InstructorData row={course} /></td>
                                <td><CourseStatus value={course} /></td>
                                <td><PurchaseWithSubcriptionCredit course={course}  /></td>
                            </tr>
                        ))}
					</tbody>
				</Table>
			</div>

			{/* Pagination @ Footer */}
			<NewPagination
                currentPage={currentPage}
                lastPage={lastPage}
                onPageChange={handlePageChange}
			/>
		</Fragment>
	);
};

export default SubscriptionBasedCourse;
