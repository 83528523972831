// import node module libraries
import React, { Fragment } from 'react';
import ReactPlayer from 'react-player';
import { usePostData } from 'hooks/usePostData';

const VideoPlayer = ({ url,  lessonId, courseId}) => {
	const {postData} = usePostData("/user/lesson/status");
	const VIDEO_BASE_URL = "https://di36hqkf8qmv6.cloudfront.net";
	const handleVideoEnd = () => {
	  postData({lessonId: lessonId, courseId: courseId});
	};

	return (
		<Fragment>
            <ReactPlayer 
				url={`${url}`}
				controls
				className="position-absolute top-0 end-0 start-0 end-0 bottom-0 h-100 w-100"  
				onEnded={handleVideoEnd}
			/>
		</Fragment>
	);
};

export default VideoPlayer;
