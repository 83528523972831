// Import node module libraries
import { Form } from 'react-bootstrap';
import { useFetchData } from 'hooks/useFetchData';

const ModuleSelect = ({ module, handleModule, courseId, validationErrors }) => {
    const { data, loading } = useFetchData(courseId ? `/course/modules/${courseId}` : null);

    return (
        <>
            {/* Module */}
            <Form.Group className="mb-3">
                <Form.Label>
                    Select Course Module (optional)
                </Form.Label>
                <Form.Select
                    placeholder='Select Module'
                    value={module}
                    onChange={handleModule}
                    disabled={loading || !courseId}
                >
                    <option value="">Select Module</option>
                    {data?.data && data.data.map((item) => (
                        <option key={item.id} value={item.id}>
                            {item.title}
                        </option>
                    ))}
                </Form.Select>
                {validationErrors && validationErrors.module ? (
                    <Form.Text className="col-sm-6 text-danger mb-4 mt-4">
                        {validationErrors.module[0]}
                    </Form.Text>
                ) : (
                    <Form.Text className="text-muted">
                        Select the course Module that is related to this quiz.
                    </Form.Text>
                )}
            </Form.Group>
        </>
    );
};

export default ModuleSelect;
