import { useEffect, useState } from 'react';

function useUserTimezone() {
  const [userTimezone, setUserTimezone] = useState('UTC'); // Default to UTC

  useEffect(() => {
    async function fetchUserTimezone() {
      const userLocale = navigator.language;

      try {
        const [timezone] = Intl.DateTimeFormat(userLocale, { timeZoneName: 'short' })
          .resolvedOptions()
          .timeZone.split(' ');
        setUserTimezone(timezone);
      } catch (error) {
        console.error('Error getting user timezone:', error);
      }
    }

    fetchUserTimezone();
  }, []);

  return {userTimezone};
}

export default useUserTimezone;
