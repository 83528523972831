// import node module libraries
import React, { useContext, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import {
	Accordion,
	Card,
	useAccordionButton,
	AccordionContext,
	OverlayTrigger,
	Tooltip
} from 'react-bootstrap';
import { AddQuestionModal } from 'components/dashboard/courses/quiz/steps/modals/AddQuestionModal';
import { AddOptionsModal } from 'components/dashboard/courses/quiz/steps/modals/AddOptionsModal';
import AileadButton from '../button/AileadButton';

const GKAccordionQuiz = ({ accordionItems, index, questionIndex, formData, addOption, removeOption, addQuestion }) => {
	const ContextAwareToggle = ({ children, eventKey, callback }) => {
		const currentEventKey = useContext(AccordionContext);
		const decoratedOnClick = useAccordionButton(
			eventKey,
			() => callback && callback(eventKey)
		);
		const isCurrentEventKey = currentEventKey === eventKey;
		const overlayKeyEdit = uuid();
		const overlayKeyDelete = uuid();

		return (
			<Fragment>
				<div className="d-flex align-items-center justify-content-between">
					<h5 className="mb-0">
						<Link
							onClick={decoratedOnClick}
							aria-expanded={isCurrentEventKey}
							to="#"
							className="text-inherit"
						>
							<span className="align-middle p-1">{children}</span>
						</Link>
					</h5>
					<div>
						<OverlayTrigger
							key={overlayKeyEdit}
							placement="top"
							overlay={<Tooltip id="tooltip-top"> Edit</Tooltip>}
						>
							<AddOptionsModal 
                                formData={formData} 
                                initialOption={accordionItems} 
                                questionIndex={questionIndex} 
                                addOption={addOption} 
                                optionIndex={index} 
                            />
						</OverlayTrigger>

						<OverlayTrigger
							key={overlayKeyDelete}
							placement="top"
							overlay={<Tooltip id="tooltip-top"> Delete</Tooltip>}
						>
							<AileadButton 
								onClick={() => removeOption(questionIndex, index)} 
								className="me-1 text-inherit" 
								text="Delete" 
								type="button" 
							/>
						</OverlayTrigger>

						<Link
							to="#"
							className="text-inherit"
							data-bs-toggle="collapse"
							onClick={decoratedOnClick}
							aria-expanded={isCurrentEventKey}
						>
							<span className="chevron-arrow">
								<i className="fe fe-chevron-down fs-5"></i>
							</span>
						</Link>
					</div>
				</div>
			</Fragment>
		);
	};

	const generateKey = (pre) => {
		if(pre){
			pre = pre.toLowerCase();
			pre = pre.replace(' ', '_');
			return `${pre}_${new Date().getTime()}`;
		}
	};
	return (
		<Fragment>
			<Accordion defaultActiveKey={accordionItems.title ? accordionItems.title : accordionItems.content}>
					<Card
						key={generateKey(accordionItems.title ? accordionItems.title + index : accordionItems.content + index)}
						className="px-2 py-2 mb-1 shadow-none"
					>
						<Card.Header className="bg-transparent border-0 p-0">
							<div className="border-0">
								<h3 className="mb-0 fw-bold">
									<ContextAwareToggle eventKey={accordionItems.title ? accordionItems.title + index : accordionItems.content + index}>
										{accordionItems.title ? accordionItems.title : accordionItems.content}
									</ContextAwareToggle>
								</h3>
							</div>
						</Card.Header>
						<Accordion.Collapse eventKey={accordionItems.title ? accordionItems.title + index : accordionItems.content + index}>
							<Card.Body className="fs-4">
								{/* {accordionItems.content}	 */}
								<p>Option : {accordionItems.content}</p>
								<p>Answer : {accordionItems.isCorrect.toString()}</p>
							</Card.Body>
						</Accordion.Collapse>
					</Card>
			</Accordion>
		</Fragment>
	);
};

export default GKAccordionQuiz;
