// import node module libraries
import React from 'react';
import { useParams } from 'react-router-dom';
// import { Dropdown } from 'react-bootstrap';

// import sub custom components
import SendMessage from './SendMessage';

// import bootstrap icons
// import { EmojiSmile, Paperclip, Mic } from 'react-bootstrap-icons';

const ChatFooter = () => {

	const { groupId, threadId } = useParams();
	// const ToggleActions = React.forwardRef(({ children, onClick }, ref) => (
	// 	<Link
	// 		to="#"
	// 		className="text-link fs-4"
	// 		ref={ref}
	// 		onClick={(e) => {
	// 			e.preventDefault();
	// 			onClick(e);
	// 		}}
	// 	>
	// 		{children}
	// 	</Link>
	// ));

	return (
		<>
			{groupId && threadId && (<div className="bg-white border-bottom border-bottom px-4 py-3 sticky-bottom">
				<div className="d-flex justify-content-between align-items-center">
						
				<SendMessage />
				{/* <div className="mt-3 d-flex">
					<div>
						<Link to="#" className="text-link me-2">
							{' '}
							<EmojiSmile size={16} />
						</Link>
						<Link to="#" className="text-link me-2">
							{' '}
							<Paperclip size={16} />
						</Link>
						<Link to="#" className="text-link me-2">
							{' '}
							<Mic size={16} />
						</Link>
					</div>
					<Dropdown>
						<Dropdown.Toggle as={ToggleActions}>
							<i className="fe fe-more-horizontal"></i>
						</Dropdown.Toggle>
						<Dropdown.Menu as="ul">
							{['Action', 'Another action', 'Something else here'].map(
								(item, index) => {
									return (
										<Dropdown.Item eventKey="1" as="li" bsPrefix=" " key={index}>
											<Link to="#" className="dropdown-item">
												{item}
											</Link>
										</Dropdown.Item>
									);
								}
							)}
						</Dropdown.Menu>
					</Dropdown>
				</div> */}
				</div>
			</div>)}
		</>
	);
};
export default ChatFooter;
