import { Card, Form } from 'react-bootstrap';
import { useEffect } from 'react';
import { usePostData } from 'hooks/usePostData';
import AileadButton from 'components/marketing/common/button/AileadButton';

const CoursePrice = (props) => {
    const { next, previous, formData, setFormData, handleInputChange } = props;
    const { response: validationResponse, loading: loadingValidation, error: validationErrors, postData } = usePostData('/course/validate/prices');

    const nextStep = () => {
        postData({ prices: formData.step2 });
    };

    useEffect(() => {
        if (validationResponse !== null && validationResponse?.status) {
            next();
        }
    }, [next, validationResponse]);

    return (
        <Form>
            {/* Card */}
            <Card className="mb-3">
                <Card.Header className="border-bottom px-4 py-3">
                    <h4 className="mb-0">Course Prices</h4>
                </Card.Header>
                {/* Card body */}
                <Card.Body>
                    {/* Currency and Amount fields */}
                    {formData.step2?.map((currency, index) => (
                        <div key={index}>
                            {/* Currency */}
                            <Form.Group className="mb-3">
                                <Form.Label>{currency.name} ({currency.symbol})</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Enter Amount"
                                    value={currency.amount}
                                    onChange={(e) => handleInputChange(index, 'amount', e.target.value)}
                                />

                            </Form.Group>
                            {validationErrors && (
                                <Form.Text className="col-sm-6 text-danger mb-4 mt-4">
                                    {validationErrors['prices.'+ index +'.amount']}
                                </Form.Text>
                            )}
                        </div>
                    ))}

                </Card.Body>
            </Card>
            {/* Button */}
            <div className="d-flex justify-content-between">
                <AileadButton type="button" variant="secondary" onClick={previous} text="Previous" />
                <AileadButton type="button" variant="primary" onClick={nextStep} loading={loadingValidation} text="Next" />
            </div>
        </Form>
    );
};

export default CoursePrice;
