import React from 'react';
import { Row, Col, Button, ListGroup } from 'react-bootstrap';

const NewPagination = ({ currentPage, lastPage, onPageChange }) => {

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < lastPage) {
      onPageChange(currentPage + 1);
    }
  };

  const handleGotoPage = (page) => {
    onPageChange(page);
  };

  return (
    <Row>
      <Col lg={12} md={12} sm={12}>
        <div className="pb-5">
          <nav>
            <ListGroup
              as="ul"
              bsPrefix="pagination"
              className="justify-content-center mb-0"
            >
              <ListGroup.Item as="li" bsPrefix="page-item">
                <Button
                  onClick={handlePreviousPage}
                  className={`page-link mx-1 rounded ${currentPage === 1 ? 'disabled' : ''}`}
                  disabled={currentPage === 1}
                >
                  <i className="fe fe-chevron-left"></i>
                </Button>
              </ListGroup.Item>
              
              {Array.from(Array(lastPage).keys()).map((page) => (
                <ListGroup.Item
                  as="li"
                  bsPrefix="page-item"
                  key={page}
                  className={`page-item ${currentPage === page + 1 ? 'active' : ''}`}
                >
                  <Button
                    className="page-link mx-1 rounded"
                    onClick={() => handleGotoPage(page + 1)}
                  >
                    {page + 1}
                  </Button>
                </ListGroup.Item>
              ))}

              <ListGroup.Item as="li" bsPrefix="page-item">
                <Button
                  onClick={handleNextPage}
                  className={`page-link mx-1 rounded ${currentPage === lastPage ? 'disabled' : ''}`}
                  disabled={currentPage === lastPage}
                >
                  <i className="fe fe-chevron-right"></i>
                </Button>
              </ListGroup.Item>
            </ListGroup>
          </nav>
        </div>
      </Col>
    </Row>
  );
};

export default NewPagination;
