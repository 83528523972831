// import node module libraries
import { Card, Form, Button, Image } from 'react-bootstrap';

// import custom components
import ReactQuill from 'react-quill';
import { useFileUpload } from 'hooks/useFileUpload';
import { usePostData } from 'hooks/usePostData';
import { useFetchData } from 'hooks/useFetchData';
import { useEffect, useRef, useState } from 'react';
import { notifySuccess } from 'helper/utils';
import { useNavigate } from 'react-router-dom';


const PlanForm = () => {

	const {response, loading: planLoading, error: planError, postData} = usePostData('/plan/create');
	const {data, loading, error} = useFetchData('/course/currencies');

	const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [priceMonth, setPriceMonth] = useState([
		{'currency': '', 'code': '', 'symbol': '', 'amount': 0},
	]);

    const [priceYear, setPriceYear] = useState([
		{'currency': '', 'code': '', 'symbol': '', 'amount': 0},
	]);

	const addInputSet = (type) => {
		if(type === 'yearly'){
			setPriceYear(prevState => [...prevState, {'currency': '', 'code': '', 'symbol': '', 'amount': 0}]);
		} else {
			setPriceMonth(prevState => [...prevState, {'currency': '', 'code': '', 'symbol': '', 'amount': 0}]);
		}
    };

    const navigate = useNavigate();

	const handleInputChange = (index, property, value, type) => {
		if (type === 'yearly') {
			setPriceYear((prevState) => {
				const newState = [...prevState];
				newState[index][property] = value;
				return newState;
			});
		} else {
			setPriceMonth((prevState) => {
				const newState = [...prevState];
				newState[index][property] = value;
				return newState;
			});
		}
	};

	const handleSelectInputChange = (index, currency, symbol, code, type) => {
		if (type === 'yearly') {
			setPriceYear((prevState) => {
				const newState = [...prevState];
				newState[index]['currency'] = currency;
				newState[index]['symbol'] = symbol;
				newState[index]['code'] = code;
				return newState;
			});
			
		} else {
			setPriceMonth((prevState) => {
				const newState = [...prevState];
				newState[index]['currency'] = currency;
				newState[index]['symbol'] = symbol;
				newState[index]['code'] = code;
				return newState;
			});
		}
	};
	
	const removeInputSet = (index, type) => {
		if (type === 'yearly') {
			setPriceYear((prevState) => prevState.filter((item, i) => i !== index));
		} else {
			setPriceMonth((prevState) => prevState.filter((item, i) => i !== index));
		}
	};

	const submit = () => {
		postData({
			"title":title,
			"description":description ,
			"priceMonthly": priceMonth,
			"priceYearly": priceYear,
		});
	}


	useEffect(() => {
		if(response !== null && response?.status){
            notifySuccess("Successfully added a plan!");
            navigate("/dashboard/plans");
		}

	}, [response])

	return (
		<Form>
			{/* Card */}
			<Card className="mb-3 ">
				<Card.Header className="border-bottom px-4 py-3">
					<h4 className="mb-0">Plan</h4>
				</Card.Header>
				{/* Card body */}
				<Card.Body>
					{/* Title  */}
					<Form.Group className="mb-3">
						<Form.Label htmlFor="courseTitle">Plan Title <Form.Text className='text-danger'>*</Form.Text></Form.Label>
						<Form.Control
							type="text"
							placeholder="Plan Title"
							value={title}
							onChange={(e) => setTitle(e.target.value)}
						/>

						{error && error?.title && (
							<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
								{error.title[0]}
							</Form.Text>
						)}
					</Form.Group>

					{priceMonth.map((set, index) => (
                        <div key={index}>
                            {/* Currency Selection */}
                            <Form.Group className="mb-3">
                                <Form.Label>Select Monthly Payment <Form.Text className='text-danger'>*</Form.Text></Form.Label>
                                <Form.Select
                                    placeholder="Select Currency"
                                    name="currency"
                                    value={set.currency}
                                    onChange={(e) => {
										const selectedOption = e.target.options[e.target.selectedIndex];
										const symbol = selectedOption.dataset.symbol;
        								const code = selectedOption.dataset.code;
        								handleSelectInputChange(index, e.target.value, symbol, code);

									}}
                                >
                                    <option value=""></option>
                                    {data?.data && data?.data.map((item) => (
                                        <option key={item.id} value={item.id} data-symbol={item.symbol} data-name={item.name} data-code={item.code}>
                                            {item.symbol} {" "} {item.name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                           
                            {/* Course Price */}
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor={`amount_${index}`}>Plan Price <Form.Text className='text-danger'>*</Form.Text></Form.Label>
                                <Form.Control
                                type="number"
                                placeholder="Plan Price"
                                id={`amount_${index}`}
                                name={`amount_${index}`}
                                value={set.amount}
                                onChange={(e) => handleInputChange(index, 'amount', e.target.value)}
                                />
                                <Form.Text className="text-muted">Choose an honest price.</Form.Text>
                            </Form.Group>

                            {/* Button to remove the set */}
                            {index > 0 && (
                                <Button variant="danger" className="mb-5 btn" onClick={() => removeInputSet(index)}>
                                    Remove Set
                                </Button>
                            )}

                        </div>
                    ))}

                    {/* Button to add more input sets */}
                    <Button variant="primary" onClick={addInputSet}>
                        Add Monthly Currency
                    </Button>

					{priceYear.map((set, index) => (
                        <div key={index}>
                            {/* Currency Selection */}
                            <Form.Group className="mb-3">
                                <Form.Label>Select Yearly Payment <Form.Text className='text-danger'>*</Form.Text></Form.Label>
                                <Form.Select
                                    placeholder="Select Currency"
                                    name="currency"
                                    value={set.currency}
                                    onChange={(e) => {
										const selectedOption = e.target.options[e.target.selectedIndex];
										const symbol = selectedOption.dataset.symbol;
        								const code = selectedOption.dataset.code;
        								handleSelectInputChange(index, e.target.value, symbol, code, 'yearly');
									}}
                                >
                                    <option value=""></option>
                                    {data?.data && data?.data.map((item) => (
                                        <option key={item.id} value={item.id} data-symbol={item.symbol} data-name={item.name} data-code={item.code}>
                                            {item.symbol} {" "} {item.name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                           
                            {/* Course Price */}
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor={`amount_${index}`}>Plan Price <Form.Text className='text-danger'>*</Form.Text></Form.Label>
                                <Form.Control
                                type="number"
                                placeholder="Plan Price"
                                id={`amount_y_${index}`}
                                name={`amount_y_${index}`}
                                value={set.amount}
                                onChange={(e) => handleInputChange(index, 'amount', e.target.value, 'yearly')}
                                />
                                <Form.Text className="text-muted">Choose an honest price.</Form.Text>
                            </Form.Group>

                            {/* Button to remove the set */}
                            {index > 0 && (
                                <Button variant="danger" className="mb-5 btn" onClick={() => removeInputSet(index, 'yearly')}>
                                    Remove Set
                                </Button>
                            )}

                        </div>
                    ))}

                    {/* Button to add more input sets */}
                    <Button variant="primary" onClick={() => addInputSet('yearly')}>
                        Add Yearly Currency
                    </Button>

					{/* Course Description*/}
					<Form.Group className="mb-3">
						<Form.Label>Plan Description</Form.Label>
						<ReactQuill
						   theme="snow"
                           value={description}
						   onChange={(content, delta, source, editor) => {
								//const value = editor.getContents();  // Adjust this line based on what you need
								setDescription(content);
							}}
                        />
						<Form.Text className="text-muted">
							Any additional information about this quiz.
						</Form.Text>
					</Form.Group>
					{error && error?.description && (
						<Form.Group className="mb-2">
							<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
								{error.description[0]}
							</Form.Text>
						</Form.Group>
					)}

				</Card.Body>
			</Card>
			{/* Button */}
			<Button variant="primary" onClick={submit} >
				Next
			</Button>
		</Form>
	);
};
export default PlanForm;
