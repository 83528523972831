// import node module libraries
import React, { Fragment, useState, useEffect } from 'react';
import { Col, Row, Tab, Card, Nav, Breadcrumb } from 'react-bootstrap';

// import sub custom components
import CoursesTable from './CoursesTable';
import { useServerFilter } from 'hooks/useServerFilter';
import MyCoursesTable from './MyCoursesTable';
import SubscriptionBasedCourse from './SubscriptionBasedCourse';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { usePostData } from 'hooks/usePostData';
import { useLocation } from 'react-router-dom';
import { notifyError, notifySuccess } from 'helper/utils';

const BusinessCourses = () => {

	const BASE_URL = process.env.REACT_APP_API_BASE_URL;
	const {user} = useSelector(state => state.auth);
	const authToken = user && user?.token;
	const location = useLocation();

	const fetchAllCourseData = async (searchQuery, pageNumber) => {
		if (authToken !== null) {
			axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
			const response = await axios.get(`${BASE_URL}/business/courses?page=${pageNumber}&searchQuery=${searchQuery}`);
			return response.data;
		}
	};

	const fetchPurchasedData = async (searchQuery, pageNumber) => {
		if (authToken !== null) {
			axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
			const response = await axios.get(`${BASE_URL}/business/courses/purchased?page=${pageNumber}&searchQuery=${searchQuery}`);
			return response.data;
		}
	};

	const fetchSubscriptionData = async (searchQuery, pageNumber) => {
		if (authToken !== null) {
			axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
			const response = await axios.get(`${BASE_URL}/business/courses/subscription?page=${pageNumber}&searchQuery=${searchQuery}`);
			return response.data;
		}
	};

	const {
		filteredData: courseData,
		searchQuery: courseSearch,
		pageNumber,
		handlePageChange,
		handleSearchChange: onCourseSearchChange
	} = useServerFilter('', fetchAllCourseData);

	const {
		filteredData: purchaseData, 
		searchQuery: purchaseSearch,
		pageNumber: purchasePageNumber,
		handlePageChange: handlePurchasePageChanged, 
		handleSearchChange: onPurchaseSearchChange
	} = useServerFilter('', fetchPurchasedData);

	const {
		filteredData: subscriptionData, 
		searchQuery: subscriptionSearch,
		pageNumber: subscriptionPageNumber,
		handlePageChange: handleSubscriptionPageChanged, 
		handleSearchChange: onSubscriptionSearchChange
	} = useServerFilter('', fetchSubscriptionData);

	const lastPage = courseData && courseData?.data && courseData?.data?.pagination?.lastPage;
	const purchaseLastPage = purchaseData && purchaseData?.data && purchaseData?.data?.pagination?.lastPage;
	const subscriptionLastPage = subscriptionData && subscriptionData?.data && subscriptionData?.data?.pagination?.lastPage;

	// Now you can access individual query parameters
	const queryParams = new URLSearchParams(location.search);
    const identifier = queryParams.get('identifier');
    const course = queryParams.get('course');
    const type = queryParams.get('type');
    const paymentMethod = queryParams.get('paymentMethod');

    const orderId = localStorage.getItem("orderId");
    const url = identifier === 'business_subscription_purchase' ? 
                '/payment/start-sub-trial' : identifier === 'business_course_purchase' ? '/payment/enroll-course': '';
    const { postData, response } = usePostData(url);
    
    useEffect(() => {
        // send paypal payment verification
        const verifyPayment = async () => {
            if(orderId && identifier) {
                await postData({
                    identifier: identifier,
                    reference: orderId,
                    type,
                    course,
                    paymentMethod
                });
                localStorage.removeItem("orderId");
            }
        }
        verifyPayment();
    }, [orderId, identifier])

	useEffect(() => {
        // send paypal payment verification
        const verifyPayment = async () => {
            if(response && response?.status === "Success") {
                notifySuccess("Payment Successful!")
            } else if(response && response?.status === "Error") {
				notifyError("Payment Failed!")
			}
        }
        verifyPayment();
    }, [orderId, identifier])


	return (
		<Fragment>
			<Row>
				<Col lg={12} md={12} sm={12}>
					<div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
						<div className="mb-3 mb-md-0">
							<h1 className="mb-1 h2 fw-bold">All Courses</h1>
							<Breadcrumb>
								<Breadcrumb.Item href="#">Home</Breadcrumb.Item>
								<Breadcrumb.Item href="#">Courses</Breadcrumb.Item>
								<Breadcrumb.Item active>All</Breadcrumb.Item>
							</Breadcrumb>
						</div>
					</div>
				</Col>
			</Row>
			<Row>
				<Col lg={12} md={12} sm={12}>
					<Tab.Container defaultActiveKey="all">
						<Card>
							<Card.Header className="border-bottom-0 p-0 bg-white">
								<Nav className="nav-lb-tab">
									<Nav.Item>
										<Nav.Link eventKey="all" className="mb-sm-3 mb-md-0">
											All Courses
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="subscription" className="mb-sm-3 mb-md-0">
											Subscription Based Course
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="coursePurchase" className="mb-sm-3 mb-md-0">
											Purchased
										</Nav.Link>
									</Nav.Item>
								</Nav>
							</Card.Header>
							<Card.Body className="p-0">
								<Tab.Content>
									<Tab.Pane eventKey="all" className="pb-4">
										<CoursesTable 
											courses_data={courseData && courseData} 
											lastPage={lastPage}
											currentPage={pageNumber}
											handlePageChange={handlePageChange}
											onSearchChange={onCourseSearchChange}
											searchValue={courseSearch}
										/>
									</Tab.Pane>
									<Tab.Pane eventKey="coursePurchase" className="pb-4">
										{/* Add Business courses here, which has been purchased by business owners */}
										<MyCoursesTable
											courses_data={purchaseData && purchaseData}
											lastPage={purchaseLastPage}
											currentPage={purchasePageNumber}
											handlePageChange={handlePurchasePageChanged}
											onSearchChange={onPurchaseSearchChange}
											searchValue={purchaseSearch}
										/> 
									</Tab.Pane>
									<Tab.Pane eventKey="subscription" className="pb-4">
										<SubscriptionBasedCourse 
											courses_data={subscriptionData && subscriptionData} 
											lastPage={subscriptionLastPage}
											currentPage={subscriptionPageNumber}
											handlePageChange={handleSubscriptionPageChanged}
											onSearchChange={onSubscriptionSearchChange}
											searchValue={subscriptionSearch}
										/>

									</Tab.Pane>
								</Tab.Content>
							</Card.Body>
						</Card>
					</Tab.Container>
				</Col>
			</Row>
		</Fragment>
	);
};

export default BusinessCourses;
