// Import node module libraries
import React, { useEffect, useState } from 'react';
import { Modal, ListGroup, Form, InputGroup, Button, Badge, Spinner } from 'react-bootstrap';
import { Avatar } from 'components/elements/bootstrap/Avatar';
import { useFetchData } from 'hooks/useFetchData';
import { usePostData } from 'hooks/usePostData';
import AileadButton from 'components/marketing/common/button/AileadButton';
import { notifySuccess } from 'helper/utils';

const NewChatModal = (props) => {
    const [query, setQuery] = useState('');
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const { data, loading, error } = useFetchData('/user/search', { query });
	const { postData, loading: loadingAdd, response } = usePostData('/group/add');

    // Handle user selection
    const handleUserSelect = (user) => {
        if (!selectedUsers.some((selected) => selected.id === user.id)) {
            setSelectedUsers([...selectedUsers, user]);
        }
    };

    const handleUserRemove = (userId) => {
        setSelectedUsers(selectedUsers.filter(user => user.id !== userId));
    };

    // Handle course selection (only one can be selected)
    const handleCourseSelect = (course) => {
        setSelectedCourse(course);
    };

    // Handle group selection (only one can be selected)
    const handleGroupSelect = (group) => {
        setSelectedGroup(group);
    };

	const handleSubmit = () => {
		const userIds = selectedUsers.map((user, i) => user.id)
		postData({
			userIds,
			courseId: selectedCourse?.courseId,
			cohortId: selectedGroup?.cohort?.cohortId
		})
	}

	const hideModal = () => {
		props.onHide();
		// clear all state to defautl values
		setQuery("")
		setSelectedUsers([])
		setSelectedCourse(null)
		setSelectedGroup(null)
	}

	useEffect(() => {
		if(response?.status === "Success") {

			// close modal
			hideModal();

			// refresh group list
			props.setIsReload(true)

			// notify success
			notifySuccess("Group Successfully added!")
		}
	},[response])

    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Create New Group
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4">
                {/* Search Input */}
                <Form.Group className="mb-3">
                    <Form.Label>Search for Users, Courses, or Groups</Form.Label>
                    <InputGroup>
                        <Form.Control
                            type="text"
                            placeholder="Search"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                        />
                    </InputGroup>
                </Form.Group>

                {/* Loading Spinner */}
                {loading && <Spinner animation="border" />}

                {/* Error Message */}
                {error && <p className="text-danger">Error fetching results.</p>}

                {/* Results List */}
                <ListGroup className="mb-3">
                    {/* Users */}
                    {data?.data?.data?.students?.map((user, index) => (
                        <ListGroup.Item
                            key={index}
                            className="d-flex justify-content-between align-items-center"
                            onClick={() => handleUserSelect(user)}
                            style={{ cursor: 'pointer' }}
                        >
                            <div className="d-flex">
                                <Avatar
                                    size="md"
                                    className="rounded-circle"
                                    type={user.avatar ? 'image' : 'initial'}
                                    src={user.avatar}
                                    status="online"
                                    alt={user.name}
                                    name={user.name}
                                />
                                <div className="ms-2">
                                    <h5 className="mb-0">{user.name}</h5>
                                    <p className="mb-0 text-muted">{user.email}</p>
                                </div>
                            </div>
                        </ListGroup.Item>
                    ))}

                    {/* Courses (only one selectable) */}
                    {data?.data?.data?.courses?.map((course, index) => (
                        <ListGroup.Item
                            key={index}
                            className={`d-flex justify-content-between align-items-center ${selectedCourse?.courseId === course.courseId ? 'bg-primary text-white' : ''}`}
                            onClick={() => handleCourseSelect(course)}
                            style={{ cursor: 'pointer' }}
                        >
                            <div>
                                <h5 className="mb-0">{course.title}</h5>
                                <p className="mb-0 text-muted">Course ID: {course.courseId}</p>
                            </div>
                        </ListGroup.Item>
                    ))}

                    {/* Groups (only one selectable) */}
                    {data?.data?.data?.groups?.map((group, index) => (
                        <ListGroup.Item
                            key={index}
                            className={`d-flex justify-content-between align-items-center ${selectedGroup?.groupId === group.groupId ? 'bg-primary text-white' : ''}`}
                            onClick={() => handleGroupSelect(group)}
                            style={{ cursor: 'pointer' }}
                        >
                            <div>
                                <h5 className="mb-0">{group.groupName}</h5>
                                <p className="mb-0 text-muted">Group ID: {group.groupId}</p>
                            </div>
                        </ListGroup.Item>
                    ))}
                </ListGroup>

                {/* Selected Users */}
                <Form.Group className="mb-3">
                    <Form.Label>Selected Users</Form.Label>
                    <div>
                        {selectedUsers.map((user, index) => (
                            <Badge
                                key={index}
                                pill
                                bg="primary"
                                className="me-2 mb-2"
                            >
                                {user.name} ({user.email})
                                <Button
                                    size="sm"
                                    variant="light"
                                    className="ms-2"
                                    onClick={() => handleUserRemove(user.id)}
                                >
                                    &times;
                                </Button>
                            </Badge>
                        ))}
                    </div>
                </Form.Group>

                {/* Selected Course */}
                {selectedCourse && (
                    <Form.Group className="mb-3">
                        <Form.Label>Selected Course</Form.Label>
						<div>
							<Badge
								pill
								bg="success"
								className="me-2 mb-2"
							>
								{selectedCourse.title} (Course ID: {selectedCourse.courseId})
								<Button
									size="sm"
									variant="light"
									className="ms-2"
									onClick={() => setSelectedCourse(null)}
								>
									&times;
								</Button>
							</Badge>
						</div>
                    </Form.Group>
                )}

                {/* Selected Group */}
                {selectedGroup && (
                    <Form.Group className="mb-3">
                        <Form.Label>Selected Group</Form.Label>
						<div>
							<Badge
								pill
								bg="info"
								className="me-2 mb-2"
							>
								{selectedGroup.groupName} (Group ID: {selectedGroup.groupId})
								<Button
									size="sm"
									variant="light"
									className="ms-2"
									onClick={() => setSelectedGroup(null)}
								>
									&times;
								</Button>
							</Badge>
						</div>
                    </Form.Group>
                )}
            </Modal.Body>
            <Modal.Footer>
                <AileadButton loading={loadingAdd} text="Create Group" type="button" variant="primary" onClick={handleSubmit} />
                <AileadButton text="Close" type="button" variant="secondary" onClick={hideModal} />
            </Modal.Footer>
        </Modal>
    );
};

export default NewChatModal;
