import React from 'react';
import SimpleBar from 'simplebar-react';
import ChatHeader from './ChatHeader';
import ChatFooter from './ChatFooter';
import { Outlet } from 'react-router-dom';

const ChatBox = ({ hideChatBox, groupId }) => {

  return (
    <div className={`chat-body w-100 ${hideChatBox ? 'chat-body-visible' : ''}`}>
        <ChatHeader groupId={groupId} />
        <SimpleBar className="vh-100" style={{ maxHeight: '77vh' }}>
          <div className="px-4 py-4 messages-container">
            <Outlet /> {/* This will render Thread and Message components */}
          </div>
        </SimpleBar>
      <ChatFooter />
    </div>
  );
};

export default ChatBox;
