import React, { useState } from 'react';
import axios from 'axios';
import { notifyError } from 'helper/utils';// Import your custom hook for error notification
import AileadButton from 'components/marketing/common/button/AileadButton';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const usePaymentOrder = () => {
  const [loading, setLoading] = useState(false);
  const PayPalCreateOrder = ({ amount, identifier, course, plan }) => {
    const createOrder = async () => {
      setLoading(true);
      try {
        const response = await axios.post(`${BASE_URL}/payment/order/create`, {
          amount: amount,
          currency: 'USD',
          identifier: identifier,
          course: JSON.stringify(course),
          plan: JSON.stringify(plan),
          type: plan?.type,
          paymentMethod: 'paypal',
          referer: 'admin',
          defaultUri: '/dashboard/business/courses?'
          
        });
        const { approval_url, orderId } = response.data?.data;
        localStorage.setItem('orderId', orderId);
        window.location.href = approval_url;
      } catch (error) {
        notifyError('Error creating order: ', error.message);
      } finally {
        setLoading(false);
      }
    };

    return (
      <AileadButton onClick={createOrder} loading={loading} text="Create Order" type="button" />
    );
  }

  return PayPalCreateOrder;
};
