// import node module libraries
import React from 'react';
import { Col, Row, Card, ListGroup } from 'react-bootstrap';

// import utility file
import { numberWithCommas } from 'helper/utils';

const PopularCourses = ({data}) => {

	return (
		<Card className="h-100">
			<Card.Header className="d-flex align-items-center justify-content-between card-header-height">
				<h4 className="mb-0">Popular Courses</h4>
			</Card.Header>
			<Card.Body>
				<ListGroup variant="flush">
					{data && data.map((item, index) => (
						<ListGroup.Item
							className={`px-0 ${index === 0 ? 'pt-0' : ''}`}
							key={index}
						>
							<Row>
								
								<Col className="ms-n3">
									<h4 className="mb-0 h5">{item.title}</h4>
									<span className="me-2 fs-6">
										{/* <span className="text-dark  me-1 fw-semi-bold">
											{item.courses}
										</span>
										Courses */}
									</span>
									<span className="me-2 fs-6">
										<span className="text-dark  me-1 fw-semi-bold">
											{numberWithCommas(item.enrollmentsCount)}
										</span>
										Students Enrolled
									</span>
									<span className="fs-6">
										{' '}
										<span className="text-dark  me-1 fw-semi-bold">
											{numberWithCommas(item.reviewsCount)}
										</span>{' '}
										Reviews
									</span>
								</Col>
							</Row>
						</ListGroup.Item>
					))}
				</ListGroup>
			</Card.Body>
		</Card>
	);
};
export default PopularCourses;
