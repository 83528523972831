import { Fragment, useEffect, useState } from 'react';
import { Col, Row, Breadcrumb, Card, Form, Button, Image, Spinner } from 'react-bootstrap';
import Paypal from 'assets/images/brand/paypal.svg';
import Paystack from 'assets/images/brand/paystack.svg';
import { useFetchData } from 'hooks/useFetchData';
import { usePostData } from 'hooks/usePostData';
import { notifyError, notifySuccess } from 'helper/utils';
import AileadButton from 'components/marketing/common/button/AileadButton';
import { PaypalInputFields, PaystackInputFields } from 'data/dashboard/SettingsData/Payments';

const Payment = () => {
	const { data: settings, loading: fetchLoading, error: fetchError } = useFetchData('/settings');
	const [formValues, setFormValues] = useState({
		paypal_test_client_id: "",
		paypal_test_secret: "",
		paypal_live_client_id: "",
		paypal_live_secret: "",
		paypal_mode: "",
		paypal_trial_plan_id: "",
		paypal_subscription_plan_id: "",
		paystack_test_public_key: "",
		paystack_test_secret_key: "",
		paystack_test_secret_key: "",
		paystack_live_public_key: "",
		paystack_payment_mode: false,
	});
	const [showPasswords, setShowPasswords] = useState({
		paypal_test_secret: false,
        paypal_live_client_id: false,
		paypal_live_secret: false,
		paystack_test_public_key: false,
		paystack_test_secret_key: false,
		paystack_live_secret_key: false,
		paystack_live_public_key: false,
		paypal_test_client_id: false,
	});
	const { postData, loading: postLoading, error: postError, response } = usePostData('/setting/update');

	const handleInputChange = (e) => {
		const { id, value, type, checked } = e.target;
		setFormValues((prev) => ({
			...prev,
			[id]: type === 'checkbox' ? checked : value
		}));
	};

	const togglePasswordVisibility = (id) => {
		setShowPasswords((prev) => ({
			...prev,
			[id]: !prev[id]
		}));
	};

    useEffect(() => {
		if (settings?.data) {
			setFormValues({
				paystack_live_secret_key: settings?.data.find((item) => item.key === "paystack_live_secret_key")?.value || "",
				paystack_test_secret_key: settings?.data.find((item) => item.key === "paystack_test_secret_key")?.value || "",
				paystack_live_public_key: settings?.data.find((item) => item.key === "paystack_live_public_key")?.value || "",
				paystack_test_public_key: settings?.data.find((item) => item.key === "paystack_test_public_key")?.value || "",
				paystack_payment_mode: settings?.data.find((item) => item.key === "paystack_payment_mode").value === "1",
				paypal_test_client_id: settings?.data.find((item) => item.key === "paypal_test_client_id")?.value || "",
				paypal_test_secret: settings?.data.find((item) => item.key === "paypal_test_secret")?.value || "",
				paypal_live_client_id: settings?.data.find((item) => item.key === "paypal_live_client_id")?.value || "",
				paypal_live_secret: settings?.data.find((item) => item.key === "paypal_live_secret")?.value || "",
				paypal_trial_plan_id: settings?.data.find((item) => item.key === "paypal_trial_plan_id")?.value || "",
				paypal_subscription_plan_id: settings?.data.find((item) => item.key === "paypal_subscription_plan_id")?.value || "",
				paypal_mode: settings?.data.find((item) => item.key === "paypal_mode")?.value || "",
			});
		}
	}, [settings.data]);

	useEffect(() => {
		if (response?.data !== null && response?.status) {
			notifySuccess("Settings Successfully updated!");
		}

		if (postError) {
			notifyError(postError);
		}
	}, [response, postError]);

	const updateSettings = () => {
		postData({ ...formValues });
	}

	const renderInputField = (field) => {
		if (field.type === 'checkbox') {
			return (
				<Row key={field.id}>
					<Col lg={12} md={12} className="mb-3">
						<Form.Check
							type="checkbox"
							label={field.label}
							id={field.id}
							checked={formValues[field.id]}
                            autoComplete='off'
							className="form-switch"
							onChange={handleInputChange}
						/>
					</Col>
				</Row>
			);
		}

		const isPassword = field.type === 'password';

		return (
			<Row key={field.id}>
				<Col lg={12} md={12} className="mb-3">
					<Form.Label>{field.label}</Form.Label>
					<div className="d-flex align-items-center">
						<Form.Control
							type={isPassword && !showPasswords[field.id] ? 'password' : 'text'}
							id={field.id}
							value={formValues[field.id]}
							placeholder=""
                            autoComplete='off'
							required
							onChange={handleInputChange}
						/>
						{isPassword && (
							<Button
								variant="outline-secondary"
								onClick={() => togglePasswordVisibility(field.id)}
								className="ms-2"
							>
								{showPasswords[field.id] ? 'Hide' : 'Show'}
							</Button>
						)}
					</div>
				</Col>
			</Row>
		);
	};

	return (
		<Fragment>
			<Row>
				<Col xl={{ offset: 3, span: 6 }} lg={{ offset: 2, span: 8 }} md={12}>
					<Row>
						<Col lg={12} md={12} sm={12}>
							<div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
								<div className="mb-3 mb-md-0">
									<h1 className="mb-1 h2 font-weight-bold">Payment Setting</h1>
									<Breadcrumb>
										<Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
										<Breadcrumb.Item href="#">Settings</Breadcrumb.Item>
										<Breadcrumb.Item active>Payment Setting</Breadcrumb.Item>
									</Breadcrumb>
								</div>
							</div>
						</Col>
					</Row>

					{/* PayPal Form */}
					<Card className="mb-4">
						<Card.Body>
							<Image src={Paypal} alt="" className="mb-2" />
							<p className="mb-4">
								Enter your PayPal information below to accept payments from students.
							</p>
							<Form>
								{PaypalInputFields.map(renderInputField)}
							</Form>
						</Card.Body>
					</Card>
					{/* End of PayPal Form */}

					{/* Paystack Form */}
					<Card className="mb-4">
						<Card.Header className="d-flex align-items-center justify-content-between">
							<h4 className="mb-0">Paystack</h4>
						</Card.Header>
						<Card.Body>
							<Image src={Paystack} alt="" className="mb-2" />
							<p className="mb-4">
								Connect your Paystack account for faster payouts.
							</p>
							<Form>
								{PaystackInputFields.map(renderInputField)}
							</Form>
						</Card.Body>
					</Card>
					{/* End of Paystack Form */}

					{/* Update Button */}
					<Row>
						<Col lg={6} md={12}>
							<AileadButton type="button" variant="primary" loading={postLoading} onClick={updateSettings} text="Update Settings" />
						</Col>
					</Row>
					{/* End of Update Button */}
				</Col>
			</Row>
		</Fragment>
	);
};

export default Payment;
