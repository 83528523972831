// import node module libraries
import { Fragment, useState, useEffect } from 'react';
import { Col, Row, Card, Form, Button, Image } from 'react-bootstrap';
import useUserTimezone from 'hooks/useUserTimezone';
import { usePostData } from 'hooks/usePostData';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// import media files
import Logo from 'assets/images/brand/logo/logo-icon.svg';
import { notifyError, notifySuccess } from 'helper/utils';
import { useFetchData } from 'hooks/useFetchData';
import AileadButton from 'components/marketing/common/button/AileadButton';

const SignUp = () => {

	const [ firstName, setFirstName ] = useState('');
	const [ lastName, setLastName ] = useState('');
	const [ mobile, setMobile ] = useState('');
	const [ gender, setGender ] = useState('');
	const [ country, setCountry ] = useState('');
	const [ email, setEmail ] = useState('');
	const [ password, setPassword ] = useState('');
	const [ passwordConfiration, setPasswordConfiration ] = useState('');
	const { postData, error, loading, response } = usePostData('/auth/register');
	const { userTimezone } = useUserTimezone();
	const navigate = useNavigate();
	const { data } = useFetchData("/countries/list");
	const Gender = [{id: 1, name: "Male"}, {id: 2, name: "Female"}];

	const handleFormSubmit = (e) => {
		e.preventDefault();
		postData({ 
		  firstName, 
		  lastName, 
		  mobile, 
		  gender, 
		  country, 
		  email, 
		  role: "student", 
		  password, 
		  timezone: userTimezone,
		  "password_confirmation": passwordConfiration
		});
	};

	useEffect(() => {
	  if(response !== null && response?.status) {
		  navigate('/authentication/sign-in');
		  notifySuccess("Registration Successful!");
	  } else {
		  notifyError(error);
	  }
	}, [response])

	return (
		<Fragment>
			<Row className="align-items-center justify-content-center g-0 min-vh-100">
				<Col lg={5} md={5} className="py-8 mt-8 mb-4 py-xl-0">
					<Card>
						<Card.Body className="p-6">
							<div className="mb-4">
								<Link to="/">
									<Image src={Logo} className="mb-4" alt="" />
								</Link>
								<h1 className="mb-1 fw-bold">Sign up</h1>
								<span>
									Already have an account?{' '}
									<Link to="/authentication/sign-in" className="ms-1">
										Sign in
									</Link>
								</span>
							</div>
							{/* Form */}
							<Form onSubmit={handleFormSubmit}>
								<Row>
									<Col lg={12} md={12} className="mb-3">
										{/* User Name */}
										<Form.Label>First Name</Form.Label>
										<Form.Control
											type="text"
											id="firstname"
											placeholder="First Name"
											required
											value={firstName}
											onChange={(e) => setFirstName(e.target.value)}
										/>
										{error && error?.firstName && 
											(
												<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
													{error.firstName[0]}
												</Form.Text>
											)
										}
									</Col>

									<Col lg={12} md={12} className="mb-3">
										{/* last Name */}
										<Form.Label>Last Name</Form.Label>
										<Form.Control
											type="text"
											id="Lastname"
											placeholder="Last Name"
											required
											value={lastName}
											onChange={(e) => setLastName(e.target.value)}
										/>

										{error && error?.lastName && 
											(
												<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
													{error.lastName[0]}
												</Form.Text>
											)
								
										}
									</Col>

									<Col lg={12} md={12} className="mb-3">
										{/* User Name */}
										<Form.Label>Mobile</Form.Label>
										<Form.Control
											type="text"
											id="mobile"
											placeholder="Mobile"
											required
											value={mobile}
											onChange={(e) => setMobile(e.target.value)}
										/>
										{error && error?.mobile && 
											(
												<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
													{error.mobile[0]}
												</Form.Text>
											)
								
										}
									</Col>

									<Col lg={12} md={12} className="mb-3">
										{/* User Name */}
										<Form.Label>Gender</Form.Label>
										<Form.Select 
											placeholder='Gender' 
											value={gender}
											onChange={(e) => setGender(e.target.value)}
										>
											<option value=""></option>
											{Gender.map((item) => (
												<option key={item.id} value={item.name}>
													{item.name}
												</option>
											))}
										</Form.Select>
										{error && error?.gender && 
											(
												<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
													{error.gender[0]}
												</Form.Text>
											)
										}
									</Col>
									
									<Col lg={12} md={12} className="mb-3">
										<Form.Label>Country</Form.Label>
										<Form.Select 
											placeholder='Select Currency' 
											value={country}
											onChange={(e) => setCountry(e.target.value)}
										>
											<option value=""></option>
											{data?.data && data?.data.map((item) => (
												<option key={item.id} value={item.name}>
													{item.name}
												</option>
											))}
										</Form.Select>

										{error && error?.country && 
											(
												<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
													{error.country[0]}
												</Form.Text>
											)
										}
									</Col>

									<Col lg={12} md={12} className="mb-3">
										{/* email */}
										<Form.Label>Email </Form.Label>
										<Form.Control
											type="email"
											id="email"
											placeholder="Email address here"
											required
											value={email}
											onChange={(e) => setEmail(e.target.value)}
										/>
										{error && error?.email && 
											(
												<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
													{error.email[0]}
												</Form.Text>
											)
								
										}
									</Col>

									<Col lg={12} md={12} className="mb-3">
										{/* Password */}
										<Form.Label>Password </Form.Label>
										<Form.Control
											type="password"
											id="password"
											placeholder="**************"
											required
											value={password}
											onChange={(e) => setPassword(e.target.value)}
										/>
										{error && error?.password && 
											(
												<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
													{error.password[0]}
												</Form.Text>
											)
								
										}
									</Col>

									<Col lg={12} md={12} className="mb-3">
										{/* Password */}
										<Form.Label>Retype Password </Form.Label>
										<Form.Control
											type="password"
											id="confirmpassword"
											placeholder="**************"
											required
											value={passwordConfiration}
											onChange={(e) => setPasswordConfiration(e.target.value)}
										/>
									</Col>

									<Col lg={12} md={12} className="mb-3">
										{/* Checkbox */}
										<Form.Check type="checkbox" id="check-api-checkbox">
											<Form.Check.Input type="checkbox" />
											<Form.Check.Label>
												I agree to the
												<Link to="/pages/terms-and-conditions">
													Terms of Service{' '}
												</Link>{' '}
												and{' '}
												<Link to="/pages/terms-and-conditions">
													Privacy Policy.
												</Link>
											</Form.Check.Label>
										</Form.Check>
									</Col>

									<Col lg={12} md={12} className="mb-0 d-grid gap-2">
										<AileadButton variant="primary" type="submit" loading={loading} text="Sign in" />
									</Col>

								</Row>
							</Form>
							<hr className="my-4" />
							<div className="mt-4 text-center">
								{/* Facebook */}
								<Link
									to="#"
									className="btn-social btn-social-outline btn-facebook"
								>
									<i className="fab fa-facebook"></i>
								</Link>{' '}
								{/* Twitter */}
								<Link
									to="#"
									className="btn-social btn-social-outline btn-twitter"
								>
									<i className="fab fa-twitter"></i>
								</Link>{' '}
								{/* LinkedIn */}
								<Link
									to="#"
									className="btn-social btn-social-outline btn-linkedin"
								>
									<i className="fab fa-linkedin"></i>
								</Link>{' '}
								{/* GitHub */}
								<Link
									to="#"
									className="btn-social btn-social-outline btn-github"
								>
									<i className="fab fa-github"></i>
								</Link>
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Fragment>
	);
};

export default SignUp;
