// import node module libraries
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Pagination = ({ lastPages, currentPage, onNextPage, onPrevPage }) => {

	const navigate = useNavigate();
	const nextPage = () => {
        if (onNextPage && typeof onNextPage === 'function') {
            onNextPage();
        }
    };

    const prevPage = () => {
        if (onPrevPage && typeof onPrevPage === 'function') {
            onPrevPage();
        }
    };

	const finishPage = () => {
        if (onNextPage && typeof onNextPage === 'function') {
            onNextPage();
			navigate("/student/quiz/result");
        }
    };

	return (
		<div
			className={`d-flex justify-content-${
				currentPage > 1 ? 'between' : 'end'
			}`}
		>
			{currentPage > 1 && (
				<Button disabled variant="secondary" onClick={prevPage}>
					<i className="fe fe-arrow-left"></i> Previous
				</Button>
			)}
			{currentPage + 1 === lastPages ? (
				<Button className="btn btn-primary" onClick={finishPage}>
				 	Finish
				</Button>

			) : (
				<Button variant="primary" onClick={nextPage}>
					{' '}
					Next <i className="fe fe-arrow-right"></i>
				</Button>
			)}
		</div>
	);
};

export default Pagination;
