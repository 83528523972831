// Import node module libraries
import { useEffect, useState } from 'react';
import { Table, Image, Button } from 'react-bootstrap';

// Import custom components
import NewPagination from 'components/elements/advance-table/NewPagination';
import { usePostData } from 'hooks/usePostData';
import { notifySuccess } from 'helper/utils';
import { Link } from 'react-router-dom';
import { DeleteConfirmationModal } from 'components/dashboard/business/all-courses/common/Components';

const GradedQuizTable = ({ 
    courses_data,
    handlePageChange,
    currentPage,
    lastPage,
    refreshData,
    loading
}) => {

    const [show, setShow] = useState(false);
    const handleClose = () => {
		setShow(false);
	}

    const { response, loading: isLoading, postData } = usePostData('/quiz/remove');

    useEffect(() => {
		if(response?.status === "Success") {
			refreshData();
			notifySuccess(`Quiz has been Deleted Successfully!`);
		}
	}, [response])

    const handleDelete = (id) => {
        postData({id})
    }

    return (
        <>
            <div className="table-responsive">
                <Table hover className="table-centered">
                    <thead className="table-light">
                        <tr>
                            <th>#</th>
                            <th>Quiz</th>
                            <th>Type</th>
                            <th>Module</th>
                            <th>Lesson</th>
                            <th>Questions</th>
                            <th>Attempts</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {courses_data?.data?.length > 0 ? (
                            courses_data?.data?.map((quiz, index) => (
                                <>
                                <tr key={quiz.id}>
                                    <td>{index + 1}</td>
                                    <td>
                                        <div className="d-lg-flex align-items-center">
                                            <div>
                                                <Image
                                                    src={quiz?.course?.image || ""}
                                                    alt=""
                                                    className="img-4by3-lg rounded"
                                                />
                                            </div>
                                            <div className="ms-lg-3 mt-2 mt-lg-0">
                                                <h4 className="mb-1 text-primary-hover">{quiz?.title}</h4>
                                                <span className="text-inherit">
                                                    Course: {quiz?.course?.title || ""}
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{quiz?.type}</td>
                                    {/* <td>{quiz?.isGradable ? "YES" : "NO"}</td> */}
                                    <td>{quiz?.module?.title || "N/A"}</td>
                                    <td>{quiz?.lesson?.title || "N/A"}</td>
                                    <td>{quiz?.questions?.length || 0}</td>
                                    <td>{quiz?.maxAttempt || "Unlimited"}</td>
                                    <td>
                                        <Link className="btn btn-sm btn-primary" to={`/dashboard/course/edit-quiz/${quiz?.id}`}>Edit</Link>
                                        <Button onClick={() => setShow(true)} variant="danger" size="sm" className="ms-2">Delete</Button>
                                        
                                    </td>
                                </tr>

                                    <DeleteConfirmationModal
                                        show={show}
                                        handleClose={handleClose}
                                        handleConfirm={() => handleDelete(quiz?.id)}
                                        loading={isLoading}
                                        item={""}
                                    />
                                </>
                            ))
                        ) : loading ? (
                            <tr>
                                <td colSpan="9" className="text-center">Fetching Data Please wait...</td>
                            </tr>
                        ) : (
                            <tr>
                                <td colSpan="9" className="text-center">No Quiz available.</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>

            <NewPagination
              currentPage={currentPage}
              lastPage={lastPage}
              onPageChange={handlePageChange}
            />
        </>
    );
};

export default GradedQuizTable;
