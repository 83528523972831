// import node module libraries
import { Fragment } from 'react';
import { Col, Row, Card, Table, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// import custom components
import { FlatPickr } from 'components/elements/flat-pickr/FlatPickr';
import StatRightChart from 'components/dashboard/common/stats/StatRightChart';
import ApexCharts from 'components/elements/charts/ApexCharts';

// import sub components
import MostViewPages from './MostViewPages';
import Browsers from './Browsers';
import SocialMediaTraffic from './SocialMediaTraffic';

// import MDI icons
import Icon from '@mdi/react';
import { mdiSquareRounded } from '@mdi/js';
import { useFetchData } from 'hooks/useFetchData';
// Import required data files
import {
	SessionChartSeries,
	SessionChartOptions,
	ActiveUserChartSeries,
	ActiveUserChartOptions,
	TrafficChannelChartSeries,
	TrafficChannelChartOptions,
	OperatingSystemChartSeries,
	OperatingSystemChartOptions
} from 'data/charts/ChartData';

const Analytics = () => {

	const {data} = useFetchData("/business/course/graph");
	const {data: dataCard} = useFetchData("/business/course/card");

	const graphData = [
		{
			name: "Course Enrollments",
			data: data?.data && data?.data?.enrollments
		},
		{
			name: "Completion Rate",
			data: data?.data && data?.data?.completions
		},
	]

	return (
		<Fragment>
			<Row>
				<Col lg={12} md={12} sm={12}>
					<div className="border-bottom pb-4 mb-4 d-md-flex justify-content-between align-items-center">
						<div className="mb-3 mb-md-0">
							<h1 className="mb-0 h2 fw-bold">Analytics</h1>
						</div>
						<div className="d-flex">
							<div className="input-group me-3  ">
								<FlatPickr value={''} />
								<span className="input-group-text text-muted" id="basic-addon2">
									<i className="fe fe-calendar"></i>
								</span>
							</div>
							<Link to="#" className="btn btn-primary">
								Setting
							</Link>
						</div>
					</div>
				</Col>
			</Row>
			<Row>
				<Col xl={3} lg={6} md={12} sm={12}>
					<StatRightChart
						title="Total Employees"
						value={dataCard?.data?.totalEmployee}
						// summary="Number of sales"
						// summaryValue="+20.9$"
						// summaryIcon="up"
						showSummaryIcon
						classValue="mb-4"
						chartName="UserChart"
					/>
				</Col>

				<Col xl={3} lg={6} md={12} sm={12}>
					<StatRightChart
						title="Total Course Purchase"
						value={dataCard?.data?.totalPuchased}
						// summary="Number of pending"
						// summaryValue="5%"
						// summaryIcon="down"
						showSummaryIcon
						classValue="mb-4"
						chartName="VisitorChart"
					/>
				</Col>

				<Col xl={3} lg={6} md={12} sm={12}>
					<StatRightChart
						title="Total Payments"
						value={dataCard?.data?.totalPayment}
						// summary="Students"
						// summaryValue="+1200"
						// summaryIcon="up"
						showSummaryIcon
						classValue="mb-4"
						chartName="BounceChart"
					/>
				</Col>

				<Col xl={3} lg={6} md={12} sm={12}>
					<StatRightChart
						title="Total Subscriptions"
						value={dataCard?.data?.totalSubcription}
						// summary="Instructor"
						// summaryValue="12%"
						// summaryIcon="up"
						showSummaryIcon
						classValue="mb-4"
						chartName="AverageVisitTimeChart"
					/>
				</Col>
			</Row>

			{/* Sessions + Active User Row */}
			<Row>
				<Col xl={12} lg={12} md={12} className="mb-4">
					<Card className="h-100">
						<Card.Header className="align-items-center card-header-height d-flex justify-content-between align-items-center">
							<h4 className="mb-0">Course Graph</h4>
						</Card.Header>
						<Card.Body>
							<ApexCharts
								options={SessionChartOptions}
								series={graphData}
								type="line"
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			{/* end of Users by Country + Traffic Channel + Operating System  */}

		</Fragment>
	);
};

export default Analytics;
