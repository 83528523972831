import GKAccordionQuiz from 'components/marketing/common/accordions/GKAccordionQuiz';
import { quizData as dataFromQuiz } from 'helper/utils';
import { usePostData } from 'hooks/usePostData';
import { useState } from 'react';

  const Options = ({ index, option, optionIndex, formData, setFormData, handleActiveOption }) => {

	const {loading, postData} = usePostData("/quiz/remove/option");
	const [isDragging, setIsDraggin] = useState(false);

	const addQuestion = (newSection) => {
		dataFromQuiz("addQuestion", setFormData, null, null, newSection);
	};

	const updateOption = (questionIndex, newOption, optionIndex) => {
		dataFromQuiz("updateOption", setFormData, questionIndex, optionIndex, newOption);
	};
	
	const removeOption = (questionIndex, optionIndex) => {
		dataFromQuiz("removeOptions", setFormData, questionIndex, optionIndex, null, postData);
	};

	return (
			<div
				draggable
				onDragStart={() => {
					handleActiveOption(optionIndex);
					setIsDraggin(true);
				}}
				
				onDragEnd={() => {
					handleActiveOption(null);
					setIsDraggin(false);
				}}

				style={{
					filter: isDragging ? 'blur(1px)' : 'none' // Apply blur effect to the container
				}}
			>
				{/* <LessonAccordionActions
					addModule={addModule}
					moduleIndex={index}
					addLesson={updateLesson}
					accordionItems={lesson}
					index={lessonIndex}
					removeLesson={removeLesson}
					removeLessonLoading={loading}
					formData={formData}
				/> */}

				<GKAccordionQuiz 
					addQuestion={addQuestion}
					questionIndex={index}
					addOption={updateOption}
					accordionItems={option}
					index={optionIndex}
					removeOption={removeOption}
					removeOptionLoading={loading}
					formData={formData}
				/>
			</div>
	);
};
export default Options;
