import { Button, Spinner } from 'react-bootstrap';
import { Fragment } from 'react';


const AileadButton = (props) => {
    const { variant, size, onClick, children, text, loading, type, className, style } = props;

	return (
        <Button 
          variant={variant} 
          onClick={onClick} 
          disabled={loading}
          className={className}
          size={size}
          style={style}
          type={type}
        >
          {
            loading ?  
              (<>
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
                Loading ...
              </>) :
              <>{text}</>
          }
        </Button>
	);
};
export default AileadButton;
