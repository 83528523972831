// import node module libraries
import { Fragment, useEffect, useState } from 'react';
import { Col, Row, Breadcrumb, Card, Form, Button, Spinner } from 'react-bootstrap';
import { usePostData } from 'hooks/usePostData';
import { useFetchData } from 'hooks/useFetchData';
import { notifyError, notifySuccess } from 'helper/utils'

const GoogleSetting = () => {
	const {postData, loading, error, response } = usePostData('/setting/update');
	const {data: settings} = useFetchData('/settings');
	const [analytics, setAnalytics] = useState("");

	useEffect(() => {
		if(settings?.data){
			setAnalytics(settings?.data.find((item) => item.key == "google_analytics_id").value);
		}
	}, [settings?.data])

	useEffect(() => {
		
		if(response?.data !== null && response?.status){
			notifySuccess("Settings Successfully updated!");
		}

		if(error){
			notifyError(error);
		}

	}, [response?.data, error])

	const updateSettings = () => {
		postData({
			google_analytics_id: analytics,
		});
	}

	return (
		<Fragment>
			<Row>
				<Col xl={{ offset: 3, span: 6 }} lg={{ offset: 2, span: 8 }} md={12}>
					<Row>
						<Col lg={12} md={12} sm={12}>
							<div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
								<div className="mb-3 mb-md-0">
									<h1 className="mb-1 h2 font-weight-bold">Google Setting</h1>
									<Breadcrumb>
										<Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
										<Breadcrumb.Item href="#">Settings</Breadcrumb.Item>
										<Breadcrumb.Item active>Google Setting</Breadcrumb.Item>
									</Breadcrumb>
								</div>
							</div>
						</Col>
					</Row>

					<Row>
						{/* google settings */}
						<Col xl={12} lg={12} md={12}>
							<Card className="mb-4">
								<Card.Header className="d-flex align-items-center justify-content-between">
									<h4 className="mb-0">Google Settings</h4>
								</Card.Header>
								<Card.Body>
									<Form>
										<Row>
											<Col lg={12} md={12} className="mb-3">
												{/* Google Tracking ID */}
												<Form.Label>
													Google Analytics Tracking ID{' '}
													<small className="text-muted">
														(ex. UA-000000-2 )
													</small>
												</Form.Label>
												<Form.Control
													type="text"
													id="googleage"
													placeholder="UA-000000-2"
													required
													value={analytics}
													onChange={(e) => setAnalytics(e.target.value)}
												/>
											</Col>
										</Row>
										<Row>
											<Col lg={12} md={12}>
												<Button variant="primary" onClick={updateSettings}>
													{
														loading ?  
															(<>
																<Spinner
																	as="span"
																	animation="border"
																	size="sm"
																	role="status"
																	aria-hidden="true"
																/>
																Loading ...
															</>) :
														<>Update Settings {' '}</>
													}
												</Button>
											</Col>
										</Row>
									</Form>
								</Card.Body>
							</Card>
						</Col>
						{/* end of google settings */}
						
					</Row>
				</Col>
			</Row>
		</Fragment>
	);
};

export default GoogleSetting;
