// Import node module libraries
import { useEffect } from 'react';
import { Table } from 'react-bootstrap';

// Import custom components
import NewPagination from 'components/elements/advance-table/NewPagination';
import { InstructorData, CourseInitialColumn, CourseStatus } from '../../business/all-courses/common/Components';
import { usePostData } from 'hooks/usePostData';
import { notifySuccess } from 'helper/utils';
import AileadButton from 'components/marketing/common/button/AileadButton';
import { useNavigate } from 'react-router-dom';

const MyCoursesTable = ({
    courses_data,
    handlePageChange,
    currentPage,
    lastPage,
    refreshData,
    loading
}) => {
    const {response: publishResponse, postData: publishPostData} = usePostData('/course/status');

    useEffect(() => {
		if(publishResponse && publishResponse?.status === "Success") {
			refreshData();
			notifySuccess(`Course has been move to trash Successfully!`);
		}
	}, [publishResponse])

    const navigate = useNavigate();

    return (
        <>
            <div className="table-responsive">
                <Table hover className="table-centered">
                    <thead className="table-light">
                        <tr>
                            <th>#</th>
                            <th>Course</th>
                            <th>Instructor</th>
                            <th>Level</th>
                            <th>Progress</th>
                            <th>Start Date</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {courses_data?.data?.length > 0 ? (
                            courses_data?.data?.map((course, index) => (
                                <tr key={course.id}>
                                    <td className='text-dark'>{index + 1}</td>
                                    <td className='text-dark'><CourseInitialColumn course={course} /></td>
                                    <td className='text-dark'><InstructorData row={course} /></td>
                                    <td className='text-dark'>{course.level}</td>
                                    <td className='text-dark'><CourseStatus value={course} /></td>
                                    <td className='text-dark'>{course.publishedDate}</td>
                                    <td className='text-dark'>
                                        <AileadButton type="button" text="Start Learning" onClick={() => navigate(`/student/start/learning/${course.slug}`)} />
                                    </td>
                                </tr>
                            ))
                        ) : loading ? (
                            <tr>
                                <td colSpan="8" className="text-center">Fetching Data Please wait .....</td>
                            </tr>
                        ) : (
                            <tr>
                                <td colSpan="8" className="text-center">No Published courses available.</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
            
            <NewPagination
              currentPage={currentPage}
              lastPage={lastPage}
              onPageChange={handlePageChange}
            />
            
        </>
    );
};

export default MyCoursesTable;
