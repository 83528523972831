// socket.js
import io from 'socket.io-client';

const SOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL;
const socket = io(SOCKET_URL, {
    transports: ['websocket'], // Ensure using websocket transport
    reconnection: true, // Enable reconnection
    reconnectionAttempts: Infinity, // Infinite reconnections
    reconnectionDelay: 1000, // Delay between reconnections
});

export default socket;

