import LessonAccordionActions from 'components/marketing/common/accordions/LessonAccordionActions';
import { curriculumData } from 'helper/utils';
import { usePostData } from 'hooks/usePostData';
import { useState } from 'react';

  const Lessons = ({ index, lesson, lessonIndex, formData, setFormData, handleActiveLesson }) => {

	const {loading, postData} = usePostData("/module/remove/lesson");
	const [isDragging, setIsDraggin] = useState(false);

	const addModule = (newSection) => {
		curriculumData("addModule", setFormData, null, null, newSection, null);
	};

	const updateLesson = (moduleIndex, newLesson, lessonIndex) => {
		curriculumData("updateLesson", setFormData, moduleIndex, lessonIndex, newLesson, null);
	};
	
	const removeLesson = (moduleIndex, lessonIndex) => {
		curriculumData("removeLesson", setFormData, moduleIndex, lessonIndex, null, postData);
	};

	return (
			<div
				draggable
				onDragStart={() => {
					handleActiveLesson(lessonIndex);
					setIsDraggin(true);
				}}
				
				onDragEnd={() => {
					handleActiveLesson(null);
					setIsDraggin(false);
				}}

				style={{
					filter: isDragging ? 'blur(1px)' : 'none' // Apply blur effect to the container
				}}
			>
				<LessonAccordionActions
					addModule={addModule}
					moduleIndex={index}
					addLesson={updateLesson}
					accordionItems={lesson}
					index={lessonIndex}
					removeLesson={removeLesson}
					removeLessonLoading={loading}
					formData={formData}
				/>
			</div>
	);
};
export default Lessons;
