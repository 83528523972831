// import node module libraries
import {useEffect} from 'react';
import { Table } from 'react-bootstrap';

// import custom components
import NewPagination from 'components/elements/advance-table/NewPagination';
import { InstructorData, CourseInitialColumn, CourseStatus, TrashedMenu } from '../../business/all-courses/common/Components';
import { usePostData } from 'hooks/usePostData';
import { notifySuccess } from 'helper/utils';

const TrashedCoursesTable = ({ 
    courses_data,
    handlePageChange,
    currentPage,
    lastPage,
	refreshData,
	loading
}) => {
	const {response: deleteResponse, loading: deleteLoading, error: deleteError, postData} = usePostData('/course/delete');
	const {response: restoreResponse, loading: restoreLoading, error: publishError, postData: restorePostData} = usePostData('/course/restore');
	
	useEffect(() => {
		if((deleteResponse && deleteResponse?.status === "Success") || 
		   (restoreResponse && restoreResponse?.status === "Success")
		) {
			refreshData();
			notifySuccess(`Action completed Successfully!`);
		}

	}, [deleteResponse, restoreResponse])

	return (
		<>
			<div className="table-responsive">
				<Table hover className="table-centered">
					<thead className="table-light">
						<tr>
							<th>#</th>
							<th>Course</th>
							<th>Category</th>
							<th>Instructor</th>
							<th>Level</th>
							<th>Created Date</th>
							<th>Status</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{courses_data?.data?.length > 0 ? (
							courses_data?.data?.map((course, index) => (
								<tr key={course.id}>
									<td>{index + 1}</td>
									<td><CourseInitialColumn course={course} /></td>
									<td>{course.category?.title || 'N/A'}</td>
									<td><InstructorData row={course} /></td>
									<td>{course.level}</td>
									<td>{course.publishedDate}</td>
									<td><CourseStatus value={course} /></td>
									<td>
										<TrashedMenu 
											value={course}
											removeCourseLoading={deleteLoading}
											handleDeleteCourse={() => postData({courseId: course.id})}
											handleRestore={() => restorePostData({courseId: course.id})}
											restoreLoading={restoreLoading}
										/>
									</td>
								</tr>
							))
						) : loading ? (
                            <tr>
                                <td colSpan="8" className="text-center">Fetching Data Please wait .....</td>
                            </tr>
                        ) : (
							<tr>
                                <td colSpan="8" className="text-center">No Trashed courses available just Yet.</td>
                            </tr>
						)
						}
					</tbody>
				</Table>
			</div>

			<NewPagination
				currentPage={currentPage}
				lastPage={lastPage}
				onPageChange={handlePageChange}
			/>
		</>
	);
};

export default TrashedCoursesTable;
