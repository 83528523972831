// import node module libraries
import { Card, Form, ButtonGroup, Button } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import { useFetchData } from 'hooks/useFetchData';
import { usePostData } from 'hooks/usePostData';
import { useEffect, useState } from 'react';
import AileadButton from 'components/marketing/common/button/AileadButton';


const UpdateBasicInformation = (props) => {
	const { next, formData, setFormData, handleInputChange } = props;
	const { data } = useFetchData('/category/categories');
	const [selectedCategories, setSelectedCategories] = useState([])
	const {response: validationResponse, loading: loadingValidation, error: validationErrors, postData} = usePostData('/course/validate/course');

	const CoursesLevel = [
		{ value: 'Intermediate', label: 'Intermediate' },
		{ value: 'Beginner', label: 'Beginner' },
		{ value: 'Advance', label: 'Advance' }
	];

	// Initialize selected categories when formData changes
	useEffect(() => {
		setSelectedCategories(formData?.step1?.category);
	}, [formData]);

	const handleCategoryChange = (categoryId) => {
		const updatedCategories = selectedCategories.includes(categoryId)
		  ? selectedCategories.filter((id) => id !== categoryId)
		  : [...selectedCategories, categoryId];
		setSelectedCategories(updatedCategories);
	
		// Update the formData with the selected categories
		setFormData({
		  ...formData,
		  step1: {
			...formData.step1,
			category: updatedCategories
		  }
		});
	};
	

	const nextStep = () => {
		postData({
			"isUpdate": true,
			"title": formData.step1.title,
			"description": formData.step1.description && formData.step1.description,
			"category": formData.step1.category,
			"instructorId": null,
			"level": formData.step1.level,
			"discount": formData.step1.discount,
			"subscription": formData.step1.subscription,
		});
	}

	useEffect(() => {
		if(validationResponse !== null && validationResponse?.status){
			next();
		}

	}, [validationResponse])

	return (
		<Form>
			{/* Card */}
			<Card className="mb-3 ">
				<Card.Header className="border-bottom px-4 py-3">
					<h4 className="mb-0">Basic Information</h4>
				</Card.Header>
				{/* Card body */}
				<Card.Body>
					{/* Title  */}
					<Form.Group className="mb-3">
						<Form.Label htmlFor="courseTitle">Course Title <Form.Text className='text-danger'>*</Form.Text></Form.Label>
						<Form.Control
							type="text"
							placeholder="Course Title"
							id="course_title"
							name="course_title"
							value={formData.step1 && formData.step1.title}
							onChange={(e) => handleInputChange('title', e.target.value)}
						/>
						<Form.Text className="text-muted">
							Write a 60 character course title.
						</Form.Text>
					</Form.Group>
					{validationErrors?.title && (
						<Form.Group className="mb-2">
							<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
								{validationErrors.title[0]}
							</Form.Text>
						</Form.Group>
					)}

					{/* Category */}
					<Form.Group className="mb-3">
						<Form.Label>
							Category <Form.Text className="text-danger">*</Form.Text>
						</Form.Label>
						<div style={{ maxHeight: "200px", maxWidth: "40em", overflowY: "auto", border: "1px solid #ced4da", padding: "10px", borderRadius: "10px"}}>
							<ButtonGroup vertical className="d-flex flex-column mt-8">
							{data?.data?.data && data?.data?.data.map((item, i) => (
								<Button
									key={item.id}
									variant={selectedCategories.includes(item.id) ? 'primary' : 'outline-primary'}
									onClick={() => handleCategoryChange(item.id)}
									className="mb-2"
								>
								{item.title}
								</Button>
							))}
							</ButtonGroup>
						</div>
						<Form.Text className="text-muted">
							Help people find your courses by choosing categories that represent your course.
						</Form.Text>
						{validationErrors?.categoryId && (
							<Form.Group className="mb-2">
							<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
								{validationErrors.categoryId[0]}
							</Form.Text>
							</Form.Group>
						)}
					</Form.Group>

					{/* Courses level */}
					<Form.Group className="mb-3">
						<Form.Label>Courses level</Form.Label>
						<Form.Select 
							placeholder='Select Category' 
							value={formData.step1 && formData.step1.level}
							onChange={(e) => handleInputChange('level', e.target.value)}
						>
							<option value=""></option>
							{CoursesLevel.map((item) => (
								<option key={item.label} value={item.value}>
								  {item.label}
								</option>
							))}
						</Form.Select>
						<Form.Text className="text-muted">
							Choose the complexity of this course. <Form.Text className='text-danger'>Beginners by default</Form.Text>
						</Form.Text>
					</Form.Group>
					
					{validationErrors?.level && (
						<Form.Group className="mb-2">
							<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
								{validationErrors.level[0]}
							</Form.Text>
						</Form.Group>
					)}

					{/* Courses level */}
					<Form.Group className="mb-3">
						<Form.Label>Subscription Enabled</Form.Label>
						<Form.Select 
							placeholder='Select Subscription'
							value={formData.step1 && formData.step1.subscription ? 1 : 0}
							onChange={(e) => handleInputChange('subscription', e.target.value)}
						>
							<option value=""></option>
							{[{value: 0, label: "No"},{value: 1, label: "Yes"}].map((item) => (
								<option key={item.label} value={item.value}>
								  {item.label}
								</option>
							))}
						</Form.Select>

						<Form.Text className="text-muted">
							if enabled, customers can enroll for your course if they subscribe. <Form.Text className='text-danger'>Disabled by default</Form.Text>
						</Form.Text>
					</Form.Group>
					{validationErrors?.isSubscription && (
						<Form.Group className="mb-2">
							<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
								{validationErrors.isSubscription[0]}
							</Form.Text>
						</Form.Group>
					)}

					<Form.Group className="mb-3">
						<Form.Label htmlFor="courseTitle">Course Discount</Form.Label>
						<Form.Control
							type="text"
							placeholder="Course Discount"
							id="course_discount"
							name="course_discount"
							value={formData.step1 && formData.step1.discount}
							onChange={(e) => handleInputChange('discount', e.target.value)}
						/>
						<Form.Text className="text-muted">
							Make discount for customers by percentage (%).
						</Form.Text>
					</Form.Group>
					{validationErrors?.discount && (
						<Form.Group className="mb-2">
							<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
								{validationErrors.discount[0]}
							</Form.Text>
						</Form.Group>
					)}

					{/* Course Description*/}
					<Form.Group className="mb-3">
						<Form.Label>Course Description <Form.Text className='text-danger'>*</Form.Text> </Form.Label>
						<ReactQuill
                           value={formData.step1 && formData.step1.description}
						   onChange={(content, delta, source, editor) => {
							//const value = editor.getContents();  // Adjust this line based on what you need
							//console.log("CONTRNT: ", content)
							handleInputChange('description', content);
						  }}
                        />
						<Form.Text className="text-muted">
							Give a detailed summary of your courses.
						</Form.Text>
					</Form.Group>
					{validationErrors?.description && (
						<Form.Group className="mb-2">
							<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
								{validationErrors.description[0]}
							</Form.Text>
						</Form.Group>
					)}

				</Card.Body>
			</Card>
			{/* Button */}
			<AileadButton variant="primary" type="button" onClick={nextStep} loading={loadingValidation} text="Next" />
		</Form>
	);
};
export default UpdateBasicInformation;
