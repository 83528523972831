// Importing necessary modules and components
import { Fragment, useEffect, useState } from 'react';
import { Col, Row, Breadcrumb, Card, Form } from 'react-bootstrap';
import { useFetchData } from 'hooks/useFetchData';
import { usePostData } from 'hooks/usePostData';
import { notifySuccess } from 'helper/utils';
import AileadButton from 'components/marketing/common/button/AileadButton';

const Cohort = () => {
    const { postData, loading, error, response } = usePostData('/setting/save');
    const { data: careers, refreshData } = useFetchData('/category/basic');
    const [enableManualMode, setEnableManualMode] = useState(false); // enable creation of cohort manually
    const [careerPrice, setCareerPrice] = useState({}); // prices of each career path stored as an object
    const [selectedCourses, setSelectedCourses] = useState({});

    // Handling updates to settings
    const updateSettings = () => {
        const payload = {
            cohort_manual_mode: enableManualMode,
            cohort_career_price: careerPrice,  // Send the prices for each career
            cohort_selected_courses: selectedCourses
        };
        postData(payload);
    };

    // Handling success and error notifications
    useEffect(() => {
        if (response?.data && response?.status === "Success") {
            refreshData();
            notifySuccess('Settings Successfully updated!');
        }
    }, [response?.data]);

    // Handle price input changes
    const handlePriceChange = (careerId, price) => {
        setCareerPrice(prevState => ({
            ...prevState,
            id: careerId,
            price,  // Update price for a specific career
        }));
    };

    // Handle course selection changes
    const handleCourseSelection = (careerId, courseId) => {
        setSelectedCourses(prevState => {
            const careerCourses = prevState[careerId] || [];
            if (careerCourses.includes(courseId)) {
                // If already selected, remove it
                return {
                    ...prevState,
                    [careerId]: careerCourses.filter(id => id !== courseId),
                };
            } else {
                // If not selected, add it
                return {
                    ...prevState,
                    [careerId]: [...careerCourses, courseId],
                };
            }
        });
    };

    return (
        <Fragment>
            <Row>
                <Col xl={{ offset: 3, span: 6 }} lg={{ offset: 2, span: 8 }} md={12}>
                    
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
                                <div className="mb-3 mb-md-0">
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
                                        <Breadcrumb.Item href="#">Settings</Breadcrumb.Item>
                                        <Breadcrumb.Item active>Cohort Setup</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        {/* Form for adding/editing FAQ */}
                        <Col xl={12} lg={12} md={12}>
                            <Card className="mb-4">
                                <Card.Body>
                                    <Form>
                                        <Row>
                                            <Col lg={12} md={12} className="mb-3">
                                                <Form.Label>
                                                    Enable Manual Mode{' '}
                                                </Form.Label>
                                                <Form.Check
                                                    type="checkbox"
                                                    label=""
                                                    checked={enableManualMode}
                                                    className="form-switch"
                                                    onChange={() => setEnableManualMode(!enableManualMode)}
                                                />
                                            </Col>
                                        </Row>

                                        {/* Loop through careers to render input fields */}
                                        {careers && careers?.data?.data?.map((career) => (
                                            <>
                                            <Row key={career.id} className="mb-3">
                                                    <Col lg={6} md={6}>
                                                        <Form.Label>{ career.title }</Form.Label>
                                                    </Col>
                                                    <Col lg={6} md={6}>
                                                        <Form.Control
                                                            type="number"
                                                            placeholder="Enter price"
                                                            value={careerPrice.id || ''}  // Display current price if set
                                                            onChange={(e) => handlePriceChange(career.id, e.target.value)}
                                                        />
                                                    </Col>
                                                </Row>
                                                {/* Fetch and display courses for this career */}
                                                <Row>
                                                    <Col lg={12} md={12}>
                                                        <Form.Label>Courses for {career.title}</Form.Label>
                                                        {career?.courses.map((course) => (
                                                            <Form.Check
                                                                key={course.id}
                                                                type="checkbox"
                                                                label={course.title}
                                                                checked={selectedCourses[career.id]?.includes(course.id) || false}
                                                                onChange={() => handleCourseSelection(career.id, course.id)}
                                                            />
                                                        ))}
                                                    </Col>
                                                </Row>
                                            </>
                                        ))}

                                        <Row>
                                            <Col lg={12} md={12}>
                                                <AileadButton
                                                    variant="primary"
                                                    text="Save Setting"
                                                    onClick={updateSettings}
                                                    loading={loading}
                                                    type="button"
                                                />
                                            </Col>
                                        </Row>

                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                        {/* End of Form for adding/editing FAQ */}
                    </Row>
                </Col>
            </Row>
        </Fragment>
    );
};

export default Cohort;
