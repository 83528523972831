// import node module libraries
import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Nav, Tab, Image, Form } from 'react-bootstrap';

// import sub custom components
import RecentChatList from './RecentChatList';
import NewChatModal from './NewChatModal';
import { useSelector } from 'react-redux';

const Sidebar = ({ hideChatBox, setHideChatBox, setGroupId }) => {
	const [modalShow, setModalShow] = React.useState(false);
	const [isReload, setIsReload] = React.useState(false);
	const {user} = useSelector(state => state.auth);
	const BASE_URL = process.env.REACT_APP_ASSET_BASE_URL;

	return (
		<div className="bg-white border-end border-top vh-100">
			{/*  chat list */}
			<div className="chat-window">
				<div className="chat-sticky-header sticky-top bg-white">
					<div className="px-4 pt-3 pb-4">
						{/*  heading */}
						<div className="d-flex justify-content-between align-items-center">
							<div>
								<h1 className="mb-0 fw-bold h2">Discussion</h1>
							</div>
							{/*  new chat dropdown */}
							<div className="d-flex">
								<Link
									to="#"
									className="btn btn-primary rounded-circle btn-icon"
									onClick={() => setModalShow(true)}
								>
									<i className="fe fe-edit"></i>
									<div id="newchat" className="d-none">
										<span>New Discussion</span>
									</div>
								</Link>
								<NewChatModal
									show={modalShow}
									onHide={() => setModalShow(false)}
									setIsReload={setIsReload}
								/>
							</div>
						</div>
						{/*  search */}
						<div className="mt-4 mb-4">
							<Form.Control
								type="search"
								className="form-control form-control-sm"
								placeholder="Search people, group and messages"
							/>
						</div>
						{/*  User chat */}
						<div className="d-flex justify-content-between align-items-center">
							<Link to="#" className="text-link">
								<div className="d-flex">
									<div className="avatar avatar-md avatar-indicators avatar-online">
										<Image
											src={`${BASE_URL}/${user?.avatar}`}
											alt=""
											className="rounded-circle"
										/>
									</div>

									<div className=" ms-2">
										<h5 className="mb-0">{`${user?.firstName} ${user?.lastName}`}</h5>
										<p className="mb-0 text-muted">
											{user?.status}
										</p>
									</div>
								</div>
							</Link>
						</div>
					</div>
					{/*  nav tabs*/}
					<Row>
						<Col lg={12} md={12} sm={12}>
							<Tab.Container defaultActiveKey="recent">
								<Nav className="nav-line-bottom" as="ul">
									<Nav.Item as="li">
										<Nav.Link eventKey="recent" className="py-2" role="button">
											Recent
										</Nav.Link>
									</Nav.Item>
								</Nav>
								<Tab.Content className="h-100">
									<Tab.Pane eventKey="recent" className="pb-0">
										<RecentChatList
											hideChatBox={hideChatBox}
											setHideChatBox={setHideChatBox}
											setGroupId={setGroupId}
											isReload={isReload}
										/>
									</Tab.Pane>
								</Tab.Content>
							</Tab.Container>
						</Col>
					</Row>
				</div>
			</div>
		</div>
	);
};
export default Sidebar;
