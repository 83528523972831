// import node module libraries
import React, { Fragment, useEffect, useState } from 'react';
import {
	Col,
	Row,
	Breadcrumb,
	Button,
	Table
} from 'react-bootstrap';

import { usePostData } from 'hooks/usePostData';

// import sub components
import AddNewCategoryPopup from './AddNewCategoryPopup';
import { notifySuccess } from 'helper/utils';
import { useServerFilter } from 'hooks/useServerFilter';
import NewPagination from 'components/elements/advance-table/NewPagination';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { CategoryMenu } from '../business/all-courses/common/Components';

const CoursesCategory = () => {
	
	const [show, setShow] = useState(false);
	const handleClose = () => {
		setShow(false);
	}

	const handleShow = () => setShow(true);
	const {response, loading: postLoading, error: postError, postData} = usePostData('/category/create');
	const {response: updateResponse, loading: updateLoading, error: updateError, postData: updateData} = usePostData('/category/update');
	const {response: deleteResponse, loading: deleteLoading, error: deleteError, postData: postDelete} = usePostData('/category/remove');
	const BASE_URL = process.env.REACT_APP_API_BASE_URL;
	const {user} = useSelector(state => state.auth);
	const authToken = user && user?.token;

	const fetchAllCourseData = async (searchQuery, pageNumber) => {
		if (authToken !== null) {
			axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
			const response = await axios.get(`${BASE_URL}/category/list/all?page=${pageNumber}&searchQuery=${searchQuery}`);
			return response.data;
		}
	};

	const {
		filteredData: categoryData,
		searchQuery: courseSearch,
		refreshData,
		pageNumber,
		handlePageChange,
		handleSearchChange: onCourseSearchChange
	} = useServerFilter('', fetchAllCourseData);

	const lastPage = categoryData && categoryData?.data && categoryData?.data?.pagination?.lastPage;

	useEffect(() => {
		if(
			response && response?.status === "Success"
			|| deleteResponse && deleteResponse?.status === "Success"
		) {
			refreshData();
			handleClose();
			notifySuccess(deleteResponse ? `${deleteResponse?.data?.title} Category has been deleted Successfully!`: `Category Created Successfully!`);
		}

	}, [response, deleteResponse])
	

	useEffect(() => {
		if(updateResponse?.status == "Success") {
			refreshData();
			notifySuccess(`Category has been updated Successfully!`);
		}

	}, [updateResponse])

	return (
		<Fragment>
			<div className="overflow-hidden">
				<Row>
					<Col lg={12} md={12} sm={12}>
					<div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
						<div className="mb-3 mb-md-0">
							<h1 className="mb-1 h2 fw-bold">All Categories</h1>
							<Breadcrumb>
								<Breadcrumb.Item href="#">Home</Breadcrumb.Item>
								<Breadcrumb.Item href="#">Courses</Breadcrumb.Item>
								<Breadcrumb.Item active>Category</Breadcrumb.Item>
							</Breadcrumb>
						</div>
						<div>
							<Button variant="primary" onClick={handleShow}>
								Add New Category
							</Button>
							<AddNewCategoryPopup 
							  handleClose={handleClose} 
							  show={show}
							  handleSubmit={postData}
							  loading={postLoading}
							  error={postError}
							/>
						</div>
					</div>
				</Col>
				</Row>
			</div>

			<div className="ng-light table-responsive overflow-y-hidden">
				<Table
					hover
					className="text-nowrap table-centered"
				>
					<thead className="table-light">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Title</th>
                        <th scope="col">Slug</th>
                        <th scope="col">Description</th>
                        <th scope="col"></th>
                      </tr>
					</thead>
					<tbody>
                        {categoryData?.data?.data && categoryData?.data?.data.map((category, index) => (
                            <tr key={category.id}>
                                <td>{index + 1}</td>
                                <td>{category?.title}</td>
                                <td>{category?.slug}</td>
                                <td>{category?.description}</td>
                                <td> 
									<CategoryMenu 
										value={category}
										editResponse={updateResponse}
										handleDeleteConfirmation={() => postDelete({id: category.id})}
										postEditedCategory={updateData}
										confirmLoading={deleteLoading}
										editCategoryLoading={updateLoading}
										editCategoryError={updateError}
									/>
								</td>
                            </tr>
                        ))}
					</tbody>
				</Table>
			</div>

			{/* Pagination @ Footer */}
			<NewPagination
                currentPage={pageNumber}
                lastPage={lastPage}
                onPageChange={handlePageChange}
			/>
		</Fragment>
	);
};

export default CoursesCategory;


