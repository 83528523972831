
export const PaystackInputFields = [
    { label: 'Paystack Test Public Key', id: 'paystack_test_public_key', type: 'password' },
    { label: 'Paystack Test Private Key', id: 'paystack_test_secret_key', type: 'password' },
    { label: 'Paystack Live Private Key', id: 'paystack_live_secret_key', type: 'password' },
    { label: 'Paystack Live Public Key', id: 'paystack_live_public_key', type: 'password' },
    { label: 'Paystack Payment Mode', id: 'paystack_payment_mode', type: 'checkbox' },
];

export const PaypalInputFields = [
    { label: 'Paypal Test Client Id', id: 'paypal_test_client_id', type: 'password' },
    { label: 'Paypal Test Secret', id: 'paypal_test_secret', type: 'password' },
    { label: 'Paypal Live Client Id', id: 'paypal_live_client_id', type: 'password' },
    { label: 'Paypal Live Secret', id: 'paypal_live_secret', type: 'password' },
    { label: 'Paypal Mode', id: 'paypal_mode', type: 'text' },
    { label: 'Paypal Trial Plan Id', id: 'paypal_trial_plan_id', type: 'text' },
    { label: 'Paypal Subscription Plan Id', id: 'paypal_subscription_plan_id', type: 'text' },
];