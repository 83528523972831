// import node module libraries
import React, { useState, Fragment, useEffect } from 'react';

// import custom components
import GKStepper from 'components/elements/stepper/GKStepper';
import QuizDetail from './steps/QuizDetail';
import QuestionsWithOptions from './steps/QuestionsWithOptions';
import { Row, Col, Breadcrumb } from 'react-bootstrap';

const AddNewQuiz = () => {

	const [currentStep, setCurrentStep] = useState(1);
	const [formData, setFormData] = useState({
		step1: {
		  title: '',
		  description: '',
		  course: '',
		  module: null,
		  lesson: null,
		  isGradable: true,
		  type: '',
		},
		step2: {
			questions: [
				{
				  text: '',
				  type: '',
				  timer: '',
				  order: '',
				  options: []
				},
			]
		},
	});
	
	  // useEffect to retrieve data from local storage on component mount
	useEffect(() => {
	  const storedData = localStorage.getItem('quizData');
	  if (storedData) {
		  const parsedData = JSON.parse(storedData);
		  setFormData(parsedData.formData);
		  setCurrentStep(parsedData.currentStep);
	  }
	}, []);
	  
	useEffect(() => {
	  localStorage.setItem(
		  'quizData',
		  JSON.stringify({ formData, currentStep })
	  );
	}, [formData, currentStep]);

	// Function to handle form data changes
	const handleInputChange = (field, value) => {
	  setFormData((prevData) => ({
	  	...prevData,
	  	[`step${currentStep}`]: {
	  		...prevData[`step${currentStep}`],
	  		[field]: value,
	  	},
	  }));

	};

	const handleQuestionInputChange = (index, field, value) => {
		setFormData((prevFormData) => {
		  const newQuestion = [...prevFormData.step2.questions];
		  newQuestion[index] = {
			...newQuestion[index],
			[field]: value,
		  };
		  return {
			...prevFormData,
			step2: {
			  ...prevFormData.step2,
			  questions: newQuestion,
			},
		  };
		});
	  };

	const next = () => {
		setCurrentStep(currentStep === 3 ? 1 : currentStep + 1);
	};
	const previous = () => {
		setCurrentStep(currentStep === 1 ? 1 : currentStep - 1);
	};

	const steps = [
		{
			id: 1,
			title: 'Quiz Detail',
			content: (
				<QuizDetail
					formData={formData}
					handleInputChange={handleInputChange}
					next={next}
				/>
			)
		},
		{
			id: 2,
			title: 'Quiz Questions',
			content: (
				<QuestionsWithOptions
					formData={formData}
					handleInputChange={handleQuestionInputChange}
					setFormData={setFormData}
					next={next}
					previous={previous}
				/>
			)
		},
	];

	return (
		<Fragment>
			<Row>
				<Col lg={12} md={12} sm={12}>
					<div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
						<div className="mb-3 mb-md-0">
							<h1 className="mb-1 h2 fw-bold">New Quizes</h1>
							<Breadcrumb>
								<Breadcrumb.Item href="#">Courses</Breadcrumb.Item>
								<Breadcrumb.Item href="#">Quiz</Breadcrumb.Item>
								<Breadcrumb.Item active>New</Breadcrumb.Item>
							</Breadcrumb>
						</div>
					</div>
				</Col>
			</Row>
			<GKStepper currentStep={currentStep} steps={steps} />
		</Fragment>
	);
};

export default AddNewQuiz;
