// import node module libraries
import { Link } from 'react-router-dom';
import { Col, Row, Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';


const NavbarBrandOnly = () => {

	const Logo = useSelector((state) => {
		const settingArray = state.setting?.setting && state.setting?.setting.settings || [];
		const siteLogoItem = settingArray.find((item) => item.key === "site_logo");
		return siteLogoItem?.value || ''; // Return an empty string if not found
	});

	return (
		<Row>
			<Col xl={{ offset: 1, span: 2 }} lg={12} md={12}>
				<div className="mt-4">
					<Link to="/">
						<Image src={Logo} alt="" className="logo-inverse" />
					</Link>
				</div>
			</Col>
		</Row>
	);
};
export default NavbarBrandOnly;
