// import node module libraries
import React, { useContext, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
	Accordion,
	useAccordionButton,
	AccordionContext,
	ListGroup
} from 'react-bootstrap';

import Icon from '@mdi/react';
import { mdiPlay, mdiFileDocument, mdiCodeBraces, mdiText, mdiBookClockOutline, mdiClockCheck } from '@mdi/js';

const CourseItemList = ({ accordionItems, itemClass, setValue, locked }) => {
	const navigate = useNavigate();
	const launchQuiz = (id) => {
		navigate("/student/quiz/"+id);	
	}

	const ContentType = ({type}) => {

		switch(type){
			case "video":
				return (
					<Icon path={mdiPlay} size={0.8} />
				)
			
			case "file":
				return (
					<Icon path={mdiFileDocument} size={0.8} />
				)

			case "task":
				return (
					<Icon path={mdiCodeBraces} size={0.8} />
				)
			
			case "text":
				return (
					<Icon path={mdiText} size={0.8} />
				)
			case "quiz":
				return (
					<Icon path={mdiBookClockOutline} size={0.8} />
				)
			case "assessment":
				return (
					<Icon path={mdiClockCheck} size={0.8} />
				)
		}
		
	}

	const ContextAwareToggle = ({ children, eventKey, callback }) => {
		const { activeEventKey } = useContext(AccordionContext);

		const decoratedOnClick = useAccordionButton(
			eventKey,
			() => callback && callback(eventKey)
		);

		const isCurrentEventKey = activeEventKey === eventKey;

		return (
			<Fragment>
				<Link
					to="#"
					onClick={decoratedOnClick}
					aria-expanded={isCurrentEventKey}
					className="d-flex align-items-center text-inherit text-decoration-none h4 mb-0"
					data-bs-toggle="collapse"
					aria-controls="courseTwo"
				>
					<div className="me-auto">{children.title}</div>
					<span className="chevron-arrow ms-4">
						<i className="fe fe-chevron-down fs-4"></i>
					</span>
				</Link>
			</Fragment>
		);
	};

	return (
		<Fragment>
			<Accordion defaultActiveKey={accordionItems?.id}>
				<ListGroup as="ul" variant="flush">
					
					{accordionItems?.modules?.map((item, index) => {
						if (item?.lessons?.length === 0) {
							return (
								<ListGroup.Item
									key={index}
									as="li"
									className={`${itemClass ? itemClass : ''}`}
								>
									<ContextAwareToggle eventKey={item.id}>
										{item}
									</ContextAwareToggle>
									<Accordion.Collapse eventKey={item.id}>
										<ListGroup variant="flush">
											<ListGroup.Item className="border-0 fs-5 px-0 py-4">
												{item?.description}
											</ListGroup.Item>
										</ListGroup>
									</Accordion.Collapse>
								</ListGroup.Item>
							);
						} else {
							return (
								<ListGroup.Item
									key={index}
									as="li"
									className={`${itemClass ? itemClass : ''}`}
								>
									<ContextAwareToggle eventKey={item.id}>
										{item}
									</ContextAwareToggle>
									<Accordion.Collapse eventKey={item.id} className="test">
										<ListGroup className="py-4" as="ul">
											{item?.lessons?.map((subitem, subindex) => (
												<ListGroup.Item
													key={subitem.id}
													as="li"
													disabled={!subitem.isUnLocked}
													className="px-0 py-1 border-0"
												>
													<div
														style={{cursor: "pointer"}}
														onClick={(e) => {
															const contentData = {
																url: subitem?.content?.url,
																type: subitem?.content?.type,
																lessonId: subitem?.id,
																courseId: accordionItems?.id,
																title: subitem?.content?.title,
																description: subitem?.content?.description
															};
															setValue(contentData);
														}}
														className={`d-flex justify-content-between align-items-center text-inherit text-decoration-none`}
													>
														<div className="text-truncate ">
															<span className="icon-shape bg-light icon-sm rounded-circle me-2">
																{!subitem.isUnLocked ? (
																	<i className="fe fe-lock fs-4"></i>
																) : (
																	<ContentType type={subitem.content.type} />
																)}{' '}
															</span>
															<span className="fs-5">{subitem?.content?.type === "task" ? "(WorkShop) " + subitem?.title:  subitem?.title}</span>
														</div>
														<div className="text-truncate">
															<span>{subitem?.content?.duratoin}</span>
														</div>
													</div>
												</ListGroup.Item>
											))}

											{item?.quiz && (<ListGroup.Item
												as="li"
												className="px-0 py-1 border-0"
												disabled={locked}
											>
												<div
													onClick={() => launchQuiz(item?.quiz?.id)}
													className={`d-flex justify-content-between align-items-center text-inherit text-decoration-none`}
													style={{cursor: "pointer"}}
												>
													<div className="text-truncate ">
														<span className="icon-shape bg-light icon-sm rounded-circle me-2">
															
															<ContentType type={"quiz"} />{' '}

														</span>
														<span className="fs-5">{"(Quiz): "}{item?.quiz?.title}</span>
													</div>
													
												</div>
											</ListGroup.Item>)}
										</ListGroup>
									</Accordion.Collapse>
								</ListGroup.Item>
							);
						}
					})}
					
					{accordionItems?.assessment && 
					  (<ListGroup.Item
						as="li"
						className="px-0 py-1 border-0"
						disabled={locked}
					  >
						<div
						  onClick={() => launchQuiz(accordionItems?.assessment?.id)}
						  className={`d-flex justify-content-between align-items-center text-inherit text-decoration-none`}
						  style={{cursor: "pointer"}}
						>
						  <div className="text-truncate ">
						  	<span className="icon-shape bg-light icon-sm rounded-circle me-2">
							  <ContentType type={"assessment"} />{' '}
						    </span>
							<span className="fs-5">{"(Exams) "}{accordionItems.assessment?.title}</span>
						  </div>				
						</div>
					  </ListGroup.Item>)}
				</ListGroup>
			</Accordion>
		</Fragment>
	);
};

export default CourseItemList;
