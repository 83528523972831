// import node module libraries
import { Fragment, useState } from 'react';
import { Col, Row, Card, Breadcrumb } from 'react-bootstrap';

// import sub custom components
import MailSidebar from '../MailSidebar';
import MailDetailsHeader from './MailDetailsHeader';
import MailDetailsBody from './MailDetailsBody';
import MailDetailsFooter from './MailDetailsFooter';
import { useFetchData } from 'hooks/useFetchData';
import { useParams } from 'react-router-dom';

const MailDetails = () => {
	const {id} = useParams();
	const {data} = useFetchData(`/mail/view/${id}`);

	return (
		<Fragment>
			<Row>
				<Col xl={12} lg={12} md={12} xs={12}>
					<Card>
						<Row className="g-0">
							<Col xxl={10} xl={9} xs={12}>
								{/* mail detail header */}
								{/* <Card.Header>
									<MailDetailsHeader />
								</Card.Header> */}

								{/* mail detail body */}
								<Card.Body>
									<MailDetailsBody data={data?.data} />
								</Card.Body>

								{/* mail detail footer */}
								<Card.Footer className="py-4 bg-white">
									<MailDetailsFooter mail={data?.data} />
								</Card.Footer>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
		</Fragment>
	);
};
export default MailDetails;
