// import node module libraries
import React, {useEffect} from 'react';
import { Card } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { useFetchData } from 'hooks/useFetchData';
import { usePostData } from 'hooks/usePostData';

// import media files
// import SurveyImg from 'assets/images/svg/survey-img.svg';

const Quiz = () => {
	const { quizId } = useParams();
	const { data, loading } = useFetchData("/quiz/single/"+quizId);
	const {response, postData} = usePostData("/quiz/start");
	const navigate = useNavigate();

	const startQuiz = () => {
	  postData({id: quizId});
	}

	useEffect(() => {
		if(response?.data?.status) {
			navigate("/student/quiz/start/", { state: { attempts: response?.data?.data } });
		}
	},[response])

	return (
		<Card className="border-0">
			<Card.Body className="p-10">
				<div className="text-center">
					{/* <img src={SurveyImg} alt="" className="img-fluid" /> */}
					<div className="px-lg-18">
						<h1>Welcome to Quiz </h1>
						<p className="mb-0" dangerouslySetInnerHTML={{__html: data?.data && data?.data?.description}}></p>
						<div onClick={startQuiz} aria-disabled={loading} className="btn btn-primary mt-4">
							Start Your Quiz
						</div>
					</div>
				</div>
			</Card.Body>
		</Card>
	);
};

export default Quiz;
