export function mapFormDataToQuizData(formData, isUpdate = false) {
    const commonData = {
        "title": formData.step1.title,
        "description": formData.step1.description,
        "courseId": formData.step1.course,
        "moduleId": formData.step1.module,
        "lessonId": formData.step1.lesson,
        "type": formData.step1.type,
        "isGradable": formData.step1.isGradable,
    };

    const questionsData = formData.step2.questions.map((question, index) => {
        const optionsData = question.options.map((option, optionIndex) => {
            const optionData = {
                content: option.content,
                isCorrect: option.isCorrect,
            };

            if (isUpdate) {
                optionData.id = option.id;
            }

            return optionData;
        });

        const questionData = {
            text: question.text,
            type: question.type,
            timer: question.timer,
            order: question.order,
            options: optionsData,
        };

        // Add the id for the question if it's an update
        if (isUpdate && question.id) {
            questionData.id = question.id;
        }

        return questionData;
    });

    if (isUpdate) {
        // Additional logic for update data
        return {
            ...commonData,
            "id": formData.step1.id,
            "questions": questionsData,
        };
    } else {
        // Default logic for create data
        return {
            ...commonData,
            "questions": questionsData,
        };
    }
}
