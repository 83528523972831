// import node module libraries
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Card, Spinner } from 'react-bootstrap';
import { mdiArrowRight, mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import { AvatarGroup, Avatar } from 'components/elements/bootstrap/Avatar';
import { getStatusColor } from 'helper/utils';
import { useFetchData } from 'hooks/useFetchData';
import { usePostData } from 'hooks/usePostData';
import NewThreadModal from './NewThreadModal';
import AileadButton from 'components/marketing/common/button/AileadButton';
import './thread.css'

const Thread = () => {
    const navigate = useNavigate();
    const location = useLocation();
	const { groupId } = location.state || {}; // Retrieve the state data
    const { data: threads, refreshData, loading } = useFetchData(`/message/list/${groupId}`);
	const BASE_URL = process.env.REACT_APP_ASSET_BASE_URL;

	const [showModal, setShowModal] = useState(false);
	const handleShowModal = () => setShowModal(true);
	const handleCloseModal = () => setShowModal(false);
	const { response, postData, loading: loadThread, error } = usePostData(`/message/create`);

	const navigateToMessage = (threadId) => {
        navigate(`/dashboard/discussion/group/${groupId}/thread/${threadId}`, {state: { threadId, groupId }});
	};

	useEffect(() => {
		if(response?.status === "Success") {
			refreshData();
			handleCloseModal();
		}
	}, [response])

	const CardHeading = (item) => {
		return (
			<div className="d-flex align-items-center">
				<Avatar
					size="md"
					src={`${BASE_URL}/${item?.instructor?.avatar}`}
					type={'image'}
					name={item?.instructor?.fullName}
					className="rounded-circle"
				/>
				<div className="ms-3">
					<h4 className="mb-0">
						{item?.instructor?.fullName}
					</h4>
					<span className="text-muted fs-6">{item?.category}</span>
				</div>
			</div>
		);
	};

	return (
        <Fragment>
			{/* Modal for creating thread */}
			<NewThreadModal
				showModal={showModal}
				handleClose={handleCloseModal}
				submit={postData}
				loading={loadThread}
				groupId={groupId}
				error={error}
			/>
			{/* If no threads */}
			{threads?.data?.data.length === 0 && !loading && (
				<div className="d-flex justify-content-center align-items-center flex-column" style={{ height: '50vh' }}>
					<p>No threads yet. Be the first to create one!</p>
					<AileadButton variant="primary" type="button" onClick={handleShowModal} text="Create New Thread" />
				</div>
			)}

			{/* Loading state */}
			{loading && (
				<div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
					<Spinner animation="border" role="status">
						<span className="visually-hidden">Loading...</span>
					</Spinner>
					<p className="ms-3">Loading Threads, please wait...</p>
				</div>
			)}

			{/* Render threads */}
			{threads?.data?.data.length > 0 && threads?.data?.data?.map((thread, i) => (
				<Card className="h-100 mb-4" key={i}>
					{thread?.attachments?.map((attachment, i) =>  {
						if (attachment.mime_type && attachment.mime_type.startsWith("image/")) {
							return (
								<Fragment key={i}>
									<Card.Img
										variant="top"
										src={attachment.file_path} // Use the attachment's URL
										className="img-fluid rounded-top"
									/>
								</Fragment>
							);
						}
						return null; // If it's not an image, return null
					})}
					<Card.Body>
						{/* heading*/}
						{thread?.coverimage == null ? (
							<div className="d-flex align-items-center justify-content-between">
								{CardHeading(thread)}
							</div>
						) : (
							CardHeading(thread)
						)}

						<div className="mt-3 mb-4">
							<p className="mb-0" dangerouslySetInnerHTML={{__html: thread?.content}}></p>
						</div>
						
						{/* progress */}
						<div className="d-flex justify-content-between align-items-center mb-5">
							{/* text */}
							<div>
								<span
									className={`badge bg-light-${getStatusColor(
										thread?.status
									)} text-dark-${getStatusColor(thread?.status)}`}
								>
									{thread?.status}
								</span>
							</div>
						</div>
						<div>
						{/* Display reactions here */}
						</div>
					</Card.Body>

					{/* card footer */}
					<Card.Footer className="p-0" style={{cursor: "pointer"}} onClick={() => navigateToMessage(thread.id)}>
						<div className="d-flex justify-content-between p-2">
							<div className="d-flex align-items-center">
								{/* avatar group */}
								<AvatarGroup>
									{thread?.replies?.map((reply, index) => 
										(
											<Avatar
												size="md"
												src={`${BASE_URL}/${reply?.avatar}`}
												type={'image'}
												name={reply?.fullName}
												className="rounded-circle"
												imgtooltip
												key={index}
											/>
										)
									)}
									<Avatar
										size="md"
										type="initial"
										name={thread?.replyCounts >= 100 ? '100+' : thread?.replyCounts.toString()}
										variant="light"
										className="rounded-circle text-dark"
										showExact
									/>
								</AvatarGroup>
								<div className='ml-4'>{thread?.replyCounts} Replies </div>
							</div>
							<Icon
								path={mdiArrowRight}
								className="text-primary ms-1"
								size={1.2}
							/>
						</div>
					</Card.Footer>
				</Card>
			))}

			{/* Floating Button */}
			<button className="floating-button" onClick={handleShowModal}>
				<Icon path={mdiPlus} size={1.5} />
			</button>

		</Fragment>
	);
};

export default Thread;
