import { Fragment, useState, useEffect } from 'react';
import { Form, Button, Modal, Badge, ToggleButtonGroup, ToggleButton, ProgressBar} from 'react-bootstrap';

// import custom components
import { useFileUpload } from 'hooks/useFileUpload';
import { usePostData } from 'hooks/usePostData';
import AileadButton from 'components/marketing/common/button/AileadButton';
import RichTextEditor from 'components/elements/new-editor/RichTextEditor';


export const AddLessonModal = ({ moduleIndex, addLesson, initialLesson, lessonIndex, formData }) => {
	const [show, setShow] = useState(false);
	const {response: validationResponse, loading: loadingValidation, error: validationErrors, postData} = usePostData('/course/validate/lesson');
	const {response: verifyVideoResponse, loading: loadingVerifyVideo, error: errorVerifyingVideo, postData : verifyVideo} = usePostData('/course/youtube');
	const { serverResponse, uploadError, uploadProgress, isUploading, handleFileChange, handleUpload } = useFileUpload('/module/upload/doc');
	
	const defaultInit = {
		title: '',
		description: '',
		order: "",
		isLocked: false,
		content: {
		type: 'video',  // Set an initial value for type
		data: {},
		},
	};

    const [lesson, setLesson] = useState(defaultInit);
	const [isFile, setIsFile] = useState("");

	useEffect(() => {
		if (initialLesson) {
			setLesson(initialLesson);
		}
	}, [initialLesson]);

	const handleClose = () => {
		if(!initialLesson) {
			// Reset lesson state when closing
			setLesson(defaultInit);
		}
		setShow(false);
	};

	const handleShow = () => {
		if(!initialLesson) {
			// Reset lesson state when open
			setLesson(defaultInit);
		}
		setShow(true)
	};
  
	const handleAddLesson = () => {
		// Generate a unique identifier for the order
		// Find the maximum order value among existing lessons in the module
		const maxOrder = formData.step4.modules[moduleIndex].lessons.reduce((max, lesson) => Math.max(max, lesson.order), 0);
    
		// Generate the order for the new lesson by incrementing the maximum order
		const newOrder = parseInt(maxOrder + 1);

		// Create a new lesson object with the generated order
		const newLesson = {
			...lesson,
			order: newOrder
		};

		const addNewLesson = initialLesson ? lesson : newLesson;

		// Perform any necessary actions before closing the modal
		addLesson(moduleIndex, addNewLesson, lessonIndex);
		handleClose();
	};
  
	const handleContentTypeChange = (e) => {
	  // Update content type and reset content data when content type changes
	  setLesson({
		...lesson,
		content: {
		  ...lesson.content,
		  type: e.target.value,
		},
	  });

	};
  
	const handleContentInputChange = (field, value) => {
	  //Update content data based on the input field
	  setLesson((prevLesson) => ({
	  	...prevLesson,
	  	content: {
	  	  ...prevLesson.content,
	  	  data: {
	  	  	...prevLesson.content.data,
	  	  	[field]: value,
	  	  },
	  	},
	  }));
	};

	const handleVideoUrlValidation = () => {
	  verifyVideo({ videoId: lesson.content.data.url });
	}

	// move to next step
	const addLectureStep = () => {
		postData(lesson);
	}

	// if lesson data is validated, update the lessons state
	useEffect(() => {
		if(validationResponse !== null && validationResponse?.status === "Success"){
			handleAddLesson();
		}
	}, [validationResponse])
  
	return (
	  <Fragment>
		{
			initialLesson ? 
				<Button 
					className="me-1 text-inherit" 
					title="Edit"
					onClick={handleShow}
				>
					<i className="fe fe-edit fs-6"></i>
					
				</Button> :

				<Button
					variant="outline-primary"
					className="btn btn-outline-primary btn-sm mt-3"
					onClick={handleShow}
				>
					Add Lesson +
				</Button>
		}
		<Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg">
		  <Modal.Header closeButton>
			<Modal.Title>Add New Lesson</Modal.Title>
		  </Modal.Header>
		  <Modal.Body className="pb-0">
			<Form.Group className="mb-3" controlId="formaddnewlecture">
			<Form.Label>Lesson Title <Form.Text className='text-danger'>*</Form.Text></Form.Label>
			  <Form.Control
				type="text"
				placeholder="Add Lesson Title"
				className='mb-4'
				value={lesson.title}
				onChange={(e) => setLesson({ ...lesson, title: e.target.value })}
			  />
			  {validationErrors && validationErrors.title && (
				<Form.Text className="col-sm-6 text-danger mb-2 mt-2">
					{validationErrors.title[0]}
				</Form.Text>
			  )}

			  <Form.Label>Lesson Description <Form.Text className='text-danger'>*</Form.Text></Form.Label>
				<RichTextEditor
					editorValue={lesson.description}
					onChange={
						(event, editor) => {
							const newData = editor.getData();
							setLesson({ ...lesson, description: newData })
						}
					}
				/>

			  {validationErrors && validationErrors.description && (
				<Form.Text className="col-sm-6 text-danger mb-2 mt-2">
					{validationErrors.description[0]}
				</Form.Text>
			  )}

			  <Form.Label>
				  Unlock Content {' '}
				<small className="text-muted">
				  (Check to enable users to view this content )
				</small>
			  </Form.Label>
			  <Form.Check
				type="checkbox"
				label=""
				checked={lesson.isLocked}
				className="form-switch"
				onChange={(e) => setLesson({ ...lesson, isLocked: !lesson.isLocked })}
			  />
			  
			  <Form.Label>Choose Lesson Content Type <Form.Text className='text-danger'>*</Form.Text></Form.Label>
			  <Form.Select
				className='mb-4'
				value={lesson.content.type}
				onChange={handleContentTypeChange}
			  >
				<option value="video">Video</option>
				<option value="text">Text</option>
				<option value="file">File</option>

			  </Form.Select>
  
			  {/* Additional fields based on content type */}
			  {lesson.content.type === 'video' && (
				<>
				<Form.Label>Choose Video <Form.Text className='text-danger'>*</Form.Text></Form.Label>
				
				{validationErrors && validationErrors.content && (
					<Badge pill bg="danger"  className="me-1 mt-3">{validationErrors.content[0]}</Badge>
				)}

				<Form.Control
					type="text"
					placeholder="Enter Video URL"
					name="url"
					required
					className='mb-4'
					value={lesson.content.data.url || ''}
					onChange={(e) => handleContentInputChange('url', e.target.value)}
				/>
				{errorVerifyingVideo && errorVerifyingVideo.url && (
					<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
						{errorVerifyingVideo.url[0]}
					</Form.Text>
				)}

				<AileadButton type="button" variant="primary" text="Validate Video URL" className='mt-4 mb-3' loading={loadingVerifyVideo} onClick={handleVideoUrlValidation} />

				</>
			  )}
  
			  {lesson.content.type === 'text' && (
				<>
					<Form.Control
						type="text"
						className='mb-4'
						placeholder="Add Title Content"
						name="title"
						value={lesson.content.data.title || ''}
						onChange={(e) => handleContentInputChange('title', e.target.value)}
					/>
					
					<Form.Control
						type="textarea"
						className='mb-4'
						placeholder="Add Text Content"
						name="textContent"
						value={lesson.content.data.textContent || ''}
						onChange={(e) => handleContentInputChange('textContent', e.target.value)}
					/>
					
					{validationErrors && validationErrors.content && (
						<Form.Text className="col-sm-6 text-danger mb-2 mt-3">
							{validationErrors.content[0]}
						</Form.Text>
					)}					
				</>
			  )}
  
			  {lesson.content.type === 'file' && (
				<>
					{ isFile === "task-file" ? (<>
						<Form.Control
							type="file"
							placeholder="Add File"
							name="url"
							onChange={handleFileChange}
						/>
						{uploadError?.url &&  uploadError?.url && (
							<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
								{uploadError?.url[0]}
							</Form.Text>
						)}

						<AileadButton type="button" variant="primary" className='mb-5 mt-3' onClick={handleUpload} text="Upload File" />

						{isUploading && <ProgressBar
							striped
							variant="success"
							label={`${uploadProgress}%`}
							now={uploadProgress}
							className="flex-auto mt-4"
							style={{ height: '12px' }}
						/>}

						</>) : (<>
							<Form.Control
							type="text"
							placeholder="Add Task Link"
							name="url"
							onChange={(e) => handleContentInputChange('url', e.target.value)}
						/>

						{validationErrors &&  validationErrors?.url(
							<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
								{validationErrors?.url[0]}
							</Form.Text>
						)}
						</>)
					}
					<div>
						<ToggleButtonGroup type="radio" className='mt-3' name="options" onClick={(e) => setIsFile(e.target.value)} defaultValue={"task-link"}>
							<ToggleButton id="tbg-radio-1" value={"task-file"} variant="outline-primary">
								Upload File
							</ToggleButton>
							<ToggleButton id="tbg-radio-2" value={"task-link"} variant="outline-primary">
								Task Link
							</ToggleButton>
						</ToggleButtonGroup>
					</div>
				</>
			  )}
			</Form.Group>
		  </Modal.Body>
		  <Modal.Footer className="pt-0 border-0 d-inline">
			<AileadButton type="button" variant="primary" onClick={addLectureStep} loading={loadingValidation} text={initialLesson ? "Update Lesson" : "Add New Lesson"} />
			<AileadButton type="button" variant="outline-secondary" onClick={handleClose} text="Close" />
		  </Modal.Footer>
		</Modal>
	  </Fragment>
	);
  };