import { toast } from 'react-toastify';

/**
 * Functions in utils
 */

/**
 * Add commas to a number
 * v1.0.0
 */
export const numberWithCommas = (x, decimal = 0) => {
	return x.toLocaleString('en-US', { minimumFractionDigits: decimal });
};

export function formatNumberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/**
 * Get the file extension from given file name
 * v1.2.0
 */
export const getFileExtension = (filename) => {
	const extension = filename.split('.').pop();
	return extension;
};

/**
 * Get the random number between min and max value
 * v1.2.0
 */
export const getRandomNo = (min = 0, max = 100) => {
	return Math.floor(Math.random() * (max - min + 1)) + min;
};

/**
 * Get the color name/value based on given status
 * v1.2.0
 */
export const getStatusColor = (itemstatus) => {
	let color = '';
	switch (itemstatus) {
		case 'In Progress':
			color = 'info';
			break;
		case 'Pending':
			color = 'warning';
			break;
		case 'Finished':
			color = 'success';
			break;
		case 'Cancel':
			color = 'danger';
			break;
		default:
			color = 'primary';
	}
	return color;
};

export const notifySuccess = (message) => {
	toast.success(message, {
		position: toast.POSITION.TOP_RIGHT
	});
}

export const notifyError = (error) => {
	toast.error(error, {
		position: toast.POSITION.TOP_LEFT
	});
}


/**
 * Get the color name/value based on given status
 * v1.2.0
 */
export const getCategoryColor = (category) => {
	let color = '';
	switch (category) {
		case 'Saas Services':
		case 'Entertainment':
		case 'Extra':
			color = 'info';
			break;
		case 'Design':
			color = 'warning';
			break;
		case 'Marketing':
			color = 'success';
			break;
		case 'Development':
			color = 'danger';
			break;
		case 'SEO':
			color = 'primary';
			break;
		default:
			color = 'primary';
	}
	return color;
};

//get chunk from array
export const chunk = (arr, chunkSize = 1, cache = []) => {
	const tmp = [...arr];
	if (chunkSize <= 0) return cache;
	while (tmp.length) cache.push(tmp.splice(0, chunkSize));
	return cache;
};

// function to get time value in hh:mm AM | PM format
export const getTimeValue = (date) => {
	var hours = date.getHours();
	var minutes = date.getMinutes();
	var ampm = hours >= 12 ? 'PM' : 'AM';
	hours = hours % 12;
	hours = hours ? hours : 12; // the hour '0' should be '12'
	minutes = minutes < 10 ? '0' + minutes : minutes;
	var strTime = hours + ':' + minutes + ' ' + ampm;
	return strTime;
};

// function to get date value in Month Name DD, YYYY format
export const getDateValue = (date) => {
	const month = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec'
	];
	const yyyy = date.getFullYear();
	let mm = date.getMonth();
	let dd = date.getDate();
	var today = month[mm] + ' ' + dd + ', ' + yyyy;
	return today;
};

// function to generate slug or ID with slug format
export const getSlug = (text) => {
	text = text.toLowerCase();
	text = text.replace(/ /g, '-').replace(/[^\w-]+/g, '');
	return text;
};


export const quizData = (action, setFormData, questionIndex, optionIndex, newData, postData) => {
	setFormData(prevData => {
	  switch (action) {
		case 'addQuestion':
			const existingQuestion = prevData.step2.questions.filter(question => (
				question.text || question.type || question.timer || question.options.length > 0
			));
	  
			const updatedQuestions = [
				...existingQuestion,
				{
					text: newData.text,
					type: newData.type,
					timer: newData.timer,
					order: newData.order,
					options: [],
				},
			];
	  
			return {
				...prevData,
				step2: {
				  ...prevData.step2,
				  questions: updatedQuestions,
				},
			};

			case 'removeQuestion': {
				const updatedQuestions = prevData.step2.questions.filter(question => {
					if (question.order === newData.order) {
						// Check if the lesson has an ID (exists in the backend database)
						if (question.id) {
						  // Make a backend request to delete the question from the database
						  // Assuming you have a deleteLessonFromBackend function to handle this
						  postData({ id: question.id });
						  console.log("has id", question.order)
						}
						// Always return false to remove the lesson from the frontend state
						return false;
					}
					return true;
				});
				return {
					...prevData,
					step2: {
					...prevData.step2,
					questions: updatedQuestions,
					},
				};
			}
			 
			case 'updateQuestion': {
				const updatedQuestions = prevData.step2.questions.map(question => {
					if (question.order === newData.order) {
						return {
							...question,
							...newData,
						};
					}
					return question;
				});
		  
				return {
					...prevData,
					step2: {
					...prevData.step2,
					questions: updatedQuestions,
					},
				};
			}
	
		case 'addOptions':
		  return {
			...prevData,
			step2: {
			  ...prevData.step2,
			  questions: prevData.step2.questions.map((question, mIndex) =>
				mIndex === questionIndex
				  ? {
					  ...question,
					  options: [...question.options, newData],
					}
				  : question
			  ),
			},
		  };
		case 'removeOptions':
		  return {
			...prevData,
			step2: {
			  ...prevData.step2,
			  questions: prevData.step2.questions.map((question, mIndex) =>
				mIndex === questionIndex
				  ? {
					  ...question,
					  options: question.options.filter((option, lIndex) => lIndex !== optionIndex),
					}
				  : question
			  ),
			},
		  };
		case 'updateOption':
		  return {
			...prevData,
			step2: {
			  ...prevData.step2,
			  questions: prevData.step2.questions.map((question, mIndex) =>
				mIndex === questionIndex
				  ? {
					  ...question,
					  options: question.options.map((option, lIndex) =>
						lIndex === optionIndex ? newData : option
					  ),
					}
				  : question
			  ),
			},
		  };
		default:
		  return prevData;
	  }
	});
};

// handle axios error request
export const handleErrorResponse = (error, setError, notifyError, logout) => {
	if (error.response) {
	  switch (error.response.status) {
		case 422:
		  let receivedValidationErrors = error.response.data.errors;
		  if (receivedValidationErrors === null || receivedValidationErrors === undefined) {
			receivedValidationErrors = error.response.data.message;
			setError(receivedValidationErrors);
			notifyError(receivedValidationErrors);
		  } else {
			setError(receivedValidationErrors);
		  }
		  break;
  
		case 400:
		  if (error.response.data.errors && error.response.data.errors.length > 0) {
			const receivedValidationErrors = error.response.data.errors;
			setError(receivedValidationErrors);
		  } else {
			const receivedValidationErrors = error.response.data.message;
			notifyError(receivedValidationErrors);
			setError(receivedValidationErrors);
		  }
		  break;
  
		case 401:
		  logout();
		  notifyError("You are not logged In.");
		  break;
  
		case 500:
		  if (error.response.data.message) {
			const receivedValidationErrors = error.response.data.message;
			notifyError(receivedValidationErrors);
		  }
		  break;
  
		default:
		  // Handle other specific error codes if needed
		  break;
	  }
	} else if (error.request) {
	  // Network error (e.g., no internet connection)
	  notifyError("Network error. Please check your internet connection.");
	} else if (error.isAxiosError && error.code === 'ECONNABORTED') {
	  // Slow network response
	  notifyError("Network error: Request timeout. Please try again later.");
	} else {
	  // Other unexpected errors
	  notifyError("An unexpected error occurred. Please try again later.");
	}
  };


export const curriculumData = (action, setFormData, moduleIndex, lessonIndex, newData, postData) => {

	setFormData(prevData => {
	  switch (action) {
		case 'addModule':
			const existingModules = prevData.step4.modules.filter(module => (
				// Filter out the initial empty object
				module.title || module.description || module.lessons.length > 0
			));
	  
			const updatedModules = [
				...existingModules,
				{
				  title: newData.title,
				  description: newData.description,
				  order: newData.order,
				  lessons: [],
				}
			];
	  
			return {
				...prevData,
				step4: {
				  ...prevData.step4,
				  modules: updatedModules,
				},
			};

		case 'removeModule': {
			const updatedModules = prevData.step4.modules.filter(module => {
				// module.order !== newData.order

				if (module.order === newData.order) {
					// Check if the lesson has an ID (exists in the backend database)
					if (module.id) {
					  // Make a backend request to delete the module from the database
					  // Assuming you have a deleteLessonFromBackend function to handle this
					  postData({moduleId: module.id});
					}
					// Always return false to remove the lesson from the frontend state
					return false;
				  }
				  return true;
			});
			return {
				...prevData,
				step4: {
				...prevData.step4,
				modules: updatedModules,
				},
			};
		 }
		 
		case 'updateModule': {
			const updatedModules = prevData.step4.modules.map(module => {
				if (module.order === newData.order) {
				return {
					...module,
					...newData,
				};
				}
				return module;
			});
	  
			return {
				...prevData,
				step4: {
				...prevData.step4,
				modules: updatedModules,
				},
			};
		  }

		case 'addLesson':
		  return {
			...prevData,
			step4: {
			  ...prevData.step4,
			  modules: prevData.step4.modules.map((module, mIndex) =>
				mIndex === moduleIndex
				  ? {
					  ...module,
					  lessons: [...module.lessons, newData],
					}
				  : module
			  ),
			},
		  };
		case 'removeLesson':
		  return {
			...prevData,
			step4: {
			  ...prevData.step4,
			  modules: prevData.step4.modules.map((module, mIndex) =>
				mIndex === moduleIndex
				  ? {
					  ...module,
					  lessons: module.lessons.filter((lesson, lIndex) => {
						if (lIndex === lessonIndex) {
						  // Check if the lesson has an ID (exists in the backend database)
						  if (lesson.id) {
							// Make a backend request to delete the lesson from the database
							// Assuming you have a deleteLessonFromBackend function to handle this
							postData({lessonId: lesson.id});
						  }
						  // Always return false to remove the lesson from the frontend state
						  return false;
						}
						return true;
					  }),
					}
				  : module
			  ),
			},
		  };
		case 'updateLesson':
		  return {
			...prevData,
			step4: {
			  ...prevData.step4,
			  modules: prevData.step4.modules.map((module, mIndex) =>
				mIndex === moduleIndex
				  ? {
					  ...module,
					  lessons: module.lessons.map((lesson, lIndex) =>
						lIndex === lessonIndex ? newData : lesson
					  ),
					}
				  : module
			  ),
			},
		  };
		default:
		  return prevData;
	  }
	});
};


export const validateQuestions = (questions, minOptions) => {
	let hasError = false;
  
	questions.forEach((question, questionIndex) => {
	  if (question.options.length < minOptions) {
		notifyError(`Question ${questionIndex + 1} must have at least ${minOptions} options`);
		hasError = true;
		return;
	  }
	});
  
	return !hasError;
};

const utils = [
	numberWithCommas,
	getFileExtension,
	getRandomNo,
	getStatusColor,
	chunk,
	getTimeValue,
	getDateValue,
	getSlug,
	notifySuccess,
	notifyError,
	quizData,
	curriculumData
];

export default utils;
