import { useState, useEffect } from 'react';
import { Card, Form } from 'react-bootstrap';
import { notifyError, notifySuccess } from 'helper/utils';
import AileadButton from 'components/marketing/common/button/AileadButton';
import CurriculumCard from '../dragndrop/CurriculumCard';
import { courseData } from 'data/dashboard/CourseData';
import { usePostData } from 'hooks/usePostData';
import { useNavigate } from 'react-router-dom';

  const Curriculum = (props) => {
	const { previous, formData, setFormData } = props;	  

	const { postData: submitCourse, response: courseResponse, loading, error } = usePostData("/course/create");
	const navigate = useNavigate();

	const validateNext = () => {
		let hasError = false;
		formData.step4.modules.forEach((item, index) => {
			if (item.lessons.length === 0) {
				notifyError("Module " + (index + 1) + " cannot have an empty Lesson");
				hasError = true;
				return;
			}
		});

		// Check if there was an error, and only call next() if there was no error
		if (!hasError) {
			const coursesData = courseData(formData);
			submitCourse(coursesData);
		}
	}

	useEffect(() => {
		const submitData = async () => {
			if (courseResponse && courseResponse.status === "Success") {
				notifySuccess("You have successfully created course with module(s) and lesson(s)!");
				localStorage.removeItem("formDataKey");
				navigate('/dashboard/courses/all-courses');
			}

		};
		
		submitData();

	}, [courseResponse]);

	return (
		<Form>
			<Card className="mb-3 border-0">
				<Card.Header className="border-bottom px-4 py-3">
				<h4 className="mb-0">Curriculum</h4>
				</Card.Header>
				<CurriculumCard setFormData={setFormData} formData={formData} />
			</Card>
			<div className="d-flex justify-content-between">
				<AileadButton type="button" variant="secondary" onClick={previous} text="Previous" />
				<AileadButton variant="primary" onClick={validateNext} text="Submit  For Review" loading={loading} type="button" />
			</div>
    	</Form>
	);
};
export default Curriculum;
