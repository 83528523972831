import React, {useState, useEffect} from 'react';
import { Card } from 'react-bootstrap';
import { useFetchData } from 'hooks/useFetchData';
import { useLocation } from 'react-router-dom';
import axios from 'axios'

const QuizResult = () => {

	const location = useLocation();
	const assessmentId = location.state;
	//const { data: quizData, loading, error } = assessmentId ? useFetchData("assessment/results/" + assessmentId) : { data: null, loading: false, error: null };

	// Define loading and error states
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Define quizData state
    const [quizData, setQuizData] = useState(null);

    // Fetch data when assessmentId changes
    useEffect(() => {

		const fetchData = async () => {
			if (assessmentId) {
				// Fetch quiz data
				setLoading(true);
				try{
					const response = await axios.get("assessment/results/" + assessmentId);
					setQuizData(response.data.data);
					setLoading(false);
				} catch(e) {
					setError(e.message)
				}
				finally{
					setLoading(false);
				}
			}
		}

		fetchData();
    }, [assessmentId]);

    const renderResultDetails = () => {
        return quizData && quizData.map((quiz, index) => (
            <div key={quiz.id} className="mb-4">
                <h5>Question {index + 1}:</h5>
                <p>{quiz.question}</p>
                <p><strong>Your Answer:</strong> {quiz.userAnswer}</p>
                <p><strong>Correct Answer:</strong> {quiz.correctAnswer}</p>
            </div>
        ));
    };

    return (
        <Card>
            <Card.Body className="p-10 text-center">
                <div className="mb-4">
                    <h1>Congratulations on successfully completing the quiz! <span role="img" aria-label="Party Popper">🎉</span></h1>
                    <p>Your participation in the quiz demonstrates your eagerness to expand your knowledge and skills.</p>
                    <p>Below are the details of your quiz:</p>
                    {loading ? <p>Loading...</p> : renderResultDetails()}
                </div>
            </Card.Body>
        </Card>
    );
};

export default QuizResult;
