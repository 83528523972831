// import node module libraries
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, Image, Row, Form } from 'react-bootstrap';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { MapPin } from 'react-feather';

// import utility file
import { notifySuccess, numberWithCommas } from 'helper/utils';
import { usePostData } from 'hooks/usePostData';
import { useFetchData } from 'hooks/useFetchData';
import AileadButton from 'components/marketing/common/button/AileadButton';
import NewPagination from 'components/elements/advance-table/NewPagination';
import { ViewUserModal } from '../all-courses/common/Components';

const StudentsGridCard = ({ userData, lastPage, currentPage, handlePageChange, searchQuery, handleSearchChange }) => {

	const [selectedStudent, setSelectedStudent] = useState(null);
	const [show, setShow] = useState(false);
	const handleClose = () => {
		setShow(false);
		setSelectedStudent(null);
	}

	const handleShow = (userId) => {
		const employeeData = data && data?.data?.find(user => user.id === userId);
		setSelectedStudent(employeeData);
		setShow(true);
	}

	const { response, postData } = usePostData("/user/activation");
	const { data } = useFetchData("/business/employee/analytics");

	const handleViewEmployee = async (user, setIsActive) => {
		await postData({ userId: user.id });
		if(response && response?.status === "Success") {
			setIsActive(response?.data?.isActive);
			notifySuccess("You have updated user status!");
		}
	}

	const [activeStatuses, setActiveStatuses] = useState({});

	useEffect(() => {
	  const initialStatuses = {};
	  userData?.data && userData?.data?.forEach(student => {
	  	initialStatuses[student.id] = student.isActive;
	  });
	  setActiveStatuses(initialStatuses);
	}, [userData]);
  
	const toggleActiveStatus = async (student) => {
		const currentStatus = activeStatuses[student.id];
		const newStatus = !currentStatus;
		
		// Optimistically update the state
		setActiveStatuses(prevStatuses => ({
		  ...prevStatuses,
		  [student.id]: newStatus
		}));
		
		// Perform the API call
		await handleViewEmployee(student, (updatedStatus) => {
		  setActiveStatuses(prevStatuses => ({
			...prevStatuses,
			[student.id]: updatedStatus
		  }));
		});
	};
  

	const displayStudents = userData?.data && userData?.data
		.map((students) => {
			return (
				<Col xl={3} lg={6} md={6} sm={12} key={students.id}>
					<Card className="mb-5">
						<Card.Body>
							<div className="text-center">
								<div className="position-relative">
									<Image
										src={students.avatar}
										className="rounded-circle avatar-xl mb-3"
										alt=""
									/>
								</div>
								<h4 className="mb-0">{students.firstName} {" "} {students.lastName}</h4>
								<p className="mb-0">
									<MapPin size="12" className="me-1 fs-6" />
									{students.country}
								</p>
							</div>
							<div className="d-flex justify-content-between border-bottom py-2">
								<span>Payments</span>
								<span className="text-dark">
									{students.totalPayment && '$' + numberWithCommas(students.totalPayment)}
								</span>
							</div>
							<div className="d-flex justify-content-between border-bottom py-2">
								<span>Added</span>
								<span>{students.addedDate}</span>
							</div>
							<div className="d-flex justify-content-between border-bottom py-2">
								<span>Last Seen</span>
								<span>{students.lastSeen}</span>
							</div>
							<div className="d-flex justify-content-between border-bottom py-2">
								<span>Enrolled</span>
								<span className="text-dark"> {students.totalEnrolledCourses} </span>
							</div>

							<div className="d-flex justify-content-between border-bottom py-2">
								<span>Completed</span>
								<span className="text-dark"> {students.totalCourseCompleted} </span>
							</div>
							<div className="d-flex justify-content-between border-bottom py-2">
								<span>In Progress</span>
								<span className="text-dark"> {students.totalCourseInprogress} </span>
							</div>
						</Card.Body>
						<div className="d-flex justify-content-between border-bottom py-2 px-4">
							<Form.Check
								type="checkbox"
								checked={activeStatuses[students.id] || false}
								className="form-switch"
								onChange={() => toggleActiveStatus(students)}
							/>

							<AileadButton 
								text={"View User"} 
								type="button" 
								onClick={() => handleShow(students.id)}
							/>
						</div>
					</Card>
				</Col>
			);
		});

	return (
		<Fragment>
			<div className="mb-4">
				<Form.Control
					type="search"
					placeholder="Search Students"
					value={searchQuery}
					onChange={handleSearchChange}
				/>
			</div>
			<Row>
				{displayStudents && displayStudents.length > 0 ? (
					displayStudents
				) : (
					<Col>
						No matching students found.
					</Col>
				)}
			</Row>

			<NewPagination
				currentPage={currentPage}
                lastPage={lastPage}
                onPageChange={handlePageChange}
			/>

			 <ViewUserModal handleClose={handleClose} show={show} data={selectedStudent} />
		</Fragment>
	);
};

export default StudentsGridCard;
