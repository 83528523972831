// reducer.js
import { SENT_MAIL } from '../actions/sentMailsAction';

const initialState = {
  isReload: false,
};

const sentMailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SENT_MAIL:
      return {
        ...state,
        isReload: true,
      };
    default:
      return state;
  }
};

export default sentMailsReducer;
