
import { Card } from 'react-bootstrap';
import { Fragment, useState } from 'react';
import Questions from './Question';
import { AddQuestionModal } from '../steps/modals/AddQuestionModal';
import { quizData as dataFromQuiz } from 'helper/utils';
import DropArea from './DropArea';

const QuizCard = ({ formData, setFormData }) => {

    const [activeQuestion, setActiveQuestion] = useState(null);
    const [activeOption, setActiveOption] = useState(null);
    
    const addQuestion = (newQuestion) => {
		  dataFromQuiz("addQuestion", setFormData, null, null, newQuestion);
	  };

    const onOptionDrop = (targetModulePosition, optionPosition) => {
      if (activeOption === null || activeOption === undefined) return;
    
      const questionsCopy = [...formData.step2.questions]; // Make a copy of the questions array
    
      // Get the option to move from the active question
      const optionToMove = questionsCopy[activeQuestion].options[activeOption];
    
      if (activeQuestion === targetModulePosition) {
        // Moving option within the same question
        const updatedOptions = [...questionsCopy[activeQuestion].options];
        const sourceIndex = activeOption;
        let targetIndex = optionPosition;
    
        // Adjust target index if moving the option within the same question
        if (sourceIndex < targetIndex) {
          targetIndex--; // Decrement target index if source index is less than target index
        }
    
        // Remove the option from its original position and insert it at the new position
        updatedOptions.splice(sourceIndex, 1);
        updatedOptions.splice(targetIndex, 0, optionToMove);
    
        // Update the options array of the active question
        questionsCopy[activeQuestion].options = updatedOptions;
      } else {
        // Moving option to a different question
        const updatedSourceModuleLessons = questionsCopy[activeQuestion].options.filter((option, index) => index !== activeOption);
        const updatedTargetModuleLessons = [...questionsCopy[targetModulePosition].options];
        updatedTargetModuleLessons.splice(optionPosition, 0, optionToMove);
    
        // Update the options array of the active question and the target question
        questionsCopy[activeQuestion].options = updatedSourceModuleLessons;
        questionsCopy[targetModulePosition].options = updatedTargetModuleLessons;
      }
    
      // Update the questions array
      const updatedModules = questionsCopy.map((question, index) => {
        if (index === activeQuestion || index === targetModulePosition) {
          return {
            ...question,
            options: questionsCopy[index].options.map((option, i) => {
              return {
                ...option,
                order: i + 1
              }
            })
          };
        } else {
          return question;
        }
      });
    
      // Update the form data with the modified questions array
      setFormData(prevFormData => ({
        ...prevFormData,
        step2: {
          ...prevFormData.step2,
          questions: updatedModules
        }
      }));
    };
    

    const onModuleDrop = (targetModulePosition) => {
      const questionsCopy = [...formData.step2.questions]; // Make a copy of the questions array
    
      // Remove the question from its original position
      const [movedModule] = questionsCopy.splice(activeQuestion, 1);
    
      // Insert the question into the target position
      questionsCopy.splice(targetModulePosition, 0, movedModule);
    
      // Update the questions array in the form data
      setFormData(prevFormData => ({
        ...prevFormData,
        step2: {
          ...prevFormData.step2,
          questions: questionsCopy.map((question, index) => {
            return {
              ...question,
              order: index + 1
            }
          })
        }
      }));
    };
    
    return (
      <Card.Body>
        <Fragment>
          <DropArea title={"Module"} onDrop={() => onModuleDrop(0)} />
          {formData.step2.questions.map((question, index) => (
              <Fragment key={index}>
                <Questions 
                  key={index}
                  question={question} 
                  questionIndex={index} 
                  formData={formData} 
                  setFormData={setFormData} 
                  handleActiveQuestion={setActiveQuestion}
                  handleActiveOption={setActiveOption}
                  onOptionDrop={onOptionDrop}
                  
                />
                <DropArea title={"Module"} onDrop={() => onModuleDrop(index + 1)} />
              </Fragment>
          ))}
        </Fragment>     
        <AddQuestionModal addQuestion={addQuestion} formData={formData} />
      </Card.Body>
    );    
};
export default QuizCard;
