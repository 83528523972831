// import node module libraries
import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Tab, Card, Nav, Breadcrumb, Button } from 'react-bootstrap';

// import sub custom components
import CurrencyTable from './CurrencyTable';
import AddNewCurrencyPopUp from './AddNewCurrencyPopUp';

import { useFetchData } from 'hooks/useFetchData';


const AllCurrency = () => {

	const {data: courseData, setData, loading, error} = useFetchData('/currency/');
    const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	return (
		<Fragment>
			<Row>
				<Col lg={12} md={12} sm={12}>
					<div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
						<div className="mb-3 mb-md-0">
							<h1 className="mb-1 h2 fw-bold">Currencies</h1>
							<Breadcrumb>
								<Breadcrumb.Item href="#">Home</Breadcrumb.Item>
								<Breadcrumb.Item href="#">Currency</Breadcrumb.Item>
								<Breadcrumb.Item active>All</Breadcrumb.Item>
							</Breadcrumb>
						</div>
						<div>
							<Button onClick={handleShow} className="btn btn-primary">
								Add New Currency
							</Button>
                            <AddNewCurrencyPopUp handleClose={handleClose} show={show} />
						</div>
					</div>
				</Col>
			</Row>
			<Row>
				<Col lg={12} md={12} sm={12}>
					<Tab.Container defaultActiveKey="all">
						<Card>
							<Card.Header className="border-bottom-0 p-0 bg-white">
								<Nav className="nav-lb-tab">
									<Nav.Item>
										<Nav.Link eventKey="all" className="mb-sm-3 mb-md-0">
											All
										</Nav.Link>
									</Nav.Item>
								</Nav>
							</Card.Header>
							<Card.Body className="p-0">
								<Tab.Content>
									<Tab.Pane eventKey="all" className="pb-4">
										<CurrencyTable 
											courses_data={courseData?.data || []}  
											setData={setData}
										/>
									</Tab.Pane>
								</Tab.Content>
							</Card.Body>
						</Card>
					</Tab.Container>
				</Col>
			</Row>
		</Fragment>
	);
};

export default AllCurrency;
