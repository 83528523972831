// import node module libraries
import React, { Fragment } from 'react';
import { Col, Row, Table } from 'react-bootstrap';

// import custom components
import NewPagination from 'components/elements/advance-table/NewPagination';
import { InstructorData, CourseInitialColumn, PurchaseMenu, CourseStatus } from './common/Components';

const MyCoursesTable = ({ 
    courses_data,
    handlePageChange,
    currentPage,
    lastPage,
    searchValue,
    onSearchChange
}) => {
	
	return (
		<Fragment>
			<div className="overflow-hidden">
				<Row>
					<Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 py-4 px-5 ">
                    <input
                        type="search"
                        className="form-control"
                        placeholder={"Search Course"}
                        value={searchValue}
                        onChange={onSearchChange}
                    />
					</Col>
				</Row>
			</div>

			<div className="table-responsive overflow-y-hidden">
				<Table
					hover
					className="text-nowrap table-centered"
				>
					<thead className="table-light">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Course</th>
                            <th scope="col">Instructor</th>
                            <th scope="col">Purchase Credits</th>
                            <th scope="col"></th>
                        </tr>
					</thead>
					<tbody>
                        {courses_data?.data?.data && courses_data?.data?.data.map((course, index) => (
                            <tr key={course.id}>
                                <td>{index + 1}</td>
                                <td><CourseInitialColumn course={course} /></td>
                                <td><InstructorData row={course} /></td>
                                <td>{course?.creditBalance}</td>
                                <td><PurchaseMenu value={course}  /></td>
                            </tr>
                        ))}
					</tbody>
				</Table>
			</div>

			{/* Pagination @ Footer */}
			<NewPagination
                currentPage={currentPage}
                lastPage={lastPage}
                onPageChange={handlePageChange}
			/>
		</Fragment>
	);
};

export default MyCoursesTable;
