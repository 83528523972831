import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';

const PageTitleDescription = () => {

    const description = useSelector((state) => {
		const settingArray = state.setting?.setting && state.setting?.setting.settings || [];
		const siteLogoItem = settingArray.find((item) => item.key === "site_description");
		return siteLogoItem?.value || ''; // Return an empty string if not found
	})

    const title = useSelector((state) => {
		const settingArray = state.setting?.setting && state.setting?.setting.settings || [];
		const siteLogoItem = settingArray.find((item) => item.key === "site_name");
		return siteLogoItem?.value || ''; // Return an empty string if not found
	})

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
        </Helmet>
    );
};

export default PageTitleDescription;
