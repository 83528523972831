// import node module libraries
import React, { useState, Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import MailSidebarData from 'data/dashboard/mail/MailSidebarData';
import { EmailSendModal } from './EmailSendModal';

const MailSidebar = ({ refresh }) => {

	const [modalShow, setModalShow] = useState(false);
	const [isRefresh, setIsRefresh] = useState(false);
	const location = useLocation();

	return (
		<Fragment>
			<Navbar className="p-4 navbar-mail">
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav as="ul" className="flex-column w-100">
						<Nav.Item as="li" bsPrefix="d-grid" className="mb-4">
							<Link
								to="#home"
								className="btn btn-primary"
								onClick={() => setModalShow(true)}
							>
								Compose New Email
							</Link>
						</Nav.Item>
						{MailSidebarData.map((item, index) => {
							return (
								<Nav.Item as="li" key={index}>
									<Nav.Link
										as={Link}
										to={item.link}
										className={location.pathname.startsWith(item.link) ? 'active' : ''}
									>
										<span className="d-flex align-items-center justify-content-between">
											<span className="d-flex align-items-center">
												{item.icon}
												{item.label}
											</span>
											{item.counter && (
												<span>{(item.label, item.counter)}</span>
											)}
										</span>
									</Nav.Link>
								</Nav.Item>
							);
						})}
					</Nav>
				</Navbar.Collapse>
			</Navbar>
			<EmailSendModal show={modalShow} onHide={() => setModalShow(false)} refresh={setIsRefresh} />
		</Fragment>
	);
};

export default MailSidebar;
