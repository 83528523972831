// import node module libraries
import { Fragment, useEffect, useState } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import { usePostData } from 'hooks/usePostData';
import { useFetchData } from 'hooks/useFetchData';
import { notifyError, notifySuccess } from 'helper/utils';

const AddNewCurrencyPopUp = ({handleClose, show}) => {
    const {data} = useFetchData('/currency/file');
	const {response, loading, error, postData} = usePostData('/currency/create');
	const [name, setName] = useState("")
	const [symbol, setSymbol] = useState("")
    const [flag, setFlag] = useState("")
	const [code, setCode] = useState("")

	const submitForm = () => {
		postData({
			name: name, 
			symbol: symbol, 
			flag: flag, 
			code: code
		});

	};

    const handleInputChange = (value) => {
        if(data.data){
            // Assuming 'data' is the array of currencies and 'fieldName' is 'course'
            const selectedCurrency = data?.data.find((item) => item.name === value);
            setName(value);
            // Update the 'flag' and 'symbol' states based on the selected currency
            if (selectedCurrency) {
              setFlag(selectedCurrency.flag);
              setSymbol(selectedCurrency.symbol);
			  setCode(selectedCurrency.code);
            }
        }
    }

	const closeModal = () => {
		handleClose();
		setSymbol("");
		setName("");
        setFlag("");
		setCode("");
	}

	useEffect(() => {
		if(response !== null){
			notifySuccess(name + " Currency successfully added!")
			closeModal();
		}

		if(error){
			notifyError("Submit failed > " + error.toString());
		}

	}, [response, error])

	return (
		<Fragment>
			<Modal show={show} onHide={closeModal} size="lg">
				<Modal.Header closeButton>
					<Modal.Title>Create New Currency</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{/*  Form  */}
					<Form>
						{/* Title  */}
						<Form.Group className="mb-3">
							<Form.Label>Currency Name</Form.Label>
							<Form.Select 
                                placeholder='Select Currency' 
                                value={name}
                                onChange={(e) => handleInputChange(e.target.value)}
                            >
                                <option value=""></option>
                                {data?.data && data?.data.map((item) => (
                                    <option key={item.name} value={item.name}>
                                        {item.name}
                                    </option>
                                ))}
                            </Form.Select>
							{error && error.name ? 
								(
									<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
										{error.name[0]}
									</Form.Text>
								): 
								(
									<Form.Text className="text-muted">
										Field must contain a unique country name
									</Form.Text>
								)
							}
						</Form.Group>
						
						{/*  Editor  */}
						<Form.Group className="mb-3">
                            <Form.Control
								type="text"
								placeholder="Currency Symbol"
								value={symbol}
                                readOnly
							/>
							{error && error?.symbol ? 
								(
									<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
										{error.symbol[0]}
									</Form.Text>
								): 
								(
									<Form.Text className="text-muted">
										Enter Symbol
									</Form.Text>
								)
							}
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer className="d-flex justify-content-start border-0 pt-0">
					{/*  Action Buttons  */}
					<Button variant="primary" onClick={submitForm} disabled={loading}>
						Add New Currency
					</Button>
					<Button variant="outline-secondary" onClick={closeModal}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</Fragment>
	);
};

export default AddNewCurrencyPopUp;
