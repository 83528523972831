// import node module libraries
import React, { useState } from 'react';
import {
	Card,
} from 'react-bootstrap';

// import custom components
import { useFetchData } from 'hooks/useFetchData';

const MyPayments = () => {

	const { data } = useFetchData("/payment/user");

	return (
		<>
			<Card className="border-0 mb-4">
				<Card.Header className="d-lg-flex justify-content-between align-items-center">
					<div className="mb-3 mb-lg-0">
						<h3 className="mb-0">Current Plan</h3>
						<span>Below your current active plan information.</span>
					</div>
				</Card.Header>
				{data?.data && data?.data.map((item) => (
					<Card.Body key={item.id}>
						<h2 className="fw-bold mb-0">{item.currency}{item.amount}/Yearly</h2>
						<p className="mb-0">
							<span className="text-success">{item.currency}{item.amount}</span> {item.description}{' '}
							<span className="text-success">{item.paidAt}</span>
						</p>
					</Card.Body>
				))}
			</Card>
		</>
	);
};

export default MyPayments;