// import node module libraries
import { Fragment, useEffect, useState } from 'react';
import { Col, Row, Breadcrumb, Card, Form, Button, ProgressBar, Image, Spinner } from 'react-bootstrap';

// import custom components
import { useFetchData } from 'hooks/useFetchData';
import { useFileUpload } from 'hooks/useFileUpload';
import { usePostData } from 'hooks/usePostData';
import { notifyError, notifySuccess } from 'helper/utils';

const GeneralSetting = () => {

	const {postData, loading, error, response } = usePostData('/setting/update');
	const {data: settings} = useFetchData('/settings');
	const [siteName, setSiteName] = useState("");
	const [siteLogo, setSiteLogo] = useState("");
	const [siteFavicon, setSiteFavicon] = useState("");
	const [siteDescription, setSiteDescription] = useState("");
	const [siteContctEmail, setSiteContactEmail] = useState("");
	const [notification, setNotification] = useState("");
	const [trial, setTrial] = useState(false);
	const [trialPeriod, setTrialPeriod] = useState("");
	const [assessmentPassingScore, setAssessmentPassingScore] = useState("");
	const [paystackPaymentMode, setPaystackPaymentMode] = useState(false);
	const [quizTimer, setQuizTimer] = useState(false);

	const { selectedFile : logoSlected, serverResponse: logoResponse, uploadError: logoError, isUploading: isLogoUploading, uploadProgress: logoProgress, handleFileChange: handleLogo, handleUpload: handleLogoUpload } = useFileUpload('/setting/logo');
	const { selectedFile : faviconSlected, serverResponse: faviconResponse, uploadError: faviconError, isUploading: isFaviconUploading, uploadProgress: faviconProgress, handleFileChange: handleFavicon, handleUpload: handleFaviconUpload } = useFileUpload('/setting/favicon');
	const {data} = useFetchData('/currency/file');

	const handleTrial = () => {
		setTrial(!trial);
	}

	const handlePaystackMode = () => {
		setPaystackPaymentMode(!paystackPaymentMode);
	}

	const handleQuizTimer = () => {
		setQuizTimer(!quizTimer);
	}

	const updateSettings = () => {
		postData({
			notification_preference: notification,
			site_contact_email: siteContctEmail,
			site_name: siteName,
			site_description: siteDescription,
			site_logo: siteLogo,
			site_favicon: siteFavicon,
			enable_trial: trial,
			trial_period: trialPeriod,
			passing_score: assessmentPassingScore,
			paystack_payment_mode: paystackPaymentMode,
			enable_quiz_timer: quizTimer,
		});
	}

	useEffect(() => {
		if(settings?.data){
			setSiteName(settings?.data.find((item) => item.key === "site_name").value);
			setSiteLogo(settings?.data.find((item) => item.key === "site_logo").value);
			setSiteDescription(settings?.data.find((item) => item.key === "site_description").value);
			setSiteContactEmail(settings?.data?.find((item) => item.key === "site_contact_email")?.value || '');
			setSiteFavicon(settings?.data.find((item) => item.key === "site_favicon").value);
			setNotification(settings?.data.find((item) => item.key === "notification_preference").value);
			setTrial(settings?.data.find((item) => item.key === "enable_trial").value == "1");
			setTrialPeriod(settings?.data.find((item) => item.key === "trial_period").value);
			setAssessmentPassingScore(settings?.data.find((item) => item.key === "passing_score").value);
			setPaystackPaymentMode(settings?.data.find((item) => item.key === "paystack_payment_mode").value == "1");
			setQuizTimer(settings?.data.find((item) => item.key === "enable_quiz_timer").value == "1");
		}
	}, [settings?.data])

	useEffect(() => {

		if(response?.data !== null && response?.status){
			notifySuccess("Settings Successfully updated!");
		}

		if(error){
			notifyError(error);
		}

		if(logoResponse !== null){
			setSiteLogo(logoResponse?.url)
		}

		if(faviconResponse !== null){
			setSiteFavicon(faviconResponse?.url)
		}

	}, [response?.data, error, logoResponse, faviconResponse])
 
	return (
		<Fragment>
			<Row>
				<Col xl={{ offset: 3, span: 6 }} lg={{ offset: 2, span: 8 }} md={12}>
					<Row>
						<Col lg={12} md={12} sm={12}>
							<div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
								<div className="mb-3 mb-md-0">
									<h1 className="mb-1 h2 font-weight-bold">General Setting</h1>
									<Breadcrumb>
										<Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
										<Breadcrumb.Item href="#">Settings</Breadcrumb.Item>
										<Breadcrumb.Item active>General Setting</Breadcrumb.Item>
									</Breadcrumb>
								</div>
							</div>
						</Col>
					</Row>

					<Row>
						{/* general  settings */}
						<Col xl={12} lg={12} md={12}>
							<Card className="mb-4">
								<Card.Header className="d-flex align-items-center justify-content-between">
									<h4 className="mb-0">General Settings</h4>
								</Card.Header>
								<Card.Body>
									<Form>
										<Row>
											<Col lg={12} md={12} className="mb-3">
												{/* Site Name */}
												<Form.Label>
													Site Name{' '}
													<small className="text-muted">
														(Enter your website name below)
													</small>
												</Form.Label>
												<Form.Control
													type="text"
													id="siteName"
													placeholder="Your Site"
													required
													value={siteName}
													onChange={(e) => setSiteName(e.target.value)}
												/>
												<small>
													The site title might be the name of your company or
													organization, or a brief description of the
													organization, or a combination of the two.
												</small>
											</Col>
											<Col lg={12} md={12} className="mb-3">
												{/* Site Description */}
												<Form.Label>
													Site Description{' '}
													<small className="text-muted">
														(Enter your website description below)
													</small>
												</Form.Label>
												<Form.Control
													as="textarea"
													rows={3}
													id="siteDescription"
													placeholder="Site Description"
													value={siteDescription}
													onChange={(e) => setSiteDescription(e.target.value)}
												/>
												<small>
													The site title might be the name of your company or
													organization, or a brief description of the
													organization, or a combination of the two.
												</small>
											</Col>
											<Col lg={12} md={12} className="mb-3">
												{/* Logo */}
												<Form.Label>
													Logo{' '}
													<small className="text-muted">
														(Upload your website Logo - Accepted logo dimension is 120x40)
													</small>
												</Form.Label>
												<Form.Group className="mb-1 input-group">
													<Form.Control
														type="file"
														className="form-control"
														onChange={handleLogo}
													/>
													
													<div>
														<Button onClick={handleLogoUpload} disabled={!logoSlected} >Upload</Button>
													</div>
												</Form.Group>
												{logoError && (
													<small className="text-danger">
														{logoError.url[0]}
													</small>
												)}
												{siteLogo && (
													<Form.Group className="mb-2">
														<Image
															src={siteLogo}
															className="rounded-circle avatar-xxl mb-3 flex-auto"
															alt=""
														/>
													</Form.Group>
												)}

												{isLogoUploading && <ProgressBar
													striped
													variant="success"
													label={`${logoProgress}%`}
													now={logoProgress}
													className="flex-auto"
													style={{ height: '12px' }}
												/>}
											</Col>
											<Col lg={12} md={12} className="mb-3">
												{/* Favicon */}
												<Form.Label>
													Favicon{' '}
													<small className="text-muted">
														(Upload your website favicon - Standard for most
														desktop browsers. 32×32)
													</small>
												</Form.Label>
												<Form.Group className="mb-1 input-group">
													<Form.Control
														id="inputfavicon"
														type="file"
														className="form-control"
														onChange={handleFavicon}
													/>
													<div>
														<Button onClick={handleFaviconUpload} disabled={!faviconSlected} >Upload Favicon</Button>
													</div>
												</Form.Group>

												{faviconError && (
													<small className="text-danger">
														{faviconError.url[0]}
													</small>
												)}
													{siteFavicon && (
														<Form.Group className="mb-2">
														<Image
															src={siteFavicon}
															className="rounded-circle avatar-xxl mb-3 flex-auto"
															alt=""
														/>
														</Form.Group>
													)}

													{isFaviconUploading && <ProgressBar
														striped
														variant="success"
														label={`${faviconProgress}%`}
														now={faviconProgress}
														className="flex-auto"
														style={{ height: '12px' }}
													/>}
													
											</Col>
										</Row>
										<Row>
											<Col lg={12} md={12}>
												{/* Button */}
												<Button variant="primary"  onClick={updateSettings} >
													{
															loading ?  
																(<>
																	<Spinner
																		as="span"
																		animation="border"
																		size="sm"
																		role="status"
																		aria-hidden="true"
																	/>
																	Loading ...
																</>) :
																<>Update Settings {' '}</>
													}
												</Button>
											</Col>
										</Row>
									</Form>
								</Card.Body>
							</Card>
						</Col>
						{/* end of general settings */}

						{/* Other Settings form */}
						<Col xl={12} lg={12} md={12}>
							<Card className="mb-4">
								<Card.Header className="d-flex align-items-center justify-content-between">
									<h4 className="mb-0">Other Settings</h4>
								</Card.Header>
								<Card.Body>
									<Form>
										<Row>
											<Col lg={12} md={12} className="mb-3">
												<Form.Label>
													Site Contact Email {' '}
												</Form.Label>
												<Form.Control 
													type="text"
													className="form-control"
													value={siteContctEmail}
													onChange={(e) => setSiteContactEmail(e.target.value)}
												/>
											</Col>
											<Col lg={12} md={12} className="mb-3">
												<Form.Label>
													Notification type {' '}
													<small className="text-muted">
														(Enter your website name below)
													</small>
												</Form.Label>
												<Form.Control 
													type="text"
													className="form-control"
													value={notification}
													onChange={(e) => setNotification(e.target.value)}
												/>

											</Col>

											<Col lg={12} md={12} className="mb-3">
												<Form.Label>
													Trial period {' '}
													<small className="text-muted">
														(Enter trial period in days)
													</small>
												</Form.Label>
												<Form.Control 
													type="text"
													className="form-control"
													value={trialPeriod}
													onChange={(e) => setTrialPeriod(e.target.value)}
												/>
											</Col>

											<Col lg={12} md={12} className="mb-3">
												<Form.Label>
													 Enable Trial {' '}
													<small className="text-muted">
														(Uncheck to disable)
													</small>
												</Form.Label>
												<Form.Check
													type="checkbox"
													label=""
													checked={trial}
													className="form-switch"
													onChange={handleTrial}
												/>
											</Col>

											<Col lg={12} md={12} className="mb-3">
												<Form.Label>
													Assessment Passing Score{' '}
													<small className="text-muted">
														(Enter Passing Score for assessment from 1 - 100 if value is not valid, it default to 70)
													</small>
												</Form.Label>
												<Form.Control 
													type="text"
													className="form-control"
													value={assessmentPassingScore}
													onChange={(e) => setAssessmentPassingScore(e.target.value)}
												/>
											</Col>

											<Col lg={12} md={12} className="mb-3">
												<Form.Label>
													 Paystack payment mode{' '}
													<small className="text-muted">
														(Uncheck for testing)
													</small>
												</Form.Label>
												<Form.Check
													type="checkbox"
													label=""
													checked={paystackPaymentMode}
													className="form-switch"
													onChange={handlePaystackMode}
												/>
											</Col>
											<Col lg={12} md={12} className="mb-3">
												<Form.Label>
													 Enable Quiz Timer {' '}
													<small className="text-muted">
														(Uncheck for to disable)
													</small>
												</Form.Label>
												<Form.Check
													type="checkbox"
													label=""
													checked={quizTimer}
													className="form-switch"
													onChange={handleQuizTimer}
													//value={quizTimer.toString()}
												/>
											</Col>
										</Row>
										<Row>
											<Col lg={12} md={12}>
												{/* Button */}
												<Button variant="primary" onClick={updateSettings}>
													{
														loading ?  
															(<>
																<Spinner
																	as="span"
																	animation="border"
																	size="sm"
																	role="status"
																	aria-hidden="true"
																/>
																Loading ...
															</>) :
															<>Update Settings {' '}</>
													}
												</Button>
											</Col>
										</Row>
									</Form>
								</Card.Body>
							</Card>
						</Col>
						{/* end of other settings form form */}

					</Row>
				</Col>
			</Row>
		</Fragment>
	);
};

export default GeneralSetting;

