import React, {useState} from 'react';
import { quizData as dataFromQuiz } from 'helper/utils';
import Options from './Options';
import { AddOptionsModal } from '../steps/modals/AddOptionsModal';
import DropArea from './DropArea';
import AileadButton from 'components/marketing/common/button/AileadButton';
import { usePostData } from 'hooks/usePostData';
import { AddQuestionModal } from '../steps/modals/AddQuestionModal';
import { DeleteConfirmationModal } from 'components/dashboard/business/all-courses/common/Components';

  const Questions = ({ question, questionIndex, formData, setFormData, handleActiveQuestion, handleActiveOption, onOptionDrop }) => {
    const [targetModuleIndex, setTargetQuestionIndex] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const [show, setShow] = useState(false);
	const handleClose = () => {
		setShow(false);
	}
    const {loading, postData} = usePostData("/quiz/remove/question");

    const addOption = (questionIndex, newOption) => {
        dataFromQuiz("addOptions", setFormData, questionIndex, null, newOption, null)
    }

    const removeQuestion = (newQuestion) => {
        console.log("DELETE ACTION ", newQuestion)
        dataFromQuiz("removeQuestion", setFormData, null, null, newQuestion, postData)
    }

    const updateQuestion = (newQuestion) => {
        dataFromQuiz("updateQuestion", setFormData, null, null, newQuestion)
    }

	return (
        <div
            draggable
            onDragStart={() => {
                handleActiveQuestion(questionIndex);
                setIsDragging(true);
            }}
			onDragEnd={() => {
                handleActiveQuestion(null);
                setIsDragging(false);
            }}
            onDragOver={(e) => {
                e.preventDefault();
                setTargetQuestionIndex(questionIndex)
            }}
        >
            {question.text && (
                <div className="bg-light rounded p-2">
                    <div className='d-flex justify-content-between'>
                        <h4 dangerouslySetInnerHTML={{__html: question.text}} />
                        <div className='w-20 d-flex justify-content-around'>
                            <AddQuestionModal addQuestion={updateQuestion} formData={formData} initialQuestion={question} />
                            <AileadButton text="Delete" onClick={() => setShow(true)} variant="outline-danger" className="btn btn-outline-danger btn-sm mt-3" type={"button"} />
                            <DeleteConfirmationModal
                                show={show}
                                handleClose={handleClose}
                                handleConfirm={() => removeQuestion(question)}
                                loading={loading}
                            />
                        </div>
                    </div>
                    <DropArea title={"Option"} onDrop={() => onOptionDrop(targetModuleIndex, 0)}  />
                    {question.options.map((option, optionIndex) => (
                        <div key={optionIndex}>  
                            <Options
                                option={option}
                                optionIndex={optionIndex}
                                formData={formData}
                                setFormData={setFormData}
                                index={questionIndex}
                                handleActiveOption={handleActiveOption}
                            />
                            
                            <DropArea title={"Option"} onDrop={() => onOptionDrop(targetModuleIndex, optionIndex + 1)} /> 
                        </div>
                    ))}
                    <AddOptionsModal formData={formData} questionIndex={questionIndex} addOption={addOption} />
                </div>
            )}
        </div>
    );    
};
export default Questions;
