// import node module libraries
import React, { Fragment, useEffect, useMemo } from 'react';
import {
	useTable,
	useFilters,
	useGlobalFilter,
	usePagination
} from 'react-table';
import { Link } from 'react-router-dom';
import { Col, Row, Button, Image, Dropdown, Table } from 'react-bootstrap';
import { XCircle, MoreVertical } from 'react-feather';

// import custom components
import GlobalFilter from 'components/elements/advance-table/GlobalFilter';
import Pagination from 'components/elements/advance-table/Pagination';
import { useNavigate } from 'react-router-dom';

const PaymentTable = ({ courses_data }) => {
	// The forwardRef is important!!
	// Dropdown needs access to the DOM node in order to position the Menu
	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<Link
			to="#"
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
			className="btn-icon btn btn-ghost btn-sm rounded-circle"
		>
			{children}
		</Link>
	));

	const ActionMenu = ({value}) => {
		const navigate = useNavigate();
		return (
			<Dropdown>
				<Dropdown.Toggle as={CustomToggle}>
					<MoreVertical size="15px" className="text-secondary" />
				</Dropdown.Toggle>
				<Dropdown.Menu align="end">
					<Dropdown.Item eventKey="1" onClick={""}>
						
						{/* <XCircle size="14px" className="me-1" onClick={() => navigate('/course/update/'+value)} /> Edit */}
					</Dropdown.Item>

					<Dropdown.Header>SETTINGS</Dropdown.Header>
					<Dropdown.Item eventKey="1">
						<XCircle size="14px" className="me-1" /> Reject with Feedback
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		);
	};

	const columns = useMemo(

		() => [
            
			{ accessor: 'userId', Header: 'ID', show: false },
			{
				accessor: 'description',
				Header: 'Description',
			},
			{ 
                accessor: 'amount', 
                Header: 'Amount', 
                
            },
            {
				accessor: 'method',
				Header: 'Method',
				
			},
            {
				accessor: 'paidAt',
				Header: 'Date',
				
			},
            {
				accessor: 'reference',
				Header: 'Ref',
				
			},
			{
				accessor: 'shortcutmenu',
				Header: '',
				Cell: ({value, row}) => {
					return <ActionMenu value={row.original.id}  />;
				}
			}
		],
		[]
	);

	const data = useMemo(() => courses_data || [], [courses_data]);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		nextPage,
		previousPage,
		state,
		gotoPage,
		pageCount,
		prepareRow,
		setGlobalFilter
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageSize: 10,
				hiddenColumns: columns.map((column) => {
					if (column.show === false) return column.accessor || column.id;
					else return false;
				})
			}
		},
		useFilters,
		useGlobalFilter,
		usePagination
	);

	const { pageIndex, globalFilter } = state;

	return (
		<Fragment>
			<div className="overflow-hidden">
				<Row>
					<Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 py-4 px-5 ">
						<GlobalFilter
							filter={globalFilter}
							setFilter={setGlobalFilter}
							placeholder="Search Course"
						/>
					</Col>
				</Row>
			</div>

			<div className="table-responsive overflow-y-hidden">
				<Table
					hover
					{...getTableProps()}
					className="text-nowrap table-centered"
				>
					<thead className="table-light">
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<th {...column.getHeaderProps()}>
										{column.render('Header')}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{page.map((row) => {
							prepareRow(row);
							return (
								<tr {...row.getRowProps()}>
									{row.cells.map((cell) => {
										return (
											<td {...cell.getCellProps()}>{cell.render('Cell')}</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</Table>
			</div>

			{/* Pagination @ Footer */}
			<Pagination
				previousPage={previousPage}
				pageCount={pageCount}
				pageIndex={pageIndex}
				gotoPage={gotoPage}
				nextPage={nextPage}
			/>
		</Fragment>
	);
};

export default PaymentTable;
