// import node module libraries
import { Fragment, useEffect, useState } from 'react';
import { Col, Row, Breadcrumb, Card, Form, Button, Spinner } from 'react-bootstrap';
import { usePostData } from 'hooks/usePostData';
import { useFetchData } from 'hooks/useFetchData';
import { notifyError, notifySuccess } from 'helper/utils'
import AileadButton from 'components/marketing/common/button/AileadButton';

const SocialSetting = () => {

	const {postData, loading, error, response } = usePostData('/setting/update');
	const {data: settings} = useFetchData('/settings');
	const [facebook, setFacebook] = useState("");
	const [x, setX] = useState("");
	const [instagram, setInstagram] = useState("");
	const [youtube, setYoutube] = useState("");
	const [linkedIn, setLinkedIn] = useState("");

	useEffect(() => {
		if(settings?.data){
			setFacebook(settings?.data.find((item) => item.key == "facebook_page_id")?.value);
			setX(settings?.data.find((item) => item.key == "x_username")?.value);
			setInstagram(settings?.data.find((item) => item.key == "instagram_username")?.value);
			setLinkedIn(settings?.data.find((item) => item.key == "linkedin_user")?.value);
			setYoutube(settings?.data.find((item) => item.key == "youtube_channel_name")?.value);
		}
	}, [settings?.data])

	useEffect(() => {

		if(response?.data !== null && response?.status){
			notifySuccess("Settings Successfully updated!");
		}

		if(error) {
			notifyError(error);
		}

	}, [response?.data, error])

	const updateSettings = () => {
		postData({
			facebook_page_id: facebook,
			x_username: x,
			linkedin_user: linkedIn,
			instagram_username: instagram,
			youtube_channel_name: instagram
		});
	}

	return (
		<Fragment>
			<Row>
				<Col xl={{ offset: 3, span: 6 }} lg={{ offset: 2, span: 8 }} md={12}>
					<Row>
						<Col lg={12} md={12} sm={12}>
							<div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
								<div className="mb-3 mb-md-0">
									<h1 className="mb-1 h2 font-weight-bold">Social Network</h1>
									<Breadcrumb>
										<Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
										<Breadcrumb.Item href="#">Settings</Breadcrumb.Item>
										<Breadcrumb.Item active>Social Setting</Breadcrumb.Item>
									</Breadcrumb>
								</div>
							</div>
						</Col>
					</Row>

					{/* facebook form */}
					<Row>
						<Col xl={12} lg={12} md={12}>
							<Card className="mb-4">
								<Card.Header className="d-flex align-items-center justify-content-between">
									<h4 className="mb-0">Social Network</h4>
								</Card.Header>
								<Card.Body>
									<Form>
										<Row>
											<Col lg={12} md={12} className="mb-3">
												{/* Facebook */}
												<Form.Label>
													Facebook Page ID{' '}
													<small className="text-muted">
														(ex. https://www.facebook.com/ailedacademy)
													</small>{' '}
												</Form.Label>
												<Form.Control
													type="text"
													id="facebookPage"
													placeholder="ailedacademy"
													value={facebook}
													onChange={(e) => setFacebook(e.target.value)}
												/>
											</Col>

											<Col lg={12} md={12} className="mb-3">
												{/* Twitter */}
												<Form.Label>
													Twitter Username{' '}
													<small className="text-muted">
														(Enter your website description below){' '}
													</small>{' '}
												</Form.Label>
												<Form.Control
													type="text"
													id="twitterPage"
													placeholder="ailedacademy"
													value={x}
													onChange={(e) => setX(e.target.value)}
												/>
											</Col>
											<Col lg={12} md={12} className="mb-3">
												{/* Youtube */}
												<Form.Label>
													YouTube Channel Name{' '}
													<small className="text-muted">
														(ex. https://www.youtube.com/ailedacademy)
													</small>
												</Form.Label>
												<Form.Control
													type="text"
													id="youtubePage"
													placeholder="ailedacademy"
													value={youtube}
													onChange={(e) => setYoutube(e.target.value)}
												/>
											</Col>
											<Col lg={12} md={12} className="mb-3">
												{/* Instagram */}
												<Form.Label>
													Instagram Username{' '}
													<small className="text-muted">
														(ex.https://www.instagram.com/ailedacademy)
													</small>{' '}
												</Form.Label>
												<Form.Control
													type="text"
													placeholder="instagram username"
													value={instagram}
													onChange={(e) => setInstagram(e.target.value)}
												/>
											</Col>
											<Col lg={12} md={12} className="mb-3">
												{/* LinkedIn */}
												<Form.Label>
													LinkedIn User{' '}
													<small className="text-muted">
														(ex.https://www.linkedin.com/ailedacademy)
													</small>{' '}
												</Form.Label>
												<Form.Control
													type="text"
													placeholder="LinkedIn User"
													value={linkedIn}
													onChange={(e) => setLinkedIn(e.target.value)}
												/>
											</Col>
										</Row>
										<Row>
											<Col lg={12} md={12}>
												<AileadButton 
													variant="primary" 
													onClick={updateSettings}
													loading={loading}
													text="Update Settings"
													type="button"
												/>
											</Col>
										</Row>
									</Form>
								</Card.Body>
							</Card>
						</Col>
						{/* end of facebook form */}
					</Row>
				</Col>
			</Row>
		</Fragment>
	);
};

export default SocialSetting;
