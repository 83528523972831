import { Fragment, useState, useEffect } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import AileadButton from 'components/marketing/common/button/AileadButton';
import { usePostData } from 'hooks/usePostData';

export const AddOptionsModal = ({ questionIndex, addOption, initialOption, optionIndex, formData }) => {
	const [show, setShow] = useState(false);
	const {response: validationResponse, loading: loadingValidation, error: validationErrors, postData} = usePostData('/course/validate/quiz-option');
	
    const [option, setOption] = useState({
		content: '',
		isCorrect: false,
	});

	useEffect(() => {
		if (initialOption) {
			setOption(initialOption);
		}
	}, [initialOption]);
  
	const handleClose = () => {
		if(!initialOption) {
			setOption({
				content: '',
				isCorrect: false,
			});
		}
		setShow(false);
	};

	const handleShow = () =>{
		if(!initialOption) {
			setOption({
				content: '',
				isCorrect: false,
			});
		}
		setShow(true)
	};
  
	const handleAddLecture = () => {
		// Find the maximum order value among existing options in the module
		const maxOrder = formData.step2.questions[questionIndex].options.reduce((max, option) => Math.max(max, option.order), 0);
    
		// Generate the order for the new option by incrementing the maximum order
		const newOrder = parseInt(maxOrder + 1);

		// Create a new option object with the generated order
		const newOption = {
			...option,
			order: newOrder
		};

		const addNewOption = initialOption ? option : newOption;

	  // Perform any necessary actions before closing the modal
	  addOption(questionIndex, addNewOption, optionIndex);
	  handleClose();
	};

	const addLectureStep = () => {
		postData(option);
	}

	useEffect(() => {
	  if(validationResponse !== null && validationResponse?.status){
		handleAddLecture();
	  }
	}, [validationResponse])
  
	return (
	  <Fragment>
		{
			initialOption ? 
				<Button 
					className="me-1 text-inherit" 
					title="Edit"
					onClick={handleShow}
				>
					<i className="fe fe-edit fs-6"></i>
					
				</Button> :

				<Button
					variant="outline-primary"
					className="btn btn-outline-primary btn-sm mt-3"
					onClick={handleShow}
				>
					Add Option +
				</Button>
		}
		<Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg">
		  <Modal.Header closeButton>
			<Modal.Title>Add New Option</Modal.Title>
		  </Modal.Header>
		  <Modal.Body className="pb-0">
			<Form.Group className="mb-3" controlId="formaddnewlecture">
			<Form.Label>Type Option <Form.Text className='text-danger'>*</Form.Text></Form.Label>
			  <Form.Control
				type="text"
				placeholder="Add Answer"
				className='mb-4'
				value={option.content}
				onChange={(e) => setOption({ ...option, content: e.target.value })}
			  />
			  {validationErrors && validationErrors.content && (
				<Form.Text className="col-sm-6 text-danger mb-2 mt-2">
					{validationErrors.content[0]}
				</Form.Text>
			  )}

			  <div>
				<Form.Label>
					Is Answer Correct?  {' '}
					<small className="text-muted">
						(Check to approve as correct answer)
					</small>
				</Form.Label>
				<Form.Check
					type="checkbox"
					label=""
					checked={option.isCorrect}
					className="form-switch"
					onChange={() => setOption({ ...option, isCorrect: !option.isCorrect })}
				/>
				
			  </div>

				{validationErrors && validationErrors.isCorrect && (
					<Form.Text className="col-sm-6 text-danger mb-2 mt-2">
						{validationErrors.isCorrect[0]}
					</Form.Text>
				)}
			 
			</Form.Group>
		  </Modal.Body>
		  <Modal.Footer className="pt-0 border-0 d-inline">
		  	<AileadButton type="button" variant="primary" onClick={addLectureStep} loading={loadingValidation} text={initialOption ? "Update Option" : "Add New Option"} />
			<AileadButton type="button" variant="outline-secondary" onClick={handleClose} text="Close" />
		  </Modal.Footer>
		</Modal>
	  </Fragment>
	);
  };