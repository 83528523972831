// import node module libraries
import { Form } from 'react-bootstrap';

import { useFetchData } from 'hooks/useFetchData';


const LessonSelect = ({ lesson, handleModule, moduleId, validationErrors }) => {

	const { data, loading } = useFetchData(moduleId ? '/course/lesson/'+moduleId: null);

	return (
            <>
			{/* Module */}
			<Form.Group className="mb-3">
				<Form.Label> Select Module Lesson (optional)</Form.Label>
					<Form.Select 
						placeholder='Select Lesson' 
						value={lesson}
						onChange={handleModule}
                        disabled={loading}
					>
						<option value="">No Quiz Lesson</option>
							{data?.data && data?.data.map((item) => (
								<option key={item.id} value={item.id}>
								  {item.title}
								</option>
						))}
					</Form.Select>
					{validationErrors && validationErrors.lesson ? (
							<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
								{validationErrors.lesson[0]}
							</Form.Text>
				    ): (
					<>
						Select the Module Lesson that is related to this quiz. Select 'No Quiz Lesson' to leave it blank
					</>
				    )}
			</Form.Group>
        </>	
			
	);
};
export default LessonSelect;
