// Import node module libraries
import { useEffect } from 'react';
import { Table, Image, Button } from 'react-bootstrap';

// Import custom components
import NewPagination from 'components/elements/advance-table/NewPagination';
import { usePostData } from 'hooks/usePostData';
import { notifySuccess } from 'helper/utils';

const AssessmentQuizTable = ({ 
    courses_data,
    handlePageChange,
    currentPage,
    lastPage,
    refreshData,
    loading
}) => {

    return (
        <>
            <div className="table-responsive">
                <Table hover className="table-centered">
                    <thead className="table-light">
                        <tr>
                            <th>#</th>
                            <th>Quiz</th>
                            <th>Type</th>
                            <th>Graded</th>
                            <th>Module</th>
                            <th>Lesson</th>
                            <th>Questions</th>
                            <th>Attempts</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {courses_data?.data?.length > 0 ? (
                            courses_data?.data?.map((quiz, index) => (
                                <tr key={quiz.id}>
                                    <td>{index + 1}</td>
                                    <td>
                                        <div className="d-lg-flex align-items-center">
                                            <div>
                                                <Image
                                                    src={quiz?.course?.image || ""}
                                                    alt=""
                                                    className="img-4by3-lg rounded"
                                                />
                                            </div>
                                            <div className="ms-lg-3 mt-2 mt-lg-0">
                                                <h4 className="mb-1 text-primary-hover">{quiz?.title}</h4>
                                                <span className="text-inherit">
                                                    Course: {quiz?.course?.title || ""}
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{quiz?.type}</td>
                                    <td>{quiz?.isGradable ? "YES" : "NO"}</td>
                                    <td>{quiz?.module?.title || "N/A"}</td>
                                    <td>{quiz?.lesson?.title || "N/A"}</td>
                                    <td>{quiz?.questions?.length || 0}</td>
                                    <td>{quiz?.maxAttempt || "Unlimited"}</td>
                                    <td>
                                        <Button variant="primary" size="sm">Edit</Button>
                                        <Button variant="danger" size="sm" className="ms-2">Delete</Button>
                                    </td>
                                </tr>
                            ))
                        ) : loading ? (
                            <tr>
                                <td colSpan="9" className="text-center">Fetching Data Please wait...</td>
                            </tr>
                        ) : (
                            <tr>
                                <td colSpan="9" className="text-center">No Quiz available.</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
            
            <NewPagination
              currentPage={currentPage}
              lastPage={lastPage}
              onPageChange={handlePageChange}
            />
        </>
    );
};

export default AssessmentQuizTable;
