// import node module libraries
import { Fragment, useState, useEffect } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import RichTextEditor from 'components/elements/new-editor/RichTextEditor';
import { usePostData } from 'hooks/usePostData';

// import data files

export const AddQuestionModal = ({ addQuestion, initialQuestion, formData }) => {
	const [show, setShow] = useState(false);
	const [question, setQuestion] = useState({
	  text: '',
	  type: '',
	  timer: '',
	  order: ''
	});

	const {response: validationResponse, loading: loadingValidation, error: validationErrors, postData} = usePostData('/course/validate/quiz-question');
  
	const handleClose = () => {

		if(!initialQuestion) {
		  // Reset module state when closing
		  setQuestion({
			text: '',
			type: '',
			timer: '',
			order: ''
		  });
		}
  
		setShow(false);
	  };
  
	  useEffect(() => {
		  if (initialQuestion) {
			setQuestion(initialQuestion);
		  }
	  }, [initialQuestion]);
	
	  const handleShow = () => {
		  if(!initialQuestion) {
			setQuestion({
				text: '',
				type: '',
				timer: '',
				order: ''
			});
		  }
		  setShow(true)
	  };

  
	const handleAddQuestion = () => {
	  // Perform any necessary actions before closing the modal

		// Get the existing questions
		const existingQuestions = formData.step2.questions;

		// Check if any module in the array is empty
		const hasEmptyQuestion = existingQuestions.some(question => (
			question.text === '' && question.type === '' && question.timer === '' && question.order === ''
		));

		// Find the maximum order value among existing lessons in the module
		const newOrder = hasEmptyQuestion ? 1: existingQuestions.length + 1;
    
		// Create a new lesson object with the generated order
		const newQuestion = {
			...question,
			order: newOrder
		};

		const addNewQuestion = initialQuestion ? question : newQuestion;
		console.log("ORDER > ", newQuestion)
	  addQuestion(addNewQuestion);
	  handleClose();
	};

	const nextStep = () => {
		postData(question);
	}

	useEffect(() => {
		if(validationResponse?.status === 'Success'){
			console.log("I can see you!")
			handleAddQuestion();
		}
	}, [validationResponse])
  
	return (
	  <Fragment>
		{initialQuestion ? 
			(
			 <Button
				variant="outline-primary"
				className="btn btn-outline-primary btn-sm mt-3"
				onClick={handleShow}
				>
				Edit Question
			 </Button>
			) : 
			
			(
			 <Button
				variant="outline-primary"
				className="btn btn-outline-primary btn-sm mt-3"
				onClick={handleShow}
			 >
			  Add Question+
			</Button>
			)
		}
		<Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg">
		  <Modal.Header closeButton>
			<Modal.Title>Add New Question</Modal.Title>
		  </Modal.Header>
		  <Modal.Body className="pb-0">
			<Form.Group className="mb-3" controlId="formaddnewquestion">
			  <Form.Label>Type Question <Form.Text className='text-danger'>*</Form.Text></Form.Label>
			  <RichTextEditor 
			  	editorValue={question.text}
			  	onChange={(event, editor) => {
					const newData = editor.getData();
					setQuestion({ ...question, text: newData })
				}}
			  />

			  {validationErrors && validationErrors.text && (
				<Form.Text className="col-sm-6 text-danger mb-2 mt-2">
					{validationErrors.text[0]}
				</Form.Text>   
			  )}

		      <Form.Label>Select Question type <Form.Text className='text-danger'>*</Form.Text></Form.Label>
			  <Form.Select
				className='mb-4 mt-1'
				value={question.type}
				onChange={(e) => setQuestion({ ...question, type: e.target.value })}
			   >
				<option value=""></option>
				<option value="single-choice">Single Choice</option>
				<option value="multi-choice">Multiple Choice</option>
			  </Form.Select>
			  {validationErrors && validationErrors.type && (
				<Form.Text className="col-sm-6 text-danger mb-2 mt-1">
					{validationErrors.type[0]}
				</Form.Text>   
			  )}
			  
			  <Form.Label>Enter count down Timer </Form.Label>
			  <Form.Control
				type="text"
				className='mb-4'
				placeholder="Add quiz count down timer"
				value={question.timer}
				onChange={(e) => setQuestion({ ...question, timer: e.target.value })}
			  />
			  {validationErrors && validationErrors.timer && (
				<Form.Text className="col-sm-6 text-danger mb-1 mt-1">
					{validationErrors.timer[0]}
				</Form.Text>   
			  )}

			</Form.Group>
		  </Modal.Body>
		  <Modal.Footer className="pt-0 border-0 d-inline">
			<Button variant="primary" onClick={nextStep}>
			  Add New Question
			</Button>
			<Button variant="outline-secondary" onClick={handleClose}>
			  Close
			</Button>
		  </Modal.Footer>
		</Modal>
	  </Fragment>
	);
  };