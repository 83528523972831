// import node module libraries
import React, { useState } from 'react';
import { Card, Badge, Form, Row, Col } from 'react-bootstrap';
import { useFetchData } from 'hooks/useFetchData';

const MySubscriptions = () => {
	const [AutoRenewalState, setAutoRenewalState] = useState(true);
	const [TrialAutoRenewalState, setTrialAutoRenewalState] = useState(true);
	const { data, loading: subLoading} = useFetchData("/user/subscription");
	const {data: trialData, error: trialError, loading: trialLoading} = useFetchData("/user/trial");

	return (
		<Card className="border-20">
				<Card.Header className="d-lg-flex justify-content-between align-items-center">
					<div className="mb-3 mb-lg-0">
						<h3 className="mb-0">My Subscriptions</h3>
						<p className="mb-0">
							Here is list of package/product that you have subscribed.
						</p>
					</div>
				</Card.Header>
				
				<Card.Body>
				{(data?.data && data?.data?.length > 0) || (trialData && trialData?.data && Object.keys(trialData?.data).length > 0) ? (
        			<>
					{data?.data && data?.data?.map((item, index) => (<div className="border-bottom pt-0 pb-5">
						<Row className="mb-4">
							<Col lg={6} md={8} sm={7} className="mb-2 mb-lg-0">
								<span className="d-block">
									<span className="h4">{item.type}</span>{' '}
									<Badge bg={item.isActive ? "success": "danger"} className="ms-2">
										{item.isActive ? "Active": "Expired"}
									</Badge>
								</span>
								{/* <p className="mb-0 fs-6">Subscription ID: #100010002</p> */}
							</Col>
							<Col lg={3} md={4} sm={5} className="mb-2 mb-lg-0">
								{/* Custom Switch */}
								<span>Auto Renewal</span>
								<Form>
									<Form.Check
										name="radios"
										type="checkbox"
										className="form-switch"
										checked={AutoRenewalState}
										onChange={() =>
											setAutoRenewalState(
												(AutoRenewalState) => !AutoRenewalState
											)
										}
									/>
								</Form>
							</Col>
						</Row>
						{/* Pricing data */}
						<Row>
							<Col lg={3} md={3} sm={6} className="mb-2 mb-lg-0">
								<span className="fs-6">Started On</span>
								<h6 className="mb-0">{item.startDate}</h6>
							</Col>
							<Col lg={3} md={3} sm={6} className="mb-2 mb-lg-0">
								<span className="fs-6">Price</span>
								<h6 className="mb-0">{item.type}</h6>
							</Col>
							<Col lg={3} md={3} sm={6} className="mb-2 mb-lg-0">
								<span className="fs-6">Access</span>
								<h6 className="mb-0">Access All Courses</h6>
							</Col>
							<Col lg={3} md={3} sm={6} className="mb-2 mb-lg-0">
								<span className="fs-6">Billing Date</span>
								<h6 className="mb-0">Next Billing on {item.endDate}</h6>
							</Col>
						</Row>
					</div> ))}
					{ trialData && trialData.data && 
					(<div className="pt-5">
						<Row className="mb-4">
							<Col className="mb-2 mb-lg-0">
								<span className="d-block">
									<span className="h4">Free Plan</span>{' '}
									<Badge bg={trialData.data && trialData?.data.isActive ? "success" : "danger"} className="ms-2">
										{trialData.data && trialData?.data.isActive ? "Active" : "Expired"}
									</Badge>
								</span>
								{/* <p className="mb-0 fs-6">Subscription ID: #100010001</p> */}
							</Col>
							<Col lg={3} md={4} sm={5} className="mb-2 mb-lg-0">
								{/* Custom Switch */}
								<span>Auto Renewal</span>
								<Form>
									<Form.Check
										name="radios"
										type="checkbox"
										className=" form-switch"
										checked={TrialAutoRenewalState}
										onChange={() =>
											setTrialAutoRenewalState(
											  (TrialAutoRenewalState) => !TrialAutoRenewalState
											)
										}
									/>
								</Form>
							</Col>
						</Row>
						<Row>
							<Col lg={3} md={3} sm={6} className="mb-2 mb-lg-0">
								<span className="fs-6">Started On</span>
								<h6 className="mb-0">{trialData.data && trialData?.data.startDate}</h6>
							</Col>
							<Col lg={3} md={3} sm={6} className="mb-2 mb-lg-0">
								<span className="fs-6">Price</span>
								<h6 className="mb-0">Free - Trial </h6>
							</Col>
							<Col lg={3} md={3} sm={6} className="mb-2 mb-lg-0">
								<span className="fs-6">Access</span>
								<h6 className="mb-0">Access All Courses</h6>
							</Col>
							<Col lg={3} md={3} sm={6} className="mb-2 mb-lg-0">
								<span className="fs-6">Billing Date</span>
								<h6 className="mb-0">Next Billing on {trialData.data && trialData?.data.endDate}</h6>
							</Col>
						</Row>
					</div>)}
					</>
				) : subLoading || trialLoading ? (<div className="text-center">Fetching Data, Please Wait ......</div>) : (<div className="text-center">No record found</div>)}
				</Card.Body>
		</Card>
	);
};

export default MySubscriptions;
