import { useEffect, useState } from 'react';

const QuizTimer = ({ timer, onTimeEnd }) => {
    const [time, setTime] = useState({
        hours: Math.floor(timer / 60),
        minutes: timer % 60,
        seconds: 0,
    });

    useEffect(() => {
        const timerId = setInterval(() => {
            setTime(prevTime => {
                const totalSeconds =
                    prevTime.hours * 3600 +
                    prevTime.minutes * 60 +
                    prevTime.seconds;

                if (totalSeconds <= 1) {
                    clearInterval(timerId);
                    onTimeEnd();
                    return { hours: 0, minutes: 0, seconds: 0 };
                }

                const newTotalSeconds = totalSeconds - 1;
                return {
                    hours: Math.floor(newTotalSeconds / 3600),
                    minutes: Math.floor((newTotalSeconds % 3600) / 60),
                    seconds: newTotalSeconds % 60,
                };
            });
        }, 1000);

        return () => clearInterval(timerId);
    }, []);

    return (
        <div>
            <span className="text-danger">
                <i className="fe fe-clock me-1 align-middle"></i>
                {`${time.hours.toString().padStart(2, '0')}:
                  ${time.minutes.toString().padStart(2, '0')}:
                  ${time.seconds.toString().padStart(2, '0')}`}
            </span>
        </div>
    );
};

export default QuizTimer;
