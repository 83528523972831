// import node module libraries
import { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Card, Form } from 'react-bootstrap';
// import media files
import { usePostData } from 'hooks/usePostData';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { notifyError, notifySuccess } from 'helper/utils';
//import Echo from 'helper/echoService';
import axios from 'axios';
import { updatedProfile } from 'redux/actions/authActions';
import AileadButton from 'components/marketing/common/button/AileadButton';

const VerifyAccount = () => {

	const [code, setCode] = useState('');
	const { postData, loading, error, response } = usePostData('/auth/email-verification');
	const [resendCode, setResendCode] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const BASE_URL = process.env.REACT_APP_API_BASE_URL;
	// Use the useSelector hook to access user data directly from the Redux store
	const {user} = useSelector(state => state.auth);
	const authToken = user ? user.token : null;
	
	const handleSubmit = (e) => {
		e.preventDefault(); // Prevent the default form submission
		postData({ enteredVerificationCode: code });
	};

	const reSendEmailVerificationCode = async () => {
		setResendCode(true);
		if (authToken !== null) {
			axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
			try {
				await axios.get(BASE_URL+"/auth/resend-code");
				setResendCode(false);
			} catch(e) {
				notifyError(e.response.data.message);
			}
		} else {
			setResendCode(false);
			notifyError("You are not logged in!");
		}
	}

	useEffect(() => {
		const updateProfile = async () => {
			if(response && response.status === "Success") {

				if (authToken !== null) {
					axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
				}
				const profileUpdate = await axios.get(BASE_URL+"/user/profile");
				dispatch(updatedProfile({isVerified: profileUpdate.data.data.isVerified}));
				notifySuccess("Verification Successful!");
				navigate("/")
			}

			if(error) {
				notifyError(error);
			}
		}

		updateProfile();

	}, [error, response])
	
	return (
		<Fragment>
			<Row className="align-items-center justify-content-center g-0 min-vh-100">
				<Col lg={5} md={5} className="py-8 py-xl-0">
					<Card>
						<Card.Body className="p-6">
							{/* Form */}
							<Form>
								<Row>
									<Col lg={12} md={12} className="mb-3">
										{/* Username or email */}
										<Form.Label>Email Verification sent to {user?.email}</Form.Label>
										<Form.Control
											type="text"
											placeholder="Enter the six digit Verification Code"
											required
											onChange={(e) => setCode(e.target.value)}
											value={code}
										/>
									</Col>
									
									<Col lg={12} md={12} className="mb-0 d-grid gap-2">
										{/* Button */}
										<AileadButton text="Verify Account" type="button" variant="primary" onClick={handleSubmit} loading={loading} />
									</Col>

                                    <div className="mt-4">
                                        <span>
                                            Didn’t get verification code?{' '}
											<AileadButton text="Resend" type="button"  variant="primary" onClick={reSendEmailVerificationCode} loading={resendCode} className="ms-1" />
                                        </span>
                                    </div>
								</Row>
							</Form>
							<hr className="my-4" />
							
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Fragment>
	);
};

export default VerifyAccount;
