// Import node module libraries
import React, { useState, useEffect } from 'react';
import { Table, Image, Button } from 'react-bootstrap';
import NewPagination from 'components/elements/advance-table/NewPagination';
import { usePostData } from 'hooks/usePostData';
import { notifySuccess } from 'helper/utils';
import { DeleteConfirmationModal } from '../business/all-courses/common/Components';

const ReviewTable = ({ 
    review_data,
    handlePageChange,
    currentPage,
    lastPage,
    refreshData,
    loading
}) => {
    // State to manage which comments are expanded
    const [expandedComments, setExpandedComments] = useState({});
    const [show, setShow] = useState(false);
    const handleClose = () => {
		setShow(false);
	}

    // Function to toggle comment expansion
    const toggleExpandComment = (id) => {
        setExpandedComments(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    // Function to truncate the comment
    const truncateComment = (comment, id) => {
        if (expandedComments[id]) {
            return (
                <>
                    {comment} 
                    <span 
                        onClick={() => toggleExpandComment(id)} 
                        className="text-primary cursor-pointer"
                    > Read less</span>
                </>
            );
        }
        if (comment.length > 100) {
            return (
                <>
                    {comment.slice(0, 100)}...
                    <span 
                        onClick={() => toggleExpandComment(id)} 
                        className="text-primary cursor-pointer"
                    > Read more</span>
                </>
            );
        }
        return comment;
    };

    const { response, loading: isLoading, postData } = usePostData('/review/remove');

    useEffect(() => {
		if(response?.status === "Success") {
			refreshData();
			notifySuccess(`Course has been move to trash Successfully!`);
		}
	}, [response])

    const handleDelete = (id) => {
        postData({id})
    }

    return (
        <>
            <div className="table-responsive">
                <Table hover className="table-centered">
                    <thead className="table-light">
                        <tr>
                            <th>#</th>
                            <th>Comment</th>
                            <th>Rating</th>
                            <th>Reviewed By</th>
                            <th>Course</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {review_data?.reviews?.length > 0 ? (
                            review_data?.reviews?.map((review, index) => (
                                <>
                                <tr key={review.id}>
                                    <td>{index + 1}</td>
                                    <td>{truncateComment(review.comment, review.id)}</td>
                                    <td>{review.rating}</td>
                                    <td>
                                        <div>
                                            <p>{review.reviewedBy.fullName}</p>
                                            <p>{review.reviewedBy.email}</p>
                                            <p>{review.reviewedBy.mobile}</p>
                                            <p>{review.reviewedBy.role}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-lg-flex align-items-center">
                                            <div>
                                                <Image
                                                    src={review.course.image || ""}
                                                    alt=""
                                                    className="img-4by3-lg rounded"
                                                />
                                            </div>
                                            <div className="ms-lg-3 mt-2 mt-lg-0">
                                                <h4 className="mb-1 text-primary-hover">{review.course.title}</h4>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <Button variant="danger" onClick={() => setShow(true)} size="sm" className="ms-2">Delete</Button>
                                    </td>
                                    
                                </tr>
                                    <DeleteConfirmationModal
                                        show={show}
                                        handleClose={handleClose}
                                        handleConfirm={() => handleDelete(review.id)}
                                        loading={isLoading}
                                        item={""}
                                    />
                                </>
                                
                            ))
                        ) : loading ? (
                            <tr>
                                <td colSpan="6" className="text-center">Fetching Data Please wait...</td>
                            </tr>
                        ) : (
                            <tr>
                                <td colSpan="6" className="text-center">No Reviews available.</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
            
            <NewPagination
              currentPage={currentPage}
              lastPage={lastPage}
              onPageChange={handlePageChange}
            />
        </>
    );
};

export default ReviewTable;
