// import node module libraries
import React, { Fragment } from 'react';
import {
	ListGroup,
} from 'react-bootstrap';

import MailRow from './MailRow';
import { useFetchData } from 'hooks/useFetchData';


const MailSent = () => {

	const {data: sentData, refreshData: refreshSent, loading: loadSent, error: sentError} = useFetchData('/mail/sent');

	return (
		<Fragment>
			<div className="">
				<ListGroup
					variant="flush"
					className="list-group list-group-mail"
				>
					{sentData?.data?.data ? (
						sentData?.data?.data.map((mail, index) => <ListGroup.Item
							className={`list-group-item-action px-4 list-mail ${
								mail.isRead ? 'bg-light' : ''
							}`}
							key={mail.id}
						>
								<MailRow
									mail={mail}
								/>
						</ListGroup.Item>)
					) : (
						<ListGroup.Item className="list-group-item-action px-4 list-mail bg-light">
							No Sent mails found.
						</ListGroup.Item>
					)}
										
				</ListGroup>
			</div>

			{/* You need to add pagination component here */}
	    </Fragment>
	);
};

export default MailSent;
