
export const APP_SETTINGS="Application Settings";

export const settings = (setting) => {
    
  // Save setting data to localStorage
  localStorage.setItem('global_setting', JSON.stringify(setting));
  
  return {
    type: APP_SETTINGS,
    payload: setting,
  };
};
