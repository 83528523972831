// import node module libraries
import { Card, Form, Button, ProgressBar, Image } from 'react-bootstrap';
import { useFileUpload } from 'hooks/useFileUpload';
import { useEffect } from 'react';
import AileadButton from 'components/marketing/common/button/AileadButton';

const UpdateCoursesMedia = (props) => {
	const { next, previous, handleInputChange, formData } = props;
	const { serverResponse, uploadError, uploadProgress, isUploading, handleFileChange, handleUpload } = useFileUpload('/module/upload/image');

	useEffect(() => {
		if(serverResponse?.status === 'Success') {
			handleInputChange('url', serverResponse?.data?.url)
		}
	},[serverResponse]);

	return (
		<Form>
			{/* Card */}
			<Card className="mb-3  border-0">
			  <Card.Header className="border-bottom px-4 py-3">
				<h4 className="mb-0">Courses Media</h4>
			  </Card.Header>
			  <Card.Body>
				{/* Course cover image */}
				<Form.Label>Course cover image</Form.Label>
				<Form.Group className="mb-1 input-group">
				  <Form.Control
					id="inputfavicon"
					type="file"
					className="form-control"
					name="url"
					onChange={handleFileChange}
				  />
				  <Form.Label
					htmlFor="inputfavicon"
					className="input-group-text mb-0"
				  >
					Upload
				  </Form.Label>

				  <Form.Text className="text-muted">
					Upload your course image here. It must meet our course image
					quality standards to be accepted. Important guidelines: 750x440
					pixels; .jpg, .jpeg,. gif, or .png. no text on the image.
				  </Form.Text>
				</Form.Group>
				{uploadError?.url && (
				  <Form.Group className="mb-2">
					<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
						{uploadError.url[0]}
					</Form.Text>
				  </Form.Group>
				)}

				{formData.step3.url && (
				  <Form.Group className="mb-2">
					<Image
					  src={formData.step3.url}
					  className="rounded-circle avatar-xxl mb-3 flex-auto"
					  alt=""
					/>
				  </Form.Group>
				)}

				<Form.Group controlId="formHorizontalButton" className="mb-3">
				  <AileadButton variant="primary" className="col-sm-3 mb-5" type="button" onClick={handleUpload} text="Upload Media" />
				</Form.Group>

				{isUploading && <ProgressBar
				  striped
				  variant="success"
				  label={`${uploadProgress}%`}
				  now={uploadProgress}
				  className="flex-auto"
				  style={{ height: '12px' }}
				/>}
			  </Card.Body>
			</Card>

			{/* Button */}
			<div className="d-flex justify-content-between">
				<AileadButton text="Previous" variant="secondary" onClick={previous} type="button" />
				<AileadButton text="Next" variant="primary" onClick={next} type="button" />
			</div>
		</Form>
	);
};
export default UpdateCoursesMedia;
