import { Fragment, useEffect, useState } from 'react';
import { ListGroup, Form } from 'react-bootstrap';

const Question = ({ item, onAnswerChange }) => {
    const isMultiChoice = item.type === 'multi-choice';
    const [selectedValue, setSelectedValue] = useState(isMultiChoice ? [] : null);

    // Ensure selectedValue is correctly initialized on item change
    useEffect(() => {
        setSelectedValue(isMultiChoice ? [] : null);
    }, [item.type]);

    // Handle changes to the selected options
    const handleOptionChange = (optionId) => {
		console.log("Updated Value: NO DATA ", isMultiChoice);
        if (isMultiChoice) {
			
            setSelectedValue((prev) => {
                const newValue = prev.includes(optionId)
                    ? prev.filter((id) => id !== optionId)
                    : [...prev, optionId];
				console.log("Updated Value:", newValue);
                return newValue;
            });
        } else {
            setSelectedValue(optionId);
        }
    };

    // Notify the parent component whenever the selected value changes
    useEffect(() => {
        onAnswerChange(selectedValue);
    }, [selectedValue, onAnswerChange]);

    return (
        <Fragment>
            <h3 className="mb-3">{item.text}</h3>
            {isMultiChoice ? (
                <Form>
                    {item.options.map((option) => (
                        <Form.Check
                            key={option.id}
                            type="checkbox"
                            id={`checkbox-${option.id}`}
                            bsPrefix="d-grid"
                            className="mb-2"
                        >
                            <Form.Check.Input
                                type="checkbox"
                                className="btn-check"
								onChange={() => handleOptionChange(option.id)}
                            	checked={Array.isArray(selectedValue) && selectedValue.includes(option.id)}
                            />
                            <Form.Check.Label className="btn btn-outline-light-primary text-dark-primary text-start">
                                {option.content}
                            </Form.Check.Label>
                        </Form.Check>
                    ))}
                </Form>
            ) : (
                <ListGroup>
                    {item.options.map((option) => (
                        <ListGroup.Item
                            key={option.id}
                            className={`list-group-item-action ${
                                selectedValue === option.id ? 'bg-light' : ''
                            }`}
                            aria-current="true"
                            onClick={() => handleOptionChange(option.id)}
                        >
                            <Form.Check
                                type="radio"
                                id={`radio-${option.id}`}
                                name={`answer-${item.id}`}
                                label={option.content}
                                value={option.id}
                                checked={selectedValue === option.id}
                                onChange={() => handleOptionChange(option.id)}
                            />
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            )}
        </Fragment>
    );
};

export default Question;