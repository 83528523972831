
export const SENT_MAIL= "Sent Email";

export const sentMails = (reloadData) => {
  // reload data
  reloadData();

  return {
    type: SENT_MAIL,
  };
};
