import React, {useState, useEffect} from 'react';
import { Card, Badge, Table } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import axios from 'axios'
import { useSelector } from 'react-redux';
import useUserTimezone from 'hooks/useUserTimezone';


const QuizAttempt = () => {
    // const previousAttempts = [
    //     {
    //         id: 1,
    //         date: "2024-03-28",
    //         score: 80,
    //         result: "Passed"
    //     },
    //     {
    //         id: 2,
    //         date: "2024-03-25",
    //         score: 60,
    //         result: "Failed"
    //     },
    //     {
    //         id: 3,
    //         date: "2024-03-22",
    //         score: 90,
    //         result: "Passed"
    //     },
    //     // Add more attempts as needed
    // ];

	const BASE_URL = process.env.REACT_APP_API_BASE_URL;
	const location = useLocation();
	const assessmentId = location.state;
	const {user} = useSelector(state => state.auth);
    const {userTimezone} = useUserTimezone();

	//const {data: previousAttempts, loading, error} = useFetchData("assessment/attempts/"+assessmentId);
		// Define loading and error states
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	
	// Define quizData state
	const [quizData, setQuizData] = useState(null);
	
	// Fetch data when assessmentId changes
	useEffect(() => {
	
		const fetchData = async () => {
			if (assessmentId) {
				// Fetch quiz data
				setLoading(true);
				try{

					if (user && user.token !== null) {
                        axios.defaults.headers.common['Timezone'] = userTimezone;
						axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
					}
					
					const response = await axios.get(BASE_URL + "/assessment/attempts/" + assessmentId);
					setQuizData(response.data.data);
					setLoading(false);
				} catch(e) {
					setError(e.message)
				}
				finally{
					setLoading(false);
				}
			}
		}
	
		fetchData();
	}, [assessmentId]);

    const ResultBadge = ({ result }) => {
        let bgValue = '';
        switch (result) {
            case 'Failed':
                bgValue = 'danger';
                break;
            case 'Pending':
                bgValue = 'warning';
                break;
            case 'Passed':
                bgValue = 'success';
                break;
            default:
                break;
        }
        return <Badge bg={bgValue} className="me-1">{result}</Badge>;
    };

    return (
        <Card className="mt-4">
            <Card.Header className="card-header">
                <div className="mb-3 mb-lg-0">
                    <h3 className="mb-1">My Quiz Attempt</h3>
                    <p className="mb-0">You have exceeded the number of quiz attempts.</p>
                </div>
            </Card.Header>
            <Card.Body>
                <p>You can review your previous attempts below:</p>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Score</th>
                            <th>Result</th>
                        </tr>
                    </thead>
                    <tbody>
                        {quizData && quizData.map((attempt, index) => (
                            <tr key={attempt.id}>
                                <td>{index + 1}</td>
                                <td>{attempt.date}</td>
                                <td>{attempt.score}</td>
                                <td><ResultBadge result={attempt.result} /></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );
};

export default QuizAttempt;
