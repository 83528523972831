// import node module libraries
import React, { useState, Fragment, useEffect } from 'react';

// import custom components
import GKStepper from 'components/elements/stepper/GKStepper';

// import sub components ( Steps )
import BasicInformation from './steps/BasicInformation';
import CoursesMedia from './steps/CoursesMedia';
import Curriculum from './steps/Curriculum';
import CoursePrice from './steps/CoursePrices';
import { useFetchData } from 'hooks/useFetchData';

const AddNewCourse = () => {

	const { data: currenciesData } = useFetchData('/course/currencies');
	const [currentStep, setCurrentStep] = useState(1);
	const [formData, setFormData] = useState({
		step1: {
		  title: '',
		  category: [],
		  description: '',
		  level: '',
		  subscription: '',
		  discount: '',
		  duration: '',
		  instructor: ''
		},
		step2:[{ currency: '', symbol: '', amount: '', name: '' }],
		step3: {
		  url: '',
		},
		step4: {
			modules: [
				{
				  title: '',
				  description: '',
				  courseId: '',
				  order: '',
				  taskType: '',
				  lessons: [],
				},
			]
		},
	  });

	  // useEffect to retrieve data from local storage on component mount
	  useEffect(() => {
		if (currenciesData) {
			setFormData((prevData) => ({
				...prevData,
				step2: currenciesData?.data?.map(currency => ({
					currency: currency.id,
					symbol: currency.symbol,
					amount: currency.amount,
					name: currency.name
				})),
			}));
		}
	  }, [currenciesData]);
	
	  // useEffect to retrieve data from local storage on component mount
	  useEffect(() => {
		const storedData = localStorage.getItem('formDataKey');
		if (storedData) {
		  const parsedData = JSON.parse(storedData);
		  setFormData(parsedData.formData);
		  setCurrentStep(parsedData.currentStep);
		}
	  }, []);
	  
	  useEffect(() => {
		localStorage.setItem(
		  'formDataKey',
		  JSON.stringify({ formData, currentStep })
		);
	  }, [formData, currentStep]);

	// Function to handle form data changes
	const handleInputChange = (field, value) => {
	  setFormData((prevData) => ({
	  	...prevData,
	  	[`step${currentStep}`]: {
	  		...prevData[`step${currentStep}`],
	  		[field]: value,
	  	},
	  }));
	};

	const handleStep2Change = (index, fieldName, value) => {
		setFormData(prevState => {
		  const newStep2 = [...prevState.step2];
		  newStep2[index] = {
			...newStep2[index],
			[fieldName]: value,
		  };
		  return {
			...prevState,
			step2: newStep2,
		  };
		});
	};

	const handleModuleInputChange = (index, field, value) => {
		setFormData((prevFormData) => {
		  const newModules = [...prevFormData.step4.modules];
		  newModules[index] = {
			...newModules[index],
			[field]: value,
		  };
		  return {
			...prevFormData,
			step4: {
			  ...prevFormData.step4,
			  modules: newModules,
			},
		  };
		});
	  };

	const next = () => {
		setCurrentStep(currentStep === 5 ? 1 : currentStep + 1);
	};
	const previous = () => {
		setCurrentStep(currentStep === 1 ? 1 : currentStep - 1);
	};

	const steps = [
		{
			id: 1,
			title: 'Course Information',
			content: (
				<BasicInformation
					formData={formData}
					setFormData={setFormData}
					handleInputChange={handleInputChange}
					next={next}
				/>
			)
		},
		{
			id: 2,
			title: 'Course Price',
			content: (
				<CoursePrice
					formData={formData}
					setFormData={setFormData}
					handleInputChange={handleStep2Change}
					next={next}
					previous={previous}
				/>
			)
		},
		{
			id: 3,
			title: 'Courses Media',
			content: (
				<CoursesMedia
					formData={formData}
					setFormData={setFormData}
					handleInputChange={handleInputChange}
					next={next}
					previous={previous}
				/>
			)
		},
		{
			id: 4,
			title: 'Curriculum',
			content: (
				<Curriculum
					formData={formData}
					handleInputChange={handleModuleInputChange}
					setFormData={setFormData}
					next={next}
					previous={previous}
				/>
			)
		},
		
	];

	return (
		<Fragment>
			<GKStepper currentStep={currentStep} steps={steps} />
		</Fragment>
	);
};

export default AddNewCourse;
