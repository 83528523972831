import { useState, useEffect } from 'react';

export const useServerFilter = (initialQuery, fetchDataFunction, delay = 300) => {
  const [ searchQuery, setSearchQuery ] = useState(initialQuery);
  const [ pageNumber, setPageNumber ] = useState(1);
  const [ filteredData, setFilteredData ] = useState([]);
  const [ loading, setLoading ] = useState(true);
  const [error, setError] = useState(null);
  const [refreshToken, setRefreshToken] = useState(0);
  
  useEffect(() => {
    const timer = setTimeout(async () => {
      try {
        // Fetch data from the server based on searchQuery
        const data = await fetchDataFunction(searchQuery, pageNumber);
        setFilteredData(data);
      } catch (error) {
        setError(error);
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    }, delay);

    // Clear the timer on component unmount or when searchQuery changes
    return () => clearTimeout(timer);
  }, [searchQuery, pageNumber, refreshToken]);

  // Function to manually trigger a refresh
  const refreshData = () => {
    setRefreshToken(previous => previous + 1);
  }

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handlePageChange = (page) => {
    setPageNumber(page);
  };

  return { searchQuery, loading, error, filteredData, refreshData, handlePageChange, pageNumber, handleSearchChange };
};
