// import node module libraries
import React, { Fragment } from 'react';
import {
	Col,
	Row,
	Card,
	Breadcrumb,
	ListGroup,
	Form,
	Button
} from 'react-bootstrap';

// import sub custom components
import MailSidebar from '../MailSidebar';
import { Outlet, Navigate, useLocation } from 'react-router-dom';


const Mail = () => {
	const location = useLocation();
	return (
		<Fragment>
			<Row>
				<Col lg={12} md={12} sm={12}>
					<div className="border-bottom pb-4 mb-4">
						<div className="mb-3 mb-lg-0">
							<h1 className="mb-0 h2 fw-bold">Mail</h1>
							<Breadcrumb>
								<Breadcrumb.Item href="#"></Breadcrumb.Item>
								<Breadcrumb.Item active>Mail</Breadcrumb.Item>
							</Breadcrumb>
						</div>
					</div>
				</Col>
			</Row>
			<Row>
				<Col xl={12} lg={12} md={12} xs={12}>
					<Card>
						<Row className="g-0">
							<Col xxl={2} xl={3} className="border-end">
								<MailSidebar />
							</Col>
							<Col xxl={10} xl={9} xs={12}>
								<Card.Header className="p-4">
									<div className=" d-md-flex justify-content-between align-items-center">
										<div className="d-flex flex-wrap gap-2 mb-2 mb-md-0">
											<Button
												variant="outline-secondary btn-icon"
												// onClick={() => handleFilter()}
											>
												<i className="fe fe-rotate-cw align-middle "></i>
											</Button>
										</div>
										<div className="">
											<Form.Control
												type="search"
												placeholder={`Search Email`}
												// value={searchTerm}
												// onChange={getSearchTerm}
											/>
										</div>
									</div>
								</Card.Header>
								<div className="">
									<ListGroup
										variant="flush"
										className={`list-group list-group-mail`}
									>
										{location.pathname === '/dashboard/mail' && <Navigate to="/dashboard/mail/inbox" replace />}
										<Outlet />
									</ListGroup>
								</div>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
		</Fragment>
	);
};

export default Mail;
