// import node module libraries
import React, { Fragment, useState } from 'react';
import { Col, Row, Tab, Card, Nav, Breadcrumb, Button } from 'react-bootstrap';

// import sub custom components
import { useFetchData } from 'hooks/useFetchData';
import PaymentTable from './PaymentTable';
import { useSelector } from 'react-redux';
import { useServerFilter } from 'hooks/useServerFilter';
import axios from 'axios';

const AllPayment = () => {

	const BASE_URL = process.env.REACT_APP_API_BASE_URL;
	const {user} = useSelector(state => state.auth);
	const authToken = user && user?.token;

	const fetchAllPaymentData = async (searchQuery, pageNumber) => {
		if (authToken !== null) {
			axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
			const response = await axios.get(`${BASE_URL}/payment/user?page=${pageNumber}&searchQuery=${searchQuery}`);
			return response.data;
		}
	};

	const {
		filteredData: paymentData,
		searchQuery: paymentSearch,
		pageNumber,
		handlePageChange,
		handleSearchChange: onPaymentSearchChange
	} = useServerFilter('', fetchAllPaymentData);

	const lastPage = paymentData && paymentData?.data && paymentData?.data?.pagination?.lastPage;

	return (
		<Fragment>
			<Row>
				<Col lg={12} md={12} sm={12}>
					<div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
						<div className="mb-3 mb-md-0">
							<h1 className="mb-1 h2 fw-bold">Payments</h1>
							<Breadcrumb>
								<Breadcrumb.Item href="#">Home</Breadcrumb.Item>
								<Breadcrumb.Item href="#">Payment</Breadcrumb.Item>
								<Breadcrumb.Item active>All</Breadcrumb.Item>
							</Breadcrumb>
						</div>
					</div>
				</Col>
			</Row>
			<Row>
				<Col lg={12} md={12} sm={12}>
					<Tab.Container defaultActiveKey="all">
						<Card>
							<Card.Header className="border-bottom-0 p-0 bg-white">
								<Nav className="nav-lb-tab">
									<Nav.Item>
										<Nav.Link eventKey="all" className="mb-sm-3 mb-md-0">
											All
										</Nav.Link>
									</Nav.Item>
								</Nav>
							</Card.Header>
							<Card.Body className="p-0">
								<Tab.Content>
									<Tab.Pane eventKey="all" className="pb-4">
										<PaymentTable 
											paymentdata={paymentData && paymentData} 
											lastPage={lastPage}
											currentPage={pageNumber}
											handlePageChange={handlePageChange}
											onSearchChange={onPaymentSearchChange}
											searchValue={paymentSearch}
										/>
									</Tab.Pane>
								</Tab.Content>
							</Card.Body>
						</Card>
					</Tab.Container>
				</Col>
			</Row>
		</Fragment>
	);
};

export default AllPayment;
