import React, { useEffect, useState } from 'react';
import { Form, Col, Row, Button, Badge } from 'react-bootstrap';

const CategorySelector = ({ categories, text, max = 5, selectedCategories, setSelectedCategories }) => {
  const [localSelectedCategories, setLocalSelectedCategories] = useState(selectedCategories);

  useEffect(() => {
    setLocalSelectedCategories(selectedCategories);
  }, [selectedCategories]);

  const handleSelect = (category) => {
    if (localSelectedCategories.includes(category)) {
      const updatedCategories = localSelectedCategories.filter(cat => cat.id !== category.id);
      setLocalSelectedCategories(updatedCategories);
      setSelectedCategories(updatedCategories);
    } else if (localSelectedCategories.length < max) {
      const updatedCategories = [...localSelectedCategories, category];
      setLocalSelectedCategories(updatedCategories);
      setSelectedCategories(updatedCategories);
    }
  };

  return (
    <Form>
      <Row>
        <Col md={6}>
          <Form.Group controlId="categorySelect">
            <Form.Label>{text ? text : "Select Categories"} (max {max}):</Form.Label>
            <div className="d-flex flex-wrap">
              {categories && categories.map(category => (
                <Button
                  key={category.id}
                  variant={localSelectedCategories.includes(category) ? 'primary' : 'outline-primary'}
                  className="m-1"
                  onClick={() => handleSelect(category)}
                >
                  {category.title}
                </Button>
              ))}
            </div>
          </Form.Group>
        </Col>
        <Col md={6}>
          <h5>Selected Categories:</h5>
          {localSelectedCategories.length === 0 ? (
            <p>No categories selected</p>
          ) : (
            <div>
              {localSelectedCategories.map(category => (
                <Badge key={category.id} variant="secondary" className="m-1">{category.title}</Badge>
              ))}
            </div>
          )}
        </Col>
      </Row>
    </Form>
  );
};

export default CategorySelector;
