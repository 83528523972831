import React, { useState, Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Form } from 'react-bootstrap';
import RichTextEditor from 'components/elements/new-editor/RichTextEditor';
import AileadButton from 'components/marketing/common/button/AileadButton';
import { usePostData } from 'hooks/usePostData';
import { notifySuccess } from 'helper/utils';
import EmailTaggingComponent from 'components/elements/tags/EmailTaggingComponent';

export const EmailSendModal = ({show, onHide, mail, refresh}) => {

	const [form, setForm] = useState({
	  subject: mail ? mail.title : '',
	  to: mail ? mail.email : [],
	  body: mail ? mail.description : '',
      attachments: mail ? mail.attachments : []
	});

    const { response, error, loading, postData } = usePostData('/mail/send');
	
    const handleSendEmail = () => {
		const emails = form.to.map((emails) => emails);
		
		const payload = {
            email: emails,
            title: form.subject,
            body: form.body,
            attachments: form.attachments
        }

        postData(payload, true);
    }

	// Handle file input change for both files and images
	const handleFileChange = (e, type) => {
		const files = Array.from(e.target.files);
		setForm((prevForm) => ({
		...prevForm,
		attachments: [...prevForm.attachments, ...files]
		}));
	};

	const handleRemoveFile = (indexToRemove) => {
		setForm((prevForm) => ({
		  ...prevForm,
		  attachments: prevForm.attachments.filter((_, index) => index !== indexToRemove),
		}));
	};

    const handleTagChange = (e) => {
        setForm({...form, to: e})
    }

    useEffect(() => {
        if(response?.data?.status) {
            refresh(true);
            onHide();
            notifySuccess(`Mail Sent to ${form.to.join(', ')}`);
        }
    }, [response])

	return (
		<Fragment>
			<Modal
                show={show}
                onHide={onHide}
				size="xl"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				className="compose-mail"
			>
				<Modal.Header closeButton className="bg-gray-100">
					<Modal.Title id="contained-modal-title-vcenter" as="h5">
						New message
					</Modal.Title>
				</Modal.Header>
				{/* compose form */}
				<Form>
					<Modal.Body className="p-0">
						<div className="border-bottom ">
                            <EmailTaggingComponent 
								selectedEmails={form.to}
								setSelectedEmails={handleTagChange}
							/>
                            {error?.email && <small className='text-danger px-3 mt-4'>{error?.email[0]}</small>}
						</div>
						<div className="border-bottom ">
							<Form.Control
								type="text"
								placeholder="Subject"
								className="border-0 shadow-none"
								name="subject"
								required
                                value={form.subject}
                                onChange={(e) => setForm({...form, subject: e.target.value})}
							/>
                            {error?.title && <small className='text-danger px-3 mt-4'>{error?.title[0]}</small>}
						</div>
						<div className='px-2 py-8'>
							<RichTextEditor
								editorValue={form.body}
								onChange={(event, editor) => {
									const newData = editor.getData();
									setForm({...form, body: newData});
								}}
							/>
                            {error?.body && <small className='text-danger mt-4'>{error?.body[0]}</small>}
						</div>
						{/* Display selected files */}
						{form.attachments.length > 0 && (
							<div className="px-3 py-2">
								<h6>Attachments:</h6>
								<ul>
								{form.attachments.map((file, index) => (
									<li key={index} style={{ display: 'flex', alignItems: 'center', padding: '10px', margin: '10px', borderRadius: '8px', border: '1px solid #ccc' }}>
										{file.type.startsWith('image/') ? (
											<img
											src={URL.createObjectURL(file)}
											alt={file.name}
											style={{ width: '100px', height: '100px', objectFit: 'cover', marginRight: '10px' }}
											/>
										) : (
											<span style={{ marginRight: '10px' }}>{file.name}</span>
										)}
										{/* Remove Button */}
										<button 
											onClick={() => handleRemoveFile(index)} 
											type='button'
											style={{ border: 'none', backgroundColor: 'transparent', color: 'red', cursor: 'pointer' }}>
											Remove
										</button>
									</li>
								))}
								</ul>
							</div>
						)}
					</Modal.Body>
						<Modal.Footer className="justify-content-between">
							<div className="">
                                <AileadButton 
									className="btn btn-primary" 
									variant="primary" 
									text="Send"
									type="button"
									loading={loading} 
									onClick={handleSendEmail} 
								/>
							<Form.Group
								as="span"
								className="ms-4 compose-img-upload cursor-pointer"
								controlId="file-input"
							>
								<Form.Label>
									<i className="fe fe-paperclip text-muted "></i>
								</Form.Label>
								<Form.Control 
									type="file" 
									onChange={(e) => handleFileChange(e, 'file')} 
								/>
							</Form.Group>
							<Form.Group
								as="span"
								className="ms-4 compose-img-upload cursor-pointer"
								controlId="file-input-second"
							>
								<Form.Label>
									<i className="fe fe-image text-muted "></i>
								</Form.Label>
								<Form.Control 
									type="file" 
									accept="image/*"
                  					onChange={(e) => handleFileChange(e, 'image')} 
								/>
							</Form.Group>
							<Link to="#" className="ms-3 text-muted">
								<i className="fe fe-link"></i>
							</Link>
						</div>
					</Modal.Footer>
				</Form>
				{/* end of compose form */}
			</Modal>
		</Fragment>
	);
};

