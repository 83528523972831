import React, {useState} from 'react';
import { curriculumData } from 'helper/utils';
import Lessons from './Lessons';
import { AddLessonModal } from '../steps/modals/AddLessonModal';
import DropArea from './DropArea';
import AileadButton from 'components/marketing/common/button/AileadButton';
import { usePostData } from 'hooks/usePostData';
import { AddModuleModal } from '../steps/modals/AddModuleModal';
import { DeleteConfirmationModal } from 'components/dashboard/business/all-courses/common/Components';

  const Modules = ({ module, moduleIndex, formData, setFormData, handleActiveModule, handleActiveLesson, onLessonDrop }) => {
    const [targetModuleIndex, setTargetModuleIndex] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const [show, setShow] = useState(false);
	const handleClose = () => {
		setShow(false);
	}
    const {loading, postData} = usePostData("/module/remove/lesson");

    const addLesson = (moduleIndex, newLesson) => {
        curriculumData("addLesson", setFormData, moduleIndex, null, newLesson, null)
    }

    const removeModule = (newModule) => {
        curriculumData("removeModule", setFormData, null, null, newModule, postData)
    }

    const updateModule = (newModule) => {
        curriculumData("updateModule", setFormData, null, null, newModule, null)
    }

	return (
        <div
            draggable
            onDragStart={() => {
                handleActiveModule(moduleIndex);
                setIsDragging(true);
            }}
			onDragEnd={() => {
                handleActiveModule(null);
                setIsDragging(false);
            }}
            onDragOver={(e) => {
                e.preventDefault();
                setTargetModuleIndex(moduleIndex)
            }}
        >
            {module.title && (
                <div className="bg-light rounded p-2">
                    <div className='d-flex justify-content-between'>
                        <h4>{module.title}</h4>
                        <div className='w-20 d-flex justify-content-around'>
                            <AddModuleModal addModule={updateModule} formData={formData} initialModule={module} />
                            <AileadButton text="Delete" onClick={() => setShow(true)} variant="outline-danger" className="btn btn-outline-danger btn-sm mt-3" type={"button"} />
                            <DeleteConfirmationModal
                                show={show}
                                item={module.title}
                                handleClose={handleClose}
                                handleConfirm={() => removeModule(module)}
                                loading={loading}
                            />
                        </div>
                    </div>
                    <DropArea title={"Lesson"} onDrop={() => onLessonDrop(targetModuleIndex, 0)}  />
                    {module.lessons.map((lesson, lessonIndex) => (
                        <div key={lessonIndex}>  
                            <Lessons
                                lesson={lesson}
                                lessonIndex={lessonIndex}
                                formData={formData}
                                setFormData={setFormData}
                                index={moduleIndex}
                                handleActiveLesson={handleActiveLesson}
                            />  
                            <DropArea title={"Lesson"} onDrop={() => onLessonDrop(targetModuleIndex, lessonIndex + 1)} /> 
                        </div>
                    ))}
                    <AddLessonModal formData={formData} moduleIndex={moduleIndex} addLesson={addLesson} />
                </div>
            )}
        </div>
    );    
};
export default Modules;
