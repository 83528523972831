export const courseData = (formData) => {
    return {
		"courseId": formData.step1.id,
		"title": formData.step1.title,
		"description": formData.step1.description ? formData.step1.description : '',
		"categoryIds": formData.step1.category,
		"instructorId": null,
		"image": formData.step3.url,
		"duration": "10:30:00",
		"level": formData.step1.level,
		"discount": formData.step1.discount,
		"subscription": formData.step1.subscription,
		"modules": formData.step4.modules,
		"prices": formData.step2.map((item, i) => {
			return {
				id: item.id,
				currency: item.currency,
				amount: item.amount
			}
		})
	}
}