// import node module libraries
import { Fragment, useState, useEffect } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import { usePostData } from 'hooks/usePostData';
import RichTextEditor from 'components/elements/new-editor/RichTextEditor';
import AileadButton from 'components/marketing/common/button/AileadButton';

// import data files

export const AddModuleModal = ({ addModule, initialModule, formData }) => {
	const [show, setShow] = useState(false);
	const [module, setModule] = useState({
	  title: '',
	  description: '',
	  order: '',
	  taskType: ''
	});

	const task = [
		{id: 1, title: 'jupyterhub'},
		{id: 2, title: 'vscode'}
	];

	const {response: validationResponse, loading: loadingValidation, error: validationErrors, postData} = usePostData('/course/validate/module');
  
	const handleClose = () => {

	  if(!initialModule) {
		// Reset module state when closing
		setModule({
			title: '',
			description: '',
			order: '',
			taskType: ''
		});
	  }

	  setShow(false);
	};

	useEffect(() => {
        if (initialModule) {
            setModule(initialModule);
        }
    }, [initialModule]);
  
	const handleShow = () => {
		if(!initialModule) {
			setModule({
				title: '',
				description: '',
				order: '',
				taskType: ''
			});
		}
		setShow(true)
	};
  
	const handleAddModule = () => {
	  	// Perform any necessary actions before closing the modal

		// Get the existing modules
		const existingModules = formData.step4.modules;

		// Check if any module in the array is empty
		const hasEmptyModule = existingModules.some(module => (
			module.title === '' && module.description === '' && module.courseId === '' && module.order === '' && module.taskType === ''
		));

		// Find the maximum order value among existing lessons in the module
		const newOrder = hasEmptyModule ? 1: existingModules.length + 1;
    
		// Create a new lesson object with the generated order
		const newModule = {
			...module,
			order: newOrder
		};

		const addNewModule = initialModule ? module : newModule;

	   addModule(addNewModule);
	   handleClose();
	};

	const nextStep = () => {
		postData(module);
	}

	useEffect(() => {
		if(validationResponse !== null && validationResponse?.status === "Success"){
			handleAddModule();
		}
	}, [validationResponse])
  
	return (
	  <Fragment>
		{initialModule ? 
			(
			 <Button
				variant="outline-primary"
				className="btn btn-outline-primary btn-sm mt-3"
				onClick={handleShow}
				>
				Edit Module
			 </Button>
			) : 
			
			(
			 <Button
				variant="outline-primary"
				className="btn btn-outline-primary btn-sm mt-3"
				onClick={handleShow}
			 >
			  Add Module+
			</Button>
			)
		}
		<Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg">
		  <Modal.Header closeButton>
			<Modal.Title>Add New Module</Modal.Title>
		  </Modal.Header>
		  <Modal.Body className="pb-0">
			<Form.Group className="mb-3" controlId="formaddnewmodule">
			 <Form.Label>Select Computing Environment</Form.Label>
			 <Form.Select 
			  placeholder='Select Computing Environment'
			  aria-label='Select Computing Environment'
			  className="mb-4"
			  value={module.taskType}
			  onChange={(e) => setModule({...module, taskType: e.target.value})}
			 >
			  <option value="">None</option>
			  {task.map((item) => (
				<option key={item.id} value={item.title}>
				  {item.title}
				</option>
			  ))}
			 </Form.Select>
			 {validationErrors && validationErrors.taskType && (
				<Form.Text className="col-sm-6 text-danger mb-2 mt-1">
					{validationErrors.taskType[0]}
				</Form.Text> 
			  )}

			  <Form.Control
				type="text"
				className='mb-4'
				placeholder="Add module Title"
				value={module.title}
				onChange={(e) => setModule({ ...module, title: e.target.value })}
			  />
			  {validationErrors && validationErrors.title && (
				<Form.Text className="col-sm-6 text-danger mb-2 mt-1">
					{validationErrors.title[0]}
				</Form.Text>   
			  )}
			  
				<RichTextEditor
					editorValue={module.description}
					onChange={
						(event, editor) => {
							const newData = editor.getData();
							setModule({ ...module, description: newData })
						}
					}
				/>

			  {validationErrors && validationErrors.description && (
				<Form.Text className="col-sm-6 text-danger mb-1 mt-1">
					{validationErrors.description[0]}
				</Form.Text>   
			  )}
			</Form.Group>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
		  </Modal.Body>
		  <Modal.Footer className="pt-0 border-0 d-inline">
			<AileadButton type="button" variant="primary" onClick={nextStep} loading={loadingValidation} text={initialModule ? "Edit Module" : "Add New module"} />
			<AileadButton type="button" variant="outline-secondary" onClick={handleClose} text="Close" />
		  </Modal.Footer>
		</Modal>
	  </Fragment>
	);
  };