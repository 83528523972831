import React, { Fragment } from 'react';
import VideoPlayer from './video/VideoPlayer';
import TextViewer from './html/TextViewer';

const ServeContent = ({value}) => {

  const ContentType = ({data}) => {
    switch(data && data.type) {
      case "video":
        return (
          <VideoPlayer url={data && data.url} lessonId={data && data.lessonId} courseId={data && data.courseId}  /> 
        )
      case "text":
        return (
          <TextViewer title={data && data.title} description={data && data.description} />
        )
		}
  }

  return (
    <Fragment>
      <ContentType data={value && value} />
    </Fragment>
  );
};

export default ServeContent;
