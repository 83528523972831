// import node module libraries
import React, { useContext, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import {
	Accordion,
	Card,
	useAccordionButton,
	AccordionContext,
	OverlayTrigger,
	Tooltip
} from 'react-bootstrap';
import { AddLessonModal } from 'components/dashboard/courses/add-new-course/steps/modals/AddLessonModal';
import AileadButton from '../button/AileadButton';

const LessonAccordionActions = ({ accordionItems, index, moduleIndex, formData, addLesson, removeLesson, addModule, removeLessonLoading }) => {
	const ContextAwareToggle = ({ children, eventKey, callback }) => {
		const currentEventKey = useContext(AccordionContext);
		const decoratedOnClick = useAccordionButton(
			eventKey,
			() => callback && callback(eventKey)
		);
		const isCurrentEventKey = currentEventKey === eventKey;
		const overlayKeyEdit = uuid();
		const overlayKeyDelete = uuid();

		return (
			<Fragment>
				<div className="d-flex align-items-center justify-content-between">
					<h5 className="mb-0">
						<Link
							onClick={decoratedOnClick}
							aria-expanded={isCurrentEventKey}
							to="#"
							className="text-inherit"
						>
							<span className="align-middle p-1">{children}</span>
						</Link>
					</h5>
					<div>
						<OverlayTrigger
							key={overlayKeyEdit}
							placement="top"
							overlay={<Tooltip id="tooltip-top"> Edit</Tooltip>}
						>
							<AddLessonModal formData={formData} initialLesson={accordionItems} moduleIndex={moduleIndex} addLesson={addLesson} lessonIndex={index} />
						</OverlayTrigger>

						<OverlayTrigger
							key={overlayKeyDelete}
							placement="top"
							overlay={<Tooltip id="tooltip-top"> Delete</Tooltip>}
						>
							<AileadButton 
								onClick={() => removeLesson(moduleIndex, index)} 
								className="me-1 text-inherit" 
								text="Delete"
								type="button"
								loading={removeLessonLoading}
							/>
						</OverlayTrigger>

						<Link
							to="#"
							className="text-inherit"
							data-bs-toggle="collapse"
							onClick={decoratedOnClick}
							aria-expanded={isCurrentEventKey}
						>
							<span className="chevron-arrow">
								<i className="fe fe-chevron-down fs-5"></i>
							</span>
						</Link>
					</div>
				</div>
			</Fragment>
		);
	};

	const generateKey = (pre) => {
		if(pre){
			pre = pre.toLowerCase();
			pre = pre.replace(' ', '_');
			return `${pre}_${new Date().getTime()}`;
		}
	};
	return (
		<Fragment>
			<Accordion defaultActiveKey={accordionItems.title ? accordionItems.title : accordionItems.content}>
					<Card
						key={generateKey(accordionItems.title ? accordionItems.title + index : accordionItems.content + index)}
						className="px-2 py-2 mb-1 shadow-none"
					>
						<Card.Header className="bg-transparent border-0 p-0">
							<div className="border-0">
								<h3 className="mb-0 fw-bold">
									<ContextAwareToggle eventKey={accordionItems.title ? accordionItems.title + index : accordionItems.content + index}>
										{accordionItems.title ? accordionItems.title : accordionItems.content}
									</ContextAwareToggle>
								</h3>
							</div>
						</Card.Header>
						<Accordion.Collapse eventKey={accordionItems.title ? accordionItems.title + index : accordionItems.content + index}>
							<Card.Body className="fs-4">
								{/* {accordionItems.content}	 */}
								{accordionItems.content.type === 'video' && (
									<>
										<p>Title : {accordionItems.content.data.title}</p>
										<p>Description : {accordionItems.content.data.description}</p>
										<p>Video URL : {accordionItems.content.data.url}</p>
										<p>Video Duration : {accordionItems.content.data.duration}</p>
									</>
								)}

								{accordionItems.content.type === 'text' && (
									<>
										<p>Title : {accordionItems.content.data.title}</p>
										<p>Description : {accordionItems.content.data.description}</p>
									</>
								)}

								{accordionItems.content.type === 'file' && (
									<>
										<p>File URL : {accordionItems.content.data.url}</p>
									</>
								)}

								{accordionItems.content.type === 'task' && (
									<>
										<p>Task Title : {accordionItems.content.data.title}</p>
										<p>Task Description : {accordionItems.content.data.description}</p>
										<p>Task URL : {accordionItems.content.data.url}</p>
									</>
								)}

								{accordionItems.isCorrect && (
									<>
										<p>Content : {accordionItems.content}</p>
										<p>Is Correct Answer? : {accordionItems.isCorrect}</p>
										
									</>
								)}
							</Card.Body>
						</Accordion.Collapse>
					</Card>
			</Accordion>
		</Fragment>
	);
};

export default LessonAccordionActions;
