// Import node module libraries
import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import RichTextEditor from 'components/elements/new-editor/RichTextEditor';
import { usePostData } from 'hooks/usePostData';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const SendMessage = () => {
  const [message, setMessage] = useState('');
  const [week, setWeek] = useState('');
  const [attachments, setAttachments] = useState([]);
  const { groupId, threadId } = useParams();
  const { user } = useSelector(state => state.auth);

  const { error, postData } = usePostData('/message/reply');

  // Check if the user has the required role
  // const hasPermission = user?.role.includes('admin') || user?.role.includes('instructor');

  // Handle form submission
  const handleSubmit = () => {
    if (!message) return;

    const newMessage = {
      content: `${message.replace(/(?:\r\n|\r|\n)/g, '<br>')}`,
      attachments,
      groupId,
      week,
      parentId: threadId,
    };

    postData(newMessage);
    setMessage('');
  };

  // Conditionally render the editor based on the route and user role
  const shouldShowEditor = threadId;

  return (
    <>
      {shouldShowEditor && (
        <div className="bg-white p-2 rounded-3 shadow-sm">
          <div className='px-2 py-2'>
            <RichTextEditor
              editorValue={message}
              onChange={(event, editor) => {
                const newData = editor.getData();
                setMessage(newData);
              }}
            />
            {error?.body && <small className='text-danger mt-4'>{error?.body[0]}</small>}
          </div>
          <div className="position-absolute end-0 mt-n7 me-4">
            <Button
              variant="none"
              bsPrefix="btn"
              type="button"
              className="fs-3 text-primary btn-focus-none"
              onClick={handleSubmit}
            >
              <i className="fe fe-send"></i>
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default SendMessage;