// import node module libraries
import { Fragment, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import AileadButton from 'components/marketing/common/button/AileadButton';

const AddNewCategoryPopup = ({
	handleClose, 
	show, 
	initialValue,
	handleSubmit,
	loading, 
	error
}) => {

	const [title, setTitle] = useState(initialValue ? initialValue.title : "");
	const [description, setDescription] = useState(initialValue ? initialValue.description : "");
	const closeModal = () => {
		handleClose();
		setTitle("");
		setDescription("");
	}

	const submitForm = () => {
		const id = initialValue ? initialValue.id : null;
		handleSubmit({
			id,
			title,
			description: description
		})
	}
	return (
		<Fragment>
			<Modal show={show} onHide={closeModal} size="lg">
				<Modal.Header closeButton>
					<Modal.Title>{initialValue ? "Edit Category" : "Create New Category"}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{/*  Form  */}
					<Form>
						{/* Title  */}
						<Form.Group className="mb-3">
							<Form.Label>Title</Form.Label>
							<Form.Control
								type="text"
								placeholder="Write a Category"
								id="category-name"
								value={title}
								onChange={(e) => setTitle(e.target.value)}
							/>
							{error?.errors && error?.errors?.title ? 
								(
									<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
										{error?.errors?.title[0]}
									</Form.Text>
								): 
								(
									<Form.Text className="text-muted">
										Field must contain a unique value
									</Form.Text>
								)
							}
						</Form.Group>
						
						{/*  Editor  */}
						<Form.Group className="mb-3">
							<ReactQuill
								value={description}
								onChange={(content, delta, source, editor) => {
									const value = editor.getContents();  // Adjust this line based on what you need
									const describeValue = value && value?.ops[0]?.insert
									setDescription(describeValue);
								}}
							/>
							{error?.errors && error?.errors?.description ? 
								(
									<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
										{error?.errors?.description[0]}
									</Form.Text>
								): 
								(
									<Form.Text className="text-muted">
										A brief summary of Category.
									</Form.Text>
								)
							}
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer className="d-flex justify-content-start border-0 pt-0">
					{/*  Action Buttons  */}
					<AileadButton
						text={initialValue ? "Edit Category" : "Add New Category" }
						loading={loading} 
						variant="primary"
						onClick={submitForm} 
						type="button"
						disabled={loading} 
					/>
					
					<AileadButton
						text="Close" 
						variant="outline-secondary" 
						onClick={handleClose}
						type="button"
					/>
				</Modal.Footer>
			</Modal>
		</Fragment>
	);
};

export default AddNewCategoryPopup;
