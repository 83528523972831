import { useEffect } from 'react';
// import node module libraries
import { BrowserRouter as Router } from 'react-router-dom';

// import layouts
import ScrollToTop from 'layouts/dashboard/ScrollToTop';
import AllRoutes from 'layouts/AllRoutes';

// import required stylesheet
import 'simplebar/dist/simplebar.min.css';
import 'tippy.js/animations/scale.css';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import PageTitleDescription from 'components/elements/site-headers/PageTitleDescription';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { FetchSettings } from 'components/elements/FetchSettings';


function App() {
	const queryClient = new QueryClient();
	
	return (
		<HelmetProvider>
		 	<QueryClientProvider client={queryClient}>
			   <FetchSettings />
				<Router>
					<PageTitleDescription />
					{/* <GoogleAnalyticsScript /> */}
					<ScrollToTop />
					<AllRoutes />
					<ToastContainer />
					
				</Router>
			</QueryClientProvider>
		</HelmetProvider>
	);
}

export default App;
