// import node module libraries
import React from 'react';
import { Col, Row, Card, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// import custom components
import { FlatPickr } from 'components/elements/flat-pickr/FlatPickr';
import ApexCharts from 'components/elements/charts/ApexCharts';
import StatRightIcon from 'components/dashboard/common/stats/StatRightIcon';

// import sub components
import PopularInstructor from './PopularInstructor';
import PopularCourses from './PopularCourses';
import { useFetchData } from 'hooks/useFetchData';

// import data files
import {
	getEarningsChartOptions
} from 'data/charts/ChartData';
import { numberWithCommas } from 'helper/utils';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
	<Link
		to=""
		ref={ref}
		onClick={(e) => {
			e.preventDefault();
			onClick(e);
		}}
		className="btn-icon btn btn-ghost btn-sm rounded-circle"
	>
		{children}
	</Link>
));

const ChartActionMenu = () => {
	return (
		<div>
			<Dropdown>
				<Dropdown.Toggle as={CustomToggle}>
					<i className="fe fe-more-vertical text-muted"></i>
				</Dropdown.Toggle>
				<Dropdown.Menu align="end">
					<Dropdown.Header>SETTINGS</Dropdown.Header>
					<Dropdown.Item eventKey="1">
						<i className="fe fe-external-link dropdown-item-icon "></i> Export
					</Dropdown.Item>
					<Dropdown.Item eventKey="2">
						<i className="fe fe-mail dropdown-item-icon "></i> Email Report
					</Dropdown.Item>
					<Dropdown.Item eventKey="3">
						<i className="fe fe-download dropdown-item-icon "></i> Download
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</div>
	);
};

const Overview = () => {
	const {data} = useFetchData('/dashboard/cards')
	const NGN_EARNINGS = data?.data?.sales?.monthly["NGN"]?.data;
	const USD_EARNINGS = data?.data?.sales?.monthly["USD"]?.data;

	const NGN_EARNINGDATA = NGN_EARNINGS && NGN_EARNINGS?.map((item, index) => {
		return parseInt(item.total);
	});

	const USD_EARNINGDATA = USD_EARNINGS && USD_EARNINGS.map((item, index) => {
		return parseInt(item.total);
	});

	return (
		<div>
			<Row>

				<Col lg={12} md={12} sm={12}>
					<div className="border-bottom pb-4 mb-4 d-lg-flex justify-content-between align-items-center">
						<div className="mb-3 mb-lg-0">
							<h1 className="mb-0 h2 fw-bold">Dashboard</h1>
						</div>
						<div className="d-flex">
							<div className="input-group me-3  ">
								<FlatPickr value={''} />
								<span className="input-group-text text-muted" id="basic-addon2">
									<i className="fe fe-calendar"></i>
								</span>
							</div>
							<Link to="#" className="btn btn-primary">
								Setting
							</Link>
						</div>
					</div>
				</Col>
			</Row>
			<Row>
				{data?.data?.sales?.allTime?.map((item, index) => (
				<Col xl={3} lg={6} md={12} sm={12}>
					<StatRightIcon
						title={`SALES (in ${item.currency})`}
						value={`${item.symbol} ${numberWithCommas(parseFloat(item.total))}`}
						// summary="Number of sales"
						// summaryValue="+20.9$"
						// summaryIcon="up"
						// showSummaryIcon
						iconName="shopping-bag"
						iconColorVariant="primary"
						classValue="mb-4"
					/>
				</Col>))}

				<Col xl={3} lg={6} md={12} sm={12}>
					<StatRightIcon
						title="UNPUBLISHED COURSES"
						value={data?.data?.courses?.pending}
						// summary="Number of pending"
						// summaryValue="120+"
						// summaryIcon="down"
						iconName="book-open"
						iconColorVariant="primary"
						classValue="mb-4"
					/>
				</Col>

				<Col xl={3} lg={6} md={12} sm={12}>
					<StatRightIcon
						title="PUBLISHED COURSES"
						value={data?.data?.courses?.published}
						// summary="Number of pending"
						// summaryValue="120+"
						// summaryIcon="down"
						iconName="book-open"
						iconColorVariant="primary"
						classValue="mb-4"
					/>
				</Col>

				<Col xl={3} lg={6} md={12} sm={12}>
					<StatRightIcon
						title="QUIZZES"
						value={data?.data?.quizzes}
						// summary="Number of pending"
						// summaryValue="120+"
						// summaryIcon="down"
						iconName="book-open"
						iconColorVariant="primary"
						classValue="mb-4"
					/>
				</Col>

				<Col xl={3} lg={6} md={12} sm={12}>
					<StatRightIcon
						title="ASSESSMENTS"
						value={data?.data?.assessments}
						// summary="Number of pending"
						// summaryValue="120+"
						// summaryIcon="down"
						iconName="book-open"
						iconColorVariant="primary"
						classValue="mb-4"
					/>
				</Col>

				<Col xl={3} lg={6} md={12} sm={12}>
					<StatRightIcon
						title="STUDENTS"
						value={data?.data?.students}
						// summary="Students"
						// summaryValue="+1200"
						// summaryIcon="up"
						// showSummaryIcon
						iconName="users"
						iconColorVariant="primary"
						classValue="mb-4"
					/>
				</Col>

				<Col xl={3} lg={6} md={12} sm={12}>
					<StatRightIcon
						title="INSTRUCTOR"
						value={data?.data?.tutors}
						// summary="Instructor"
						// summaryValue="+200"
						// summaryIcon="up"
						// showSummaryIcon
						iconName="user-check"
						iconColorVariant="primary"
						classValue="mb-4"
					/>
				</Col>
				<Col xl={3} lg={6} md={12} sm={12}>
					<StatRightIcon
						title="BUSINESS OWNERS"
						value={data?.data?.businesses}
						// summary="Instructor"
						// summaryValue="+200"
						// summaryIcon="up"
						// showSummaryIcon
						iconName="user-check"
						iconColorVariant="primary"
						classValue="mb-4"
					/>
				</Col>
			</Row>
			<Row>
				<Col xl={12} lg={12} md={12} className="mb-4">
					<Card>
						<Card.Header className="align-items-center card-header-height d-flex justify-content-between align-items-center">
							<div>
								<h4 className="mb-0">Earnings (in NGN)</h4>
							</div>
							<div>
								<ChartActionMenu />
							</div>
						</Card.Header>
						<Card.Body>
							{NGN_EARNINGDATA && (<ApexCharts
								options={getEarningsChartOptions(NGN_EARNINGDATA)}
								series={[{
									name:"Current Month",
									data: NGN_EARNINGDATA
								}]}
								type="line"
							/>)}
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col xl={12} lg={12} md={12} className="mb-4">
					<Card>
						<Card.Header className="align-items-center card-header-height d-flex justify-content-between align-items-center">
							<div>
								<h4 className="mb-0">Earnings (in USD)</h4>
							</div>
							<div>
								<ChartActionMenu />
							</div>
						</Card.Header>
						<Card.Body>
							{USD_EARNINGDATA && (<ApexCharts
								options={getEarningsChartOptions(USD_EARNINGDATA)}
								series={[{
									name:"Current Month",
									data: USD_EARNINGDATA
								}]}
								type="line"
							/>)}
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col xl={4} lg={6} md={12} className="mb-4">
					<PopularCourses data={data?.data?.popularCourses} />
				</Col>
				<Col xl={4} lg={6} md={12} className="mb-4">
					<PopularInstructor title="Popular Instructor" />
				</Col>
			</Row>
		</div>
	);
};

export default Overview;
