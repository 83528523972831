import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Tab, Card, Breadcrumb, FormControl } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import CohortTable from './CohortTable';

const AllCohorts = () => {
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const { user } = useSelector(state => state.auth);
  const authToken = user && user?.token;

  const [searchQuery, setSearchQuery] = useState('');
  const [pageNumber, setPageNumber] = useState(1);

  const fetchCourses = async ({ queryKey }) => {
    const [, searchQuery, pageNumber] = queryKey;
    if (authToken !== null) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
      const response = await axios.get(`${BASE_URL}/cohort?page=${pageNumber}&searchQuery=${searchQuery}`);
      return response.data;
    }
  };

  const {
    data: courseData,
    isLoading,
    isError,
    error,
    refetch
  } = useQuery({
    queryKey: ['cohort', searchQuery, pageNumber],
    queryFn: fetchCourses,
    keepPreviousData: true,
    staleTime: 300000, // 5 minutes
    cacheTime: 900000, // 15 minutes
  });

  const handlePageChange = (newPageNumber) => {
    setPageNumber(newPageNumber);
    refetch();
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPageNumber(1);
    refetch();
  };

  if (isLoading) {
    // return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  const cohortsData = courseData?.data;

  return (
    <Fragment>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
            <div className="mb-3 mb-md-0">
              <h1 className="mb-1 h2 fw-bold">Cohorts</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="#">Cohort</Breadcrumb.Item>
                <Breadcrumb.Item active>All</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <FormControl
            type="search"
            placeholder="Search courses..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <Tab.Container defaultActiveKey="published">
            <Card>
              <Card.Body className="p-0">
                <CohortTable 
                    cohort_data={cohortsData} 
                    lastPage={cohortsData?.pagination?.lastPage}
                    currentPage={cohortsData?.pagination?.currentPage}
                    handlePageChange={handlePageChange}
                    refreshData={refetch}
                    loading={isLoading}
                />
              </Card.Body>
            </Card>
          </Tab.Container>
        </Col>
      </Row>
    </Fragment>
  );
};

export default AllCohorts;
