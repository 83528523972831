// import node module libraries
import { Fragment, useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { Col, Row, Card, Form, Button, Image } from 'react-bootstrap';
// import media files
import { usePostData } from 'hooks/usePostData';
import { useFetchData } from 'hooks/useFetchData';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../../redux/actions/authActions';
import { notifyError, notifySuccess } from 'helper/utils';
import AileadButton from 'components/marketing/common/button/AileadButton';


const SignIn = () => {

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const {postData, loading, error, response } = usePostData('/auth/login');
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const siteLogo = useSelector((state) => {
		const settingArray = state.setting?.setting && state.setting?.setting.settings || [];
		const siteLogoItem = settingArray.find((item) => item.key === "site_logo");
		return siteLogoItem?.value || ''; // Return an empty string if not found
	});

	const handleSubmit = (e) => {
		e.preventDefault(); // Prevent the default form submission
		postData({ email, password });
	};

	useEffect(() => {
		const fetchData = async () => {
			if (response !== null 
				&& response?.data?.profile && 
				(response?.data?.profile?.role.includes('admin') || 
				response?.data?.profile?.role.includes('business') || 
				response?.data?.profile?.role.includes('student'))
			) {
			  const authenticatedUser = response?.data?.profile;
			  // Dispatch login action
			  await dispatch(login(authenticatedUser));
			  // Notify user of successful login
			  notifySuccess('Successfully logged in!');
			} else {
			  notifyError(error);
			}
		};
	  
		fetchData(); // Call the async function
	}, [response, dispatch]);
	  
	// Use the useSelector hook to access user data directly from the Redux store
	const {user} = useSelector(state => state.auth);
	  
	// Use another useEffect to handle navigation
	useEffect(() => {
		// Check if the necessary data is available before navigating
		if (user) {
		  // Navigate based on user role
		  if (user.role.includes('admin') || user.role.includes('business') || user.role.includes('student')) {
			navigate('/dashboard/overview');
		  } 
		}
	  
	}, [user, notifySuccess]);

	return (
		<Fragment>
			<Row className="align-items-center justify-content-center g-0 min-vh-100">
				<Col lg={5} md={5} className="py-8 py-xl-0">
					<Card>
						<Card.Body className="p-6">
							<div className="mb-4">
								<Link to="/">
									<Image src={siteLogo} className="mb-4" alt="" />
								</Link>
								<h1 className="mb-1 fw-bold">Sign in</h1>
							</div>
							{/* Form */}
							<Form onSubmit={handleSubmit}>
								<Row>
									<Col lg={12} md={12} className="mb-3">
										{/* Username or email */}
										<Form.Label>Username or email </Form.Label>
										<Form.Control
											type="email"
											id="email"
											placeholder="Email address here"
											required
											onChange={(e) => setEmail(e.target.value)}
											value={email}
										/>
									</Col>
									<Col lg={12} md={12} className="mb-3">
										{/* Password */}
										<Form.Label>Password </Form.Label>
										<Form.Control
											type="password"
											id="password"
											placeholder="**************"
											required
											onChange={(e) => setPassword(e.target.value)}
											value={password}
										/>
									</Col>
									<Col lg={12} md={12} className="mb-3">
										{/* Checkbox */}
										<div className="d-md-flex justify-content-between align-items-center">
											<Form.Group
												className="mb-3 mb-md-0"
												controlId="formBasicCheckbox"
											>
												<Form.Check type="checkbox" label="Remember me" />
											</Form.Group>
										</div>
									</Col>
									<Col lg={12} md={12} className="mb-0 d-grid gap-2">
										<AileadButton variant="primary" type='submit' text={"Sign in"} loading={loading} />
									</Col>
								</Row>
							</Form>
							<hr className="my-4" />
							<div className="mt-4 text-center">
								{/* Facebook */}
								<Link
									to="#"
									className="btn-social btn-social-outline btn-facebook"
								>
									<i className="fab fa-facebook"></i>
								</Link>{' '}
								{/* Twitter */}
								<Link
									to="#"
									className="btn-social btn-social-outline btn-twitter"
								>
									<i className="fab fa-twitter"></i>
								</Link>{' '}
								{/* LinkedIn */}
								<Link
									to="#"
									className="btn-social btn-social-outline btn-linkedin"
								>
									<i className="fab fa-linkedin"></i>
								</Link>{' '}
								{/* GitHub */}
								<Link
									to="#"
									className="btn-social btn-social-outline btn-github"
								>
									<i className="fab fa-github"></i>
								</Link>
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Fragment>
	);
};

export default SignIn;
