// Importing necessary modules and components
import { Fragment, useEffect, useState } from 'react';
import { Col, Row, Breadcrumb, Card, Form } from 'react-bootstrap';
import { useFetchData } from 'hooks/useFetchData';
import { usePostData } from 'hooks/usePostData';
import { notifyError, notifySuccess } from 'helper/utils';
import AileadButton from 'components/marketing/common/button/AileadButton';

const FAQSetting = () => {
    const { postData, loading, error, response } = usePostData('/site/faq/add');
    const { data: settingsData, refreshData } = useFetchData('/site/faq/list');

    // State variables for managing FAQ input fields
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');

    // Handling updates to FAQs
    const updateSettings = () => {
        postData({
            question,
            answer,
        });
    };

    // Handling success and error notifications
    useEffect(() => {
        if (response?.data && response?.status === "Success") {
            refreshData();
            setQuestion("");
            setAnswer("");
            notifySuccess('Settings Successfully updated!');
        }
        if (error) {
            notifyError(error);
        }
    }, [response?.data, error]);

    return (
        <Fragment>
            <Row>
                <Col xl={{ offset: 3, span: 6 }} lg={{ offset: 2, span: 8 }} md={12}>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
                                <div className="mb-3 mb-md-0">
                                    <h1 className="mb-1 h2 font-weight-bold">General Setting</h1>
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
                                        <Breadcrumb.Item href="#">Settings</Breadcrumb.Item>
                                        <Breadcrumb.Item active>FAQ</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        {/* Form for adding/editing FAQ */}
                        <Col xl={12} lg={12} md={12}>
                            <Card className="mb-4">
                                <Card.Header className="d-flex align-items-center justify-content-between">
                                    <h4 className="mb-0">Add FAQ</h4>
                                </Card.Header>
                                <Card.Body>
                                    <Form>
                                        <Row>
                                            <Col lg={12} md={12} className="mb-3">
                                                {/* FAQ Question */}
                                                <Form.Label>
                                                    Question{' '}
                                                    <small className="text-muted">(Enter your FAQ question)</small>
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="faqQuestion"
                                                    placeholder="Enter FAQ Question"
                                                    value={question}
                                                    onChange={(e) => setQuestion(e.target.value)}
                                                />
                                            </Col>
                                            <Col lg={12} md={12} className="mb-3">
                                                {/* FAQ Answer */}
                                                <Form.Label>Answer</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={3}
                                                    id="faqAnswer"
                                                    placeholder="Enter FAQ Answer"
                                                    value={answer}
                                                    onChange={(e) => setAnswer(e.target.value)}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12} md={12}>
                                                <AileadButton
                                                    variant="primary"
                                                    text="Add"
                                                    onClick={updateSettings}
                                                    loading={loading}
                                                    type="button"
                                                />
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                        {/* End of Form for adding/editing FAQ */}

                        {/* List of FAQs */}
                        <Col xl={12} lg={12} md={12}>
                            <Card className="mb-4">
                                <Card.Header className="d-flex align-items-center justify-content-between">
                                    <h4 className="mb-0">FAQ List</h4>
                                </Card.Header>
                                <Card.Body>
                                    {settingsData.data && settingsData.data.map((faq, index) => (
                                        <div key={index} className="mb-3">
                                            <h5>{faq.question}</h5>
                                            <p>{faq.answer}</p>
                                        </div>
                                    ))}
                                </Card.Body>
                            </Card>
                        </Col>
                        {/* End of List of FAQs */}
                    </Row>
                </Col>
            </Row>
        </Fragment>
    );
};

export default FAQSetting;
