// import node module libraries
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row, Container, Card, Dropdown } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { useFetchData } from 'hooks/useFetchData';
import { useSelector } from 'react-redux';
// import MDI icons

// import custom components
import ServeContent from './components/common/ServeContent';
import CourseItemList from './components/common/CourseItemList';
// import socket from 'helper/socket';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu

export const CourseLearning = () => {
    const { slug } = useParams();
	const { data } = useFetchData('/course/'+slug);
	const [YouTubeURL, setYoutubeUrl] = useState(null);

    // useEffect(() => {
    //     const connectWebSocket = () => {
    //        if(!user || user === undefined) return;

	// 		if(user?.id) {
	// 			// Authenticate the user with their userId
	// 			socket.emit('authenticate', user?.id);
	// 			console.log('Socket emit authenticate with userId:', user?.id);
	// 		}

    //         // Listen for any connection error
    //         socket.on('connect_error', (err) => {
    //             console.error('Connection error:', err.message);
    //         });

    //         // Listen for the otpVerified event
    //         socket.on('lessonCompleted', (userId, message) => {
    //             console.log(`lessonCompleted event received, user ${userId}`, message);
	// 		 	refreshData();
	// 			notifySuccess("Lesson Video Completed Successfully!");
    //         });

    //         // Listen for disconnection
    //         socket.on('disconnect', () => {
    //             console.log('Disconnected from WebSocket server');
    //         });
    //     };

    //     connectWebSocket();

    //     // Clean up on component unmount
    //     return () => {
    //         socket.off('lessonCompleted');
    //         socket.off('connect');
    //         socket.off('disconnect');
    //         socket.off('connect_error');
    //     };
    // }, [user?.id]);

	return (
			<Fragment>
				<main>
					<section className="mt-6 course-container">
						<Container fluid>
							<Row>
								<Col sm={12} md={12} lg={12}>
									{/*  Tab content  */}
									<div className="content">
										{/*  Video */}
										<div className="d-flex align-items-center justify-content-between mb-4">
											<div>
												<h3 className=" mb-0  text-truncate-line-2">
													{data?.data && data?.data.title}
												</h3>
											</div>
											<div className="d-flex justify-content-between">
												<Dropdown className="video-info-icon me-2">
													<Dropdown.Toggle
														bsPrefix=" "
														as="a"
														href="#"
														variant="secondary"
														id="dropdown-basic"
													>
														<i className="fe fe-help-circle text-secondary"></i>
													</Dropdown.Toggle>
													<Dropdown.Menu
														className="p-3"
														style={{ width: '300px' }}
													>
														<span dangerouslySetInnerHTML={{__html: data?.data && data?.data.description}}>
														</span>
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</div>
										<div
											className="embed-responsive position-relative w-100 d-block overflow-hidden p-0"
											style={{ height: '600px' }}
										>
											<ServeContent value={YouTubeURL && YouTubeURL} />
										</div>
									</div>
								</Col>
							</Row>
						</Container>
					</section>
					{/*  Card */}
					<section className="card course-sidebar " id="courseAccordion">
						<SimpleBar style={{ maxHeight: '80vh' }}>
							<Card>
								<Card.Header>
									<h4 className="mb-0">Table of Content</h4>
								</Card.Header>

								{/* Course Index Accordion */}
								<CourseItemList 
									accordionItems={data?.data && data?.data} 
									setValue={setYoutubeUrl} 
								/>
							</Card>
						</SimpleBar>
					</section>
				</main>
			</Fragment>
		);
	};

export default CourseLearning;
