// import node module libraries
import React, { useState, Fragment, useEffect } from 'react';

// import custom components
import GKStepper from 'components/elements/stepper/GKStepper';
// import sub components ( Steps )
import BasicInformation from './steps/UpdateBasicInformation';
import CoursesMedia from './steps/UpdateCoursesMedia';
import Curriculum from './steps/UpdateCurriculum';
import CoursePrice from './steps/UpdateCoursePrices';
import { useFetchData } from 'hooks/useFetchData';
import { useParams } from 'react-router-dom';

const UpdateExistingCourse = () => {

	const [currentStep, setCurrentStep] = useState(1);
	const { slug } = useParams();
	const { data, refreshData } = useFetchData('/course/'+slug);

	const updateFormData = () => {
		refreshData();
	}

	const formUpdateData = {
		step1: {
		  title: '',
		  category: [],
		  description: '',
		  level: '',
		  subscription: '',
		  discount: '',
		  duration: '',
		  instructor: ''
		},
		step2: [{ currency: '', symbol: '', amount: '', name: '' }],
		step3: {
		  url: '',
		},
		step4: {
			modules: [
				{
				  title: '',
				  description: '',
				  order: '',
				  courseId: '',
				  lessons: [],
				},
			]
		},
	}

	useEffect(() => {
		setFormData(formUpdateData);
		localStorage.removeItem('updateFormDataKey');
	}, [slug]);

	const [formData, setFormData] = useState(() => {
		const storedFormData = localStorage.getItem('updateFormDataKey');
    	return storedFormData ? JSON.parse(storedFormData) : formUpdateData;
	});
	
	useEffect(() => {
		if(data?.data) {
			// Set initial state from server data
			const initialFormData = {
				step1: {
					id: data.data?.id || '',
					title: data.data?.title || '',
					category: data.data?.categories || [],
					description: data.data?.description || '',
					level: data.data?.level || '',
					subscription: data.data?.isSubscription || '',
					discount: data.data?.discount || '',
					duration: data.data?.duration || '',
					instructor: data.data?.instructorId || ''
				},
				step2:( data.data?.prices || []).map((price, index) => { 
					return {
						id: price.id,
						currency: price.currencyId, 
						amount: price.amount,
						symbol: price.symbol,
						name: price.name
					}
				}),
				step3: {
					url: data.data?.image || '',
				},
				step4: {
					modules: (data.data?.modules || []).map((module, index) => {
						return {
							id: module.id,
							title: module.title,
							description: module.description,
							courseId: module.courseId,
							order: module.order,
							lessons: module.lessons.map((lesson, i) => {
								return {
									id: lesson.id,
									title: lesson.title,
									description: lesson.description,
									order: lesson.order,
									isLocked: lesson.isUnLocked,
									content: {
										type: lesson.content.type,
										data: lesson.content,
									},
								}
							})
						}
					})
				},
			};

			setFormData(initialFormData);
		 }

	}, [data]);
		
	// useEffect to update local storage whenever formData or currentStep changes
	useEffect(() => {
		localStorage.setItem(
		  'updateFormDataKey',
		  JSON.stringify({ formData, currentStep })
		);
	}, [formData, currentStep]);
 
	// Function to handle form data changes
	const handleInputChange = (field, value) => {
		setFormData((prevData) => ({
			...prevData,
			[`step${currentStep}`]: {
				...prevData[`step${currentStep}`],
				[field]: value,
			},
		}));
	};

	const handleStep2Change = (index, fieldName, value) => {
		setFormData(prevState => {
		  const newStep2 = [...prevState.step2];
		  newStep2[index] = {
			...newStep2[index],
			[fieldName]: value,
		  };
		  return {
			...prevState,
			step2: newStep2,
		  };
		});
	};

	const handleModuleInputChange = (index, field, value) => {
		setFormData((prevFormData) => {
		  const newModules = [...prevFormData.step4.modules];
		  newModules[index] = {
			...newModules[index],
			[field]: value,
		  };
		  return {
			...prevFormData,
			step4: {
			  ...prevFormData.step4,
			  modules: newModules,
			},
		  };
		});
	  };

	const next = () => {
		setCurrentStep(currentStep === 5 ? 1 : currentStep + 1);
	};
	const previous = () => {
		setCurrentStep(currentStep === 1 ? 1 : currentStep - 1);
	};

	const steps = [
		{
			id: 1,
			title: 'Course Information',
			content: (
				<BasicInformation
					formData={formData}
					setFormData={setFormData}
					handleInputChange={handleInputChange}
					next={next}
				/>
			)
		},
		{
			id: 2,
			title: 'Course Price',
			content: (
				<CoursePrice
					formData={formData}
					setFormData={setFormData}
					handleInputChange={handleStep2Change}
					next={next}
					previous={previous}
				/>
			)
		},
		{
			id: 3,
			title: 'Courses Media',
			content: (
				<CoursesMedia
					formData={formData}
					setFormData={setFormData}
					handleInputChange={handleInputChange}
					next={next}
					previous={previous}
				/>
			)
		},
		{
			id: 4,
			title: 'Curriculum',
			content: (
				<Curriculum
					formData={formData}
					handleInputChange={handleModuleInputChange}
					setFormData={setFormData}
					next={next}
					previous={previous}
					updateFormData={updateFormData}
				/>
			)
		},
	];

	return (
		<Fragment>
			<GKStepper currentStep={currentStep} steps={steps} />
		</Fragment>
	);
};

export default UpdateExistingCourse;
