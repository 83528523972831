// import media files
import Avatar from 'assets/images/png/IBM.png';

const NotificationData = [
	{
		id: 1,
		image: Avatar,
		name: 'Kristin Watson',
		notification:
			'reply on your comment for Become a Product Manager | Learn the Skills',
		date: '2 hours ago',
		time: '2:19 PM',
		icon: 'ThumbsUp',
		colorClass: 'success',
		removable: true
	},
	{
		id: 2,
		image: Avatar,
		name: 'Brooklyn Simmons',
		notification: 'Just launched a new Courses React for Beginner.',
		date: 'Oct 9',
		time: '1:20 PM',
		icon: 'ThumbsUp',
		colorClass: 'success'
	},
	{
		id: 3,
		image: Avatar,
		name: 'Jadyn Johnson',
		notification:
			'Krisitn Watsan like your comment on course Javascript Introduction!',
		date: 'Oct 9',
		time: '1:59 PM',
		icon: 'ThumbsUp',
		colorClass: 'success'
	},
	{
		id: 4,
		image: Avatar,
		name: 'Sina Ray',
		notification:
			'You earn new certificate for complete the Javascript Beginner course.',
		date: 'Oct 9',
		time: '1:59 PM',
		icon: 'Award',
		colorClass: 'warning'
	},
	{
		id: 5,
		image: Avatar,
		name: 'Nikolas Howe',
		notification:
			'reply on your comment for Become a Product Manager | Learn the Skills',
		date: 'Oct 9',
		time: '2 PM',
		icon: 'MessageSquare',
		colorClass: 'info'
	},
	{
		id: 6,
		image: Avatar,
		name: 'Jadyn Johnson',
		notification: 'reply on your comment for The Web Developer Bootcamp 2020',
		date: 'Oct 9',
		time: '2.10 PM',
		icon: 'MessageSquare',
		colorClass: 'info'
	},
	{
		id: 7,
		image: Avatar,
		name: 'Jenny Wilson',
		notification:
			'reply on your comment for The Complete Front End Developer in 2021: Zero to Mastery',
		date: 'Oct 9',
		time: '2.30 PM',
		icon: 'MessageSquare',
		colorClass: 'info'
	},
	{
		id: 8,
		image: Avatar,
		name: 'Bobbie Koelpin',
		notification:
			'reply on your comment for Ultimate Web Designer & Developer Course | 20 Projects',
		date: 'Oct 9',
		time: '2.59 PM',
		icon: 'MessageSquare',
		colorClass: 'info'
	},
	{
		id: 9,
		image: Avatar,
		name: 'Brendan Hammes',
		notification:
			'your earn new certificate for learn courses Java Script Beginner.',
		date: 'Oct 9',
		time: '3 hours ago',
		icon: 'Award',
		colorClass: 'warning'
	},
	{
		id: 10,
		image: Avatar,
		name: 'Margarita Heathcote',
		notification:
			'ike your comment Integer id mauris vulputate, posuere dui ege course. Javascript Introduction',
		date: 'Oct 9',
		time: '3 hours ago',
		icon: 'Award',
		colorClass: 'warning'
	}
];

export default NotificationData;
