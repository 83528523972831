import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useFetchData } from 'hooks/useFetchData';  // Replace with actual hook import
import debounce from 'lodash.debounce';
import './tag.css';

const EmailTaggingComponent = ({ selectedEmails, setSelectedEmails }) => {
  const [inputValue, setInputValue] = useState('');  // The input field's value
  const [queryValue, setQueryValue] = useState('');  // The value used for debounced API call
  const [isSuggestionsOpen, setIsSuggestionsOpen] = useState(false);  // Controls visibility of suggestions

  const inputRef = useRef(null);  // Reference to the input element
  const containerRef = useRef(null);  // Reference to the main component

  // Debounce function for making API calls without affecting the input typing
  const debouncedSetQueryValue = useCallback(
    debounce((value) => {
      setQueryValue(value);
      setIsSuggestionsOpen(true);  // Open suggestions after debouncing
    }, 500), []);  // 500ms delay for debouncing

  // Update input value instantly and debounce API request
  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);  // Update the input field immediately
    debouncedSetQueryValue(value);  // Trigger the debounced API call
  };

  // Fetch suggestions based on the debounced query value
  const { data: suggestions, loading } = useFetchData(
    `/mail/emails`,
    { query: queryValue },
    {
      enabled: !!queryValue,  // Only fetch when queryValue exists
      staleTime: 0,
      cacheTime: 0,
    }
  );

  // Handle email selection
  const handleEmailSelect = (email) => {
    if (!selectedEmails.some(selected => selected === email)) {
      setSelectedEmails([...selectedEmails, email]);  // Add email object to selected list
    }
    setInputValue('');  // Clear the input after selecting an email
    setIsSuggestionsOpen(false);  // Close suggestions after selection
  };

  // Remove selected email
  const handleEmailRemove = (email) => {
    setSelectedEmails(selectedEmails.filter((e) => e.email !== email.email));  // Remove from selected list
  };

  // Close suggestions when clicking outside the component
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsSuggestionsOpen(false);  // Close the suggestions list
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={containerRef} className="email-tagging-container">
      <div className="email-input-wrapper">
        <label className="email-tagging-label">Email Tagging</label>

        <div className="input-tag-box">
          {selectedEmails.map((email) => (
            <div key={email} className="tag">
              {email}
              <span onClick={() => handleEmailRemove(email)} className="remove-tag">x</span>
            </div>
          ))}

          <input
            ref={inputRef}
            type="text"
            value={inputValue}  // Controlled input
            onChange={handleInputChange}
            placeholder="Type to search for emails..."
            className="email-input"
            onFocus={() => setIsSuggestionsOpen(true)}  // Open suggestions on focus
          />
        </div>
      </div>

      {loading && <div className="loading">Loading suggestions...</div>}

      {/* Render suggestions */}
      {isSuggestionsOpen && suggestions && (
        <div className="suggestions">
          {suggestions?.data?.map((email) => (
            <div
              key={email.email}
              onClick={() => handleEmailSelect(email?.email)}
              className="suggestion-item"
            >
              {`${email.fullName} -> ${email.email}`}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default EmailTaggingComponent;
