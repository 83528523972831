import { Fragment } from 'react';
import { Link } from 'react-router-dom';
// import custom components
import { Avatar } from 'components/elements/bootstrap/Avatar';
import GKTippy from 'components/elements/tooltips/GKTippy';
// import MDI icons
import Icon from '@mdi/react';
import { mdiStarOutline, mdiReplyOutline, mdiAttachment } from '@mdi/js';

const MailDetailsBody = ({ data }) => {

	return (
		<Fragment>
			<div className="d-xl-flex justify-content-between align-items-center">
				<div className="d-flex align-items-center mb-3 mb-xl-0">
					<div>
						<Avatar
							size="md"
							src={`${process.env.REACT_APP_ASSET_BASE_URL}/${data?.avatar}`}
							type="image"
							className="rounded-circle"
						/>
					</div>
					<div className="ms-3 lh-1">
						<h5 className="mb-1">{data?.title}</h5>
						<p className="mb-0 fs-6">{data?.email}</p>
					</div>
				</div>
				<div className="d-flex align-items-center">
					<div>
						<small className="text-muted">
							{data?.date}
						</small>
					</div>
					<div className="ms-2">
						<GKTippy content="Star">
							<Link to="#">
								<Icon
									path={mdiStarOutline}
									className="text-muted me-2"
									size={0.7}
								/>
							</Link>
						</GKTippy>
						<GKTippy content="Reply">
							<Link to="#">
								<Icon
									path={mdiReplyOutline}
									className="text-muted me-2"
									size={0.7}
								/>
							</Link>
						</GKTippy>
					</div>
				</div>
			</div>
			<div className="mt-6">
				<p className="mb-3 text-dark" dangerouslySetInnerHTML={{__html: data?.description}}></p>

				{data?.attachments.length > 0 && data?.attachments?.map((attachment, index) =>  <div className="border-top py-4 mt-6">
					<p>
						<Icon
							path={mdiAttachment}
							className="me-2 align-middle"
							size={0.7}
						/>{' '}
						{data?.attachments.length} Attachments
					</p>
					<div className="d-flex">
						<div className="d-flex align-items-center ms-6">
							<div className="icon-shape icon-md bg-danger text-white rounded">
								<small>{attachment?.mime_type}</small>
							</div>
							<div className="ms-2">
								<p className="mb-0 fs-6">{attachment?.file_name}</p>
								<p className="fs-6 mb-0">{attachment?.file_size}</p>
							</div>
						</div>
					</div>
				</div>)}
			</div>
		</Fragment>
	);
};
export default MailDetailsBody;
