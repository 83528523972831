// import node module libraries
import React, { useEffect } from 'react';
import SimpleBar from 'simplebar-react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ListGroup } from 'react-bootstrap';
// import custom components
import { Avatar } from 'components/elements/bootstrap/Avatar';
import { useFetchData } from 'hooks/useFetchData';
import socket from 'helper/socket';
import { useSelector } from 'react-redux';

const RecentChatList = ({setHideChatBox, setGroupId, isReload}) => {
	const navigate = useNavigate();
	const { user } = useSelector(state => state.auth);
	const BASE_URL = process.env.REACT_APP_ASSET_BASE_URL;
	const location = useLocation();

	const handleOpenChatThread = (group) => {
		setHideChatBox(true);
		setGroupId(group?.id)
		navigate(`/dashboard/discussion/group/${group.id}`, {state: {groupId: group?.id}});
	};

	const { data, refreshData } = useFetchData('/group/list');

	useEffect(() => {
		const connectWebSocket = () => {
			socket.on('connect', () => {
				console.log('Connected to WebSocket server');
			});
			
			if(data) {
				// check if user is authenticated
				if(user) {
					// check if user is authenticated
					socket.emit('authenticate', user?.id);
					data?.data?.data.map((group, index) => {
						if(group?.id) {
							// check if user belongs to a group
							socket.emit('joinGroup', user?.id, group?.id);
						}
					})
				}
			}

			socket.on('connect_error', (err) => {
				console.error('Connection error: ', err.message);
			});
			
			socket.on('disconnect', () => {
				console.log('Disconnected from WebSocket server');
			});
		};

		connectWebSocket(); // 

		return () => {
			socket.off('authenticate');
			socket.off('joinGroup');
			socket.off('connect');
			socket.off('disconnect');
			socket.off('connect_error');
		};
	}, [data]);

	useEffect(() => {
		if(isReload) {
			refreshData();
		}
	}, [isReload])

	return (
		<SimpleBar style={{ maxHeight: '650px' }}>
			<ListGroup bsPrefix="list-unstyled" as="ul" className="contacts-list">
				{data?.data?.data.map((group, index) => 
						(
							<ListGroup.Item
								as="li"
								bsPrefix=" "
								key={group.id}
								role="button"
								className={`py-3 px-4 chat-item contacts-item ${
									location.pathname.startsWith(`/dashboard/discussion/group/${group.id}`) ? 'bg-light' : ''
								}`}
							>
								
									<div
										className="d-flex justify-content-between align-items-center"
										onClick={() => handleOpenChatThread(group)}
									>
										<Link to="#" className="text-link contacts-link">
											<div className="d-flex">
												<Avatar
													size="md"
													className="rounded-circle"
													type={group?.icon ? 'image' : 'initial'}
													src={`${BASE_URL}/${group?.icon}`}
													status={group?.status}
													alt={group?.title}
													name={group?.title}
												/>
												<div className=" ms-2">
													<h5 className="mb-0 fw-bold"> {group?.title}</h5>
													<p
														className="mb-0 text-muted text-truncate"
														style={{ maxWidth: '145px' }}
													>
														{group?.lastMessage}
													</p>
												</div>
											</div>
										</Link>
										<div>
											<small className="text-muted">
												{group?.date}
											</small>
											{group.isRead ? null : (
												<div className="text-end">
													<span className="icon-shape icon-xs text-white bg-danger rounded-circle fw-bold fs-6">
														1
													</span>
												</div>
											)}
										</div>
									</div>
							</ListGroup.Item>
						)
					)}
			</ListGroup>
		</SimpleBar>
	);
};
export default RecentChatList;
