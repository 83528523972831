// import node module libraries
import { Fragment } from 'react';
import { Col, Row, Breadcrumb } from 'react-bootstrap';

// import sub components
import { useSelector } from 'react-redux';
import { useServerFilter } from 'hooks/useServerFilter';
import axios from 'axios';
import EnrollTable from './EnrollTable';
import { useFetchData } from 'hooks/useFetchData';

const EnrollEmployees = () => {
	const BASE_URL = process.env.REACT_APP_API_BASE_URL;
	const { user } = useSelector(state => state.auth);
	const authToken = user && user?.token;
	const { data: purchasedCourse } = useFetchData("/business/courses/purchased");

	const fetchUsers = async (searchQuery, pageNumber) => {
		if (authToken !== null) {
			axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
			const response = await axios.get(`${BASE_URL}/business/employees?page=${pageNumber}&searchQuery=${searchQuery}`);
			return response.data;
		}
	};

	const {
		filteredData,
		searchQuery,
		pageNumber,
		handlePageChange, 
		handleSearchChange
	} = useServerFilter('', fetchUsers);

	const lastPage = filteredData && filteredData?.data && filteredData?.data?.pagination?.lastPage;

	return (
		<Fragment>
			<Row>
				<Col lg={12} md={12} sm={12}>
					<div className="border-bottom pb-4 mb-4 d-flex align-items-center justify-content-between">
						<div className="mb-3 mb-md-0">
							<h1 className="mb-1 h2 fw-bold">
								Enroll Employee <span className="fs-5 text-muted"></span>
							</h1>
							<Breadcrumb>
								<Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
								<Breadcrumb.Item href="#">User</Breadcrumb.Item>
								<Breadcrumb.Item active>Enroll Employee</Breadcrumb.Item>
							</Breadcrumb>
						</div>
					</div>
				</Col>
			</Row>
            <Row>
                <Col lg={12} md={12} sm={12}>
                    <EnrollTable
                        userData={filteredData && filteredData}
						courseData={purchasedCourse && purchasedCourse?.data}
                        lastPage={lastPage}
                        currentPage={pageNumber}
                        handlePageChange={handlePageChange}
                        searchQuery={searchQuery}
                        handleSearchChange={handleSearchChange}
                    />
                </Col>
            </Row>
		</Fragment>
	);
};
export default EnrollEmployees;
