// import node module libraries
import { Fragment, useEffect, useState } from 'react';
import { Col, Row, Breadcrumb, Card, Form, Image } from 'react-bootstrap';

// import custom components
import { useFetchData } from 'hooks/useFetchData';
import { useFileUpload } from 'hooks/useFileUpload';
import { notifyError, notifySuccess } from 'helper/utils';
import AileadButton from 'components/marketing/common/button/AileadButton';

const SiteTeamMember = () => {

	const { selectedFile, serverResponse, uploadError, isUploading, handleFileChange, handleUpload } = useFileUpload('/site/team/add');
	const { data: settingsData, refreshData} = useFetchData('/site/team/list');

    const [teamName, setTeamName] = useState("");
    const [teamProfession, setTeamProfession] = useState("");

	const BASE_URL = process.env.REACT_APP_ASSET_BASE_URL;

	const updateSettings = () => {
		handleUpload({
			fullName: teamName,
			profession: teamProfession,
		});
	}

	useEffect(() => {
		if(serverResponse?.status === "Success"){
			refreshData();
			setTeamName("");
			setTeamProfession("");
			notifySuccess("Settings Successfully updated!");
		}

		if(uploadError){
			notifyError(uploadError);
		}
		
	}, [serverResponse, uploadError])
 
	return (
		<Fragment>
			<Row>
				<Col xl={{ offset: 3, span: 6 }} lg={{ offset: 2, span: 8 }} md={12}>
					<Row>
						<Col lg={12} md={12} sm={12}>
							<div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
								<div className="mb-3 mb-md-0">
									<h1 className="mb-1 h2 font-weight-bold">About Us Team</h1>
									<Breadcrumb>
										<Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
										<Breadcrumb.Item href="#">Settings</Breadcrumb.Item>
										<Breadcrumb.Item active>About Us Team</Breadcrumb.Item>
									</Breadcrumb>
								</div>
							</div>
						</Col>
					</Row>

					<Row>
						{/* general  settings */}
						<Col xl={12} lg={12} md={12}>
							<Card className="mb-4">
								<Card.Header className="d-flex align-items-center justify-content-between">
									<h4 className="mb-0">About Us Teams Members</h4>
								</Card.Header>
								<Card.Body>
									<Form>
										<Row>
											<Col lg={12} md={12} className="mb-3">
												{/* Site Name */}
												<Form.Label>
													Upload Team Photo{' '}
												</Form.Label>
												<Form.Group className="mb-1 input-group">
													<Form.Control
														type="file"
														onChange={handleFileChange}
													/>
													{uploadError && uploadError.image && (
														<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
															{uploadError.image[0]}
														</Form.Text>
													)}
												</Form.Group>
											</Col>
											<Col lg={12} md={12} className="mb-3">
												{/* Site Description */}
												<Form.Label>
													Team Full Name{' '}
												</Form.Label>
												<Form.Control
													type="text"
													placeholder="Team Full Name"
													value={teamName}
													onChange={(e) => setTeamName(e.target.value)}
												/>
												{uploadError && uploadError.fullName && (
														<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
															{uploadError.fullName[0]}
														</Form.Text>
													)}
											</Col>
                                            <Col lg={12} md={12} className="mb-3">
												{/* Site Description */}
												<Form.Label>
													Team Profession{' '}
												</Form.Label>
												<Form.Control
													type="text"
													placeholder="Team Profession"
													value={teamProfession}
													onChange={(e) => setTeamProfession(e.target.value)}
												/>
												{uploadError && uploadError?.profession && (
														<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
															{uploadError.profession[0]}
														</Form.Text>
													)}
											</Col>
											
										</Row>
										
										<Row>
											<Col lg={12} md={12}>
                                                <AileadButton type="button" variant="primary" text="Add Team"  onClick={updateSettings} loading={isUploading} disabled={!selectedFile} />
											</Col>
										</Row>
									</Form>
								</Card.Body>
							</Card>

							{/* List of FAQs */}
							<Col xl={12} lg={12} md={12}>
								<Card className="mb-4">
									<Card.Header className="d-flex align-items-center justify-content-between">
										<h4 className="mb-0">Team List</h4>
									</Card.Header>
									<Card.Body>
										{settingsData.data && settingsData?.data?.map((team, index) => (
											<Row key={team.id}>
												<Col xl={4} lg={4} md={4}>
													<Image src={`${BASE_URL}/${team.image}`} className="rounded-circle avatar-xxl mb-3 flex-auto" />
												</Col>	
												<Col xl={4} lg={4} md={4}>
													<p>{team.fullName}</p>
												</Col>
												<Col xl={4} lg={4} md={4}>
													<p>{team.profession}</p>
												</Col>
											</Row>
										))}
									</Card.Body>
								</Card>
							</Col>
							{/* End of List of FAQs */}
						</Col>
					</Row>
				</Col>
			</Row>
		</Fragment>
	);
};

export default SiteTeamMember;

