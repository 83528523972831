import { Fragment, useEffect, useState } from 'react';
import { Col, Row, Breadcrumb, Card, Form, Button } from 'react-bootstrap';
import { useFetchData } from 'hooks/useFetchData';
import { usePostData } from 'hooks/usePostData';
import { notifyError, notifySuccess } from 'helper/utils';
import AileadButton from 'components/marketing/common/button/AileadButton';
import CategorySelector from 'components/elements/category/CategorySelector';
import RichTextEditor from 'components/elements/new-editor/RichTextEditor';

const Site = () => {
  const { postData, loading, error, response } = usePostData('/setting/update');
  const { data: settings } = useFetchData('/settings');
  const { data: categories } = useFetchData('/category/categories');

  const [courseEnrollment, setCourseEnrollment] = useState('');
  const [instructors, setInstructors] = useState('');
  const [courseLanguage, setCourseLanguage] = useState('');
  const [courseOnlineVideos, setCourseOnlineVideos] = useState('');
  const [titleHeader, setTitleHeader] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [privacyPolicy, setPrivacyPolicy] = useState('');
  const [termOfUse, setTermOfUse] = useState('');
  const [trialTitle, setTrialTitle] = useState('');
  const [trialDescription, setTrialDescription] = useState('');
  const [subscriptionTitle, setSubscriptionTitle] = useState('');
  const [subscriptionDescription, setSubscriptionDescription] = useState('');
  const [popularCategories, setPopularCategories] = useState([]);

  const updateSettings = () => {
    postData({
      site_course_enrollment_counter: courseEnrollment,
      site_course_instructor_counter: instructors,
      site_course_langauage_counter: courseLanguage,
      site_course_online_video_counter: courseOnlineVideos,
      site_home_carousel_title: titleHeader,
      site_home_carousel_sub_title: subtitle,
      site_privacy_policy: privacyPolicy,
      site_term_of_use: termOfUse,
      site_trial_title: trialTitle,
      site_trial_description: trialDescription,
      site_subscription_title: subscriptionTitle,
      site_subscription_description: subscriptionDescription,
      site_popular_category: JSON.stringify(popularCategories),
    });
  };

  useEffect(() => {
    if (settings?.data) {
      const siteSettings = settings.data.reduce((acc, curr) => {
        acc[curr.key] = curr.value;
        return acc;
      }, {});

      setCourseEnrollment(siteSettings.site_course_enrollment_counter || '');
      setInstructors(siteSettings.site_course_instructor_counter || '');
      setCourseLanguage(siteSettings.site_course_langauage_counter || '');
      setCourseOnlineVideos(siteSettings.site_course_online_video_counter || '');
      setTitleHeader(siteSettings.site_home_carousel_title || '');
      setSubtitle(siteSettings.site_home_carousel_sub_title || '');
      setPrivacyPolicy(siteSettings.site_privacy_policy || '');
      setTermOfUse(siteSettings.site_term_of_use || '');
      setTrialTitle(siteSettings.site_trial_title || '');
      setTrialDescription(siteSettings.site_trial_description || '');
      setSubscriptionTitle(siteSettings.site_subscription_title || '');
      setSubscriptionDescription(siteSettings.site_subscription_description || '');

      try {
        const popularCategories = siteSettings.site_popular_category ? JSON.parse(siteSettings.site_popular_category) : [];
        setPopularCategories(Array.isArray(popularCategories) ? popularCategories : []);
      } catch (error) {
        console.error('Error parsing popular categories JSON:', error);
        setPopularCategories([]);
      }
    }
  }, [settings?.data]);

  useEffect(() => {
    if (response?.data !== null && response?.status) {
      notifySuccess('Settings successfully updated!');
    }
    if (error) {
      notifyError(error);
    }
  }, [response?.data, error]);

  return (
    <Fragment>
      <Row>
        <Col xl={{ offset: 3, span: 6 }} lg={{ offset: 2, span: 8 }} md={12}>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
                <div className="mb-3 mb-md-0">
                  <h1 className="mb-1 h2 font-weight-bold">Public Setting</h1>
                  <Breadcrumb>
                    <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
                    <Breadcrumb.Item href="#">Settings</Breadcrumb.Item>
                    <Breadcrumb.Item active>Public Site Setting</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={12} lg={12} md={12}>
              <Card className="mb-4">
                <Card.Header className="d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Public Site Settings</h4>
                </Card.Header>
                <Card.Body>
                  <Form>
                    <Row>
                      <Col lg={12} md={12} className="mb-3">
                        <Form.Label>Course Enrollment Counter</Form.Label>
                        <Form.Control
                          type="text"
                          value={courseEnrollment}
                          onChange={(e) => setCourseEnrollment(e.target.value)}
                        />
                      </Col>
                      <Col lg={12} md={12} className="mb-3">
                        <Form.Label>Course Instructors Counter</Form.Label>
                        <Form.Control
                          type="text"
                          value={instructors}
                          onChange={(e) => setInstructors(e.target.value)}
                        />
                      </Col>
                      <Col lg={12} md={12} className="mb-3">
                        <Form.Label>Course Language Counter</Form.Label>
                        <Form.Control
                          type="text"
                          value={courseLanguage}
                          onChange={(e) => setCourseLanguage(e.target.value)}
                        />
                      </Col>
                      <Col lg={12} md={12} className="mb-3">
                        <Form.Label>Course Online Videos Counter</Form.Label>
                        <Form.Control
                          type="text"
                          value={courseOnlineVideos}
                          onChange={(e) => setCourseOnlineVideos(e.target.value)}
                        />
                      </Col>
                      {categories.data && (
                        <CategorySelector
                          categories={categories.data.data}
                          selectedCategories={popularCategories}
                          setSelectedCategories={setPopularCategories}
                        />
                      )}
                      <Col lg={12} md={12} className="mb-3">
                        <Form.Label>Carousel Title</Form.Label>
                        <Form.Control
                          type="text"
                          value={titleHeader}
                          onChange={(e) => setTitleHeader(e.target.value)}
                        />
                      </Col>
                      <Col lg={12} md={12} className="mb-3">
                        <Form.Label>Carousel Subtitle</Form.Label>
                        <Form.Control
                          type="text"
                          value={subtitle}
                          onChange={(e) => setSubtitle(e.target.value)}
                        />
                      </Col>
                      <Col lg={12} md={12} className="mb-3">
                        <Form.Label>Privacy Policy</Form.Label>
                        <RichTextEditor
                          editorValue={privacyPolicy}
                          onChange={(event, editor) => {
                            const newData = editor.getData();
                            setPrivacyPolicy(newData);
                          }}
                        />
                      </Col>
                      <Col lg={12} md={12} className="mb-3">
                        <Form.Label>Term of Use</Form.Label>
                        <RichTextEditor
                          editorValue={termOfUse}
                          onChange={(event, editor) => {
                            const newData = editor.getData();
                            setTermOfUse(newData);
                          }}
                        />
                      </Col>
                      <Col lg={12} md={12} className="mb-3">
                        <Form.Label>Trial Title</Form.Label>
                        <Form.Control
                          type="text"
                          value={trialTitle}
                          onChange={(e) => setTrialTitle(e.target.value)}
                        />
                      </Col>
                      <Col lg={12} md={12} className="mb-3">
                        <Form.Label>Trial Description</Form.Label>
                        <RichTextEditor
                          editorValue={trialDescription}
                          onChange={(event, editor) => {
                            const newData = editor.getData();
                            setTrialDescription(newData);
                          }}
                        />
                      </Col>
                      <Col lg={12} md={12} className="mb-3">
                        <Form.Label>Subscription Title</Form.Label>
                        <Form.Control
                          type="text"
                          value={subscriptionTitle}
                          onChange={(e) => setSubscriptionTitle(e.target.value)}
                        />
                      </Col>
                      <Col lg={12} md={12} className="mb-3">
                        <Form.Label>Subscription Description</Form.Label>
                        <RichTextEditor
                          editorValue={subscriptionDescription}
                          onChange={(event, editor) => {
                            const newData = editor.getData();
                            setSubscriptionDescription(newData);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12} md={12}>
                        <AileadButton type="button" variant="primary" text="Update Settings" onClick={updateSettings} loading={loading} />
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Site;
