// import node module libraries
import { useState, useEffect } from 'react';
import { Col, Row, Modal, Form} from 'react-bootstrap';
import useUserTimezone from 'hooks/useUserTimezone';
import { useFetchData } from 'hooks/useFetchData';
import { useNavigate, Link } from 'react-router-dom';
import AileadButton from 'components/marketing/common/button/AileadButton';
// import custom components
import { usePostData } from 'hooks/usePostData';
import { notifyError, notifySuccess } from 'helper/utils';

const AddNewEmployee = ({show, handleClose}) => {

    const [ firstName, setFirstName ] = useState('');
	const [ lastName, setLastName ] = useState('');
	const [ mobile, setMobile ] = useState('');
	const [ gender, setGender ] = useState('');
	const [ country, setCountry ] = useState('');
	const [ email, setEmail ] = useState('');
	const [ password, setPassword ] = useState('');
	const [ passwordConfiration, setPasswordConfiration ] = useState('');
	const { postData, error, loading, response } = usePostData('/create/employee');
	const { userTimezone } = useUserTimezone();
	const { data } = useFetchData("/countries/list");
	const Gender = [{id: 1, name: "Male"}, {id: 2, name: "Female"}];
	const navigate = useNavigate();
	
	const handleFormSubmit = (e) => {
		e.preventDefault();
		postData({
		  firstName, 
		  lastName, 
		  mobile, 
		  gender, 
		  country, 
		  email,
		  role: "employee", 
		  password, 
		  timezone: userTimezone,
          businessId: true,
		  "password_confirmation": passwordConfiration
		});
	};

    useEffect(() => {
        if(response !== null && response?.status === "Success") {
            navigate('/dashboard/business/employees');
			handleClose()
            notifySuccess("Employee Created Successfully!");
        } else {
			handleClose();
            notifyError(error);
        }
      }, [response])
 
	return (
		<Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg">
			<Modal.Header closeButton>
				<Modal.Title>Create Employee</Modal.Title>
			</Modal.Header>
			<Modal.Body className="pb-4">
                <Row>
					<Col lg={12} md={12} className="mb-3">
						{/* User Name */}
						<Form.Label>First Name</Form.Label>
						<Form.Control
							type="text"
							id="firstname"
							placeholder="First Name"
							required
							value={firstName}
							onChange={(e) => setFirstName(e.target.value)}
						/>
						{error && error?.firstName && 
							(
								<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
									{error.firstName[0]}
								</Form.Text>
							)
						}
					</Col>
					<Col lg={12} md={12} className="mb-3">
						{/* last Name */}
						<Form.Label>Last Name</Form.Label>
						<Form.Control
							type="text"
							id="Lastname"
							placeholder="Last Name"
							required
							value={lastName}
							onChange={(e) => setLastName(e.target.value)}
						/>
						{error && error?.lastName && 
							(
								<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
									{error.lastName[0]}
								</Form.Text>
							)
						}
					</Col>
					<Col lg={12} md={12} className="mb-3">
						{/* User Name */}
						<Form.Label>Mobile</Form.Label>
						<Form.Control
							type="text"
							id="mobile"
							placeholder="Mobile"
							required
							value={mobile}
							onChange={(e) => setMobile(e.target.value)}
						/>
						{error && error?.mobile && 
							(
								<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
									{error.mobile[0]}
								</Form.Text>
							)
						}
					</Col>
					<Col lg={12} md={12} className="mb-3">
						{/* User Name */}
						<Form.Label>Gender</Form.Label>
						<Form.Select 
							placeholder='Gender' 
							value={gender}
							onChange={(e) => setGender(e.target.value)}
						>
							<option value=""></option>
							{Gender.map((item) => (
								<option key={item.id} value={item.name}>
									{item.name}
								</option>
							))}
						</Form.Select>
						{error && error?.gender && 
							(
								<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
									{error.gender[0]}
								</Form.Text>
							)
						}
					</Col>
						<Col lg={12} md={12} className="mb-3">
							<Form.Label>Country</Form.Label>
							<Form.Select 
								placeholder='Select Currency' 
								value={country}
								onChange={(e) => setCountry(e.target.value)}
							>
                                <option value=""></option>
                                {data?.data && data?.data.map((item) => (
                                    <option key={item.id} value={item.name}>
                                        {item.name}
                                    </option>
                                ))}
						    </Form.Select>
							{error && error?.country &&
								(
									<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
										{error.country[0]}
									</Form.Text>
								)
							}
					</Col>
					<Col lg={12} md={12} className="mb-3">
							{/* email */}
							<Form.Label>Email </Form.Label>
							<Form.Control
								type="email"
								id="email"
								placeholder="Email address here"
								required
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
							{error && error?.email && 
								(
									<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
										{error.email[0]}
									</Form.Text>
								)
							}
						</Col>
						<Col lg={12} md={12} className="mb-3">
							{/* Password */}
							<Form.Label>Password </Form.Label>
							<Form.Control
								type="password"
								id="password"
								placeholder="**************"
								required
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
							{
								error && error?.password && 
									(
										<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
											{error.password[0]}
										</Form.Text>
									)
							}
						</Col>
						<Col lg={12} md={12} className="mb-3">
							{/* Password */}
							<Form.Label>Retype Password </Form.Label>
							<Form.Control
								type="password"
								id="confirmpassword"
								placeholder="**************"
								required
								value={passwordConfiration}
								onChange={(e) => setPasswordConfiration(e.target.value)}
							/>
						</Col>
						<Col lg={12} md={12} className="mb-3">
							{/* Checkbox */}
							<Form.Check type="checkbox" id="check-api-checkbox">
								<Form.Check.Input type="checkbox" />
								<Form.Check.Label>
									I agree to the
									<Link to="/pages/terms-and-conditions">
										Terms of Service{' '}
									</Link>{' '}
									and{' '}
									<Link to="/pages/terms-and-conditions">
										Privacy Policy.
									</Link>
								</Form.Check.Label>
							</Form.Check>
						</Col>
					<Col lg={12} md={12} className="mb-0 d-grid gap-2">
						<AileadButton variant="primary" type="button" loading={loading} text="Sign in" onClick={handleFormSubmit} />
					</Col>
				</Row>
			</Modal.Body>
		</Modal>
	);
};

export default AddNewEmployee;
