// actions/authActions.js
export const login = (user) => {
    
    // Save user data to localStorage
    localStorage.setItem('user', JSON.stringify(user));

    return {
      type: 'LOGIN',
      payload: user,
    };
};

export const updatedProfile = (updatedUserProfile) => {
  // Merge the updated fields with the existing user profile
  const updatedUser = {
    ...JSON.parse(localStorage.getItem('user')), // Get the existing user profile from localStorage
    ...updatedUserProfile, // Merge updated fields
  };

  // Save the updated user data to localStorage
  localStorage.setItem('user', JSON.stringify(updatedUser));

  return {
    type: 'UPDATE_PROFILE',
    payload: updatedUser,
  };
};
  
export const logout = () => {
    return {
      type: 'LOGOUT',
    };
};
