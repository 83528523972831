import { Outlet, Navigate } from 'react-router-dom';

// Custom ProtectedRoute component
const ProtectedRoute = ({
  isLogin,
  redirectPath = '/authentication/sign-in',
  children,
}) => {
  
  if (!isLogin) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
