// import node module libraries
import { Fragment, useState } from 'react';
import { Button } from 'react-bootstrap';

// import MDI icons
import Icon from '@mdi/react';
import {
	mdiReplyOutline,
} from '@mdi/js';
import { useLocation } from 'react-router-dom';
import { EmailSendModal } from '../EmailSendModal';

const MailDetailsFooter = ({mail, refresh}) => {
	const [modalShow, setModalShow] = useState(false);
	const location = useLocation();

	return (
		<Fragment>
			{!location.pathname.startsWith("/dashboard/mail/sent") &&
			<>
				<Button
					variant="outline-secondary"
					size="sm"
					className="fs-5 me-2 mb-2 mb-md-0"
					onClick={() => setModalShow(true)}
				>
					<Icon path={mdiReplyOutline} className="me-2" size={0.6} />
					Reply
				</Button>
				<EmailSendModal show={modalShow} onHide={() => setModalShow(false)} refresh={refresh} mail={mail} />
			</>}		
		</Fragment>
	);
};
export default MailDetailsFooter;
