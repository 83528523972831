import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row, Table, Image, Form, Button, Modal } from 'react-bootstrap';
import NewPagination from 'components/elements/advance-table/NewPagination';
import { usePostData } from 'hooks/usePostData';
import { notifySuccess } from 'helper/utils';
import AileadButton from 'components/marketing/common/button/AileadButton';

const EnrollTable = ({ 
    userData,
    courseData,
    handlePageChange,
    currentPage,
    lastPage,
    searchValue,
    onSearchChange
}) => {
    const [selectedUsers, setSelectedUsers] = useState({});
    const [selectedCourses, setSelectedCourses] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const { loading, response, postData } = usePostData("/business/enroll/employee");

    useEffect(() => {
        if(response && response?.status === "Success") {
            notifySuccess("You have successfully enrolled your employee");
        }
    }, [response])

    const handleCheckboxChange = (userId) => {
        setSelectedUsers(prevState => ({
            ...prevState,
            [userId]: !prevState[userId] // Toggle selection
        }));
    };

    const handleSelectAll = () => {
        setSelectAll(prevSelectAll => !prevSelectAll);
    
        const newSelectedUsers = {};
        userData?.data?.forEach(user => {
            newSelectedUsers[user.id] = !selectAll;
        });
        setSelectedUsers(newSelectedUsers);
    };
    

    const handleSelectCourse = (course) => {
        if (selectedCourses.includes(course)) {
            setSelectedCourses(selectedCourses.filter(selCourse => selCourse !== course));
        } else {
            setSelectedCourses([...selectedCourses, course]);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleEnroll = async () => {
        const coursesData = selectedCourses.map(course => ({
            id: course.id,
            title: course.title,
            type: "course"
        }));
    
        const employeesData = Object.keys(selectedUsers)
            .filter(userId => selectedUsers[userId])
            .map(userId => ({
                id: userId,
                type: "employee"
            }));

        await postData({
            courses: coursesData,
            employees: employeesData
        });
    };

    return (
        <Fragment>
            <div className="overflow-hidden mb-4">
                <Row className="align-items-center">
                    <Col lg={6} md={6} sm={6} className="py-2">
                        <Form.Group>
                            <Form.Control
                                type="search"
                                placeholder="Search Course"
                                value={searchValue}
                                onChange={onSearchChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6} md={6} sm={6} className="py-2 d-flex justify-content-end">
                        <Button variant="primary" onClick={() => setShowModal(true)} disabled={Object.values(selectedUsers).every(val => !val)}>
                            Select Course(s)
                        </Button>
                    </Col>
                </Row>
            </div>

            <div className="table-responsive overflow-y-hidden">
                <Table hover className="text-nowrap table-centered">
                    <thead className="table-light">
                        <tr>
                            <th scope="col">
                                <Form.Check
                                    type="checkbox"
                                    id="checkbox-select-all"
                                    checked={selectAll}
                                    onChange={handleSelectAll}
                                />
                            </th>
                            <th scope="col">Image</th>
                            <th scope="col">Full Name</th>
                            <th scope="col">Mobile</th>
                            <th scope="col">Email</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userData?.data && userData?.data.map((employee, index) => (
                            <tr key={employee.id}>
                                <td>
                                    <Form.Check
                                        type="checkbox"
                                        id={`checkbox-${employee.id}`}
                                        checked={selectedUsers[employee.id] || false}
                                        onChange={() => handleCheckboxChange(employee.id)}
                                    />
                                </td>
                                <td>
                                    <Image
                                        src={employee.avatar}
                                        className="rounded-circle avatar-xl mb-3"
                                        alt=""
                                    />
                                </td>
                                <td><p>{`${employee.firstName} ${employee.lastName}`}</p></td>
                                <td><p>{employee.mobile}</p></td>
                                <td><p>{employee.email}</p></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>

            {/* Pagination @ Footer */}
            <NewPagination
                currentPage={currentPage}
                lastPage={lastPage}
                onPageChange={handlePageChange}
            />

            {/* Modal for selecting course */}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Select Courses</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: '300px', overflowY: 'auto' }}>
                    <Row>
                        {/* Column for Courses */}
                        <Col md={6}>
                            <h5>Select Courses</h5>
                            {courseData?.data && courseData?.data.map(course => (
                                <Form.Check
                                    key={course.id}
                                    type="checkbox"
                                    id={`checkbox-course-${course.id}`}
                                    label={course.title}
                                    checked={selectedCourses.includes(course)}
                                    onChange={() => handleSelectCourse(course)}
                                />
                            ))}
                        </Col>

                        <Col md={6}>
                            <h5>Select Users</h5>
                            {Object.keys(selectedUsers).map(userId => {
                                const user = userData?.data?.find(user => user.id === parseInt(userId));
                                return (
                                    <Form.Check
                                        key={`selected-user-${userId}`}
                                        type="checkbox"
                                        id={`checkbox-user-${userId}`}
                                        label={`${user.firstName} ${user.lastName}`}
                                        checked={selectedUsers[userId]}
                                        onChange={() => handleCheckboxChange(userId)}
                                    />
                                )})
                            }
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <AileadButton type="button" variant="secondary" onClick={handleCloseModal} text='Close' />
                    <AileadButton type="button" variant="primary" text="Enroll Selected" onClick={handleEnroll} loading={loading} disabled={selectedCourses.length === 0} />
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default EnrollTable;
