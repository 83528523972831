// import node module libraries
import { Card, Form } from 'react-bootstrap';

import { useFetchData } from 'hooks/useFetchData';
import { usePostData } from 'hooks/usePostData';
import { useEffect, useRef, useState } from 'react';
import RichTextEditor from 'components/elements/new-editor/RichTextEditor';
import ModuleSelect from './modals/ModuleSelect';
import AileadButton from 'components/marketing/common/button/AileadButton';
import LessonSelect from './modals/LessonSelect';


const QuizDetail = (props) => {
	const { next, formData, handleInputChange } = props;
	const { data } = useFetchData('/course');
	const {response: validationResponse, loading: loadingValidation, error: validationErrors, postData} = usePostData('/course/validate/quiz');

	const nextStep = () => {
		postData({
			"title": formData.step1.title,
			"description": formData.step1.description && formData.step1.description,
			"course": formData.step1.course,
			"module": formData.step1.module,
			"lesson": formData.step1.lesson,
			"type": formData.step1.type,
			"isGradable": formData.step1.isGradable,
			"maxAttempt": formData.step1.maxAttempt,
		});
	}

	useEffect(() => {
		if(validationResponse !== null && validationResponse?.status === "Success"){
		next();
		}
	}, [validationResponse])


	return (
		<>
			{/* Card */}
			<Card className="mb-3 ">
				<Card.Header className="border-bottom px-4 py-3">
					<h4 className="mb-0">Quiz Detail</h4>
				</Card.Header>
				{/* Card body */}
				<Card.Body>
					{/* Title  */}
					<Form.Group className="mb-3">
						<Form.Label htmlFor="courseTitle">Quiz Title <Form.Text className='text-danger'>*</Form.Text></Form.Label>
						<Form.Control
							type="text"
							placeholder="Quiz Title"
							id="quiz_title"
							name="quiz_title"
							value={formData.step1.title}
							onChange={(e) => handleInputChange('title', e.target.value)}
						/>

						{validationErrors && validationErrors?.errors?.title ? (
							<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
								{validationErrors?.errors?.title[0]}
							</Form.Text>
						) : (
							<> 
								Write a 255 or less character quiz description. 
							</>
						)}
					</Form.Group>

					{/* Is Gradable */}
					<Form.Group className="mb-3">
						<Form.Label> Is this quiz Gradable? </Form.Label>
						<Form.Check
							name="radios"
							type="checkbox"
							className="form-switch form-switch-price"
							checked={formData.step1.isGradable}
							onChange={(e) => handleInputChange('isGradable', e.target.checked)}
						/>
					</Form.Group>


					{/* Quiz Type */}
					<Form.Group className="mb-3">
						<Form.Label>Select Quiz Type <Form.Text className='text-danger'>*</Form.Text></Form.Label>
						<Form.Select
							placeholder='Select Quiz Type' 
							value={formData.step1.type}
							onChange={(e) => handleInputChange('type', e.target.value)}
						>
							<option value=""></option>
							{[{label: 'Gradable Quiz', value: 'gradable'}, {label: 'None Gradable Quiz', value: 'non-gradable'}, {label: 'Course Assessment Quiz', value: 'assessment'}].map((item) => (
								<option key={item.value} value={item.value}>
								  {item.label}
								</option>
							))}
						</Form.Select>
						{validationErrors && validationErrors?.errors?.type ? (
							<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
								{validationErrors?.errors?.type[0]}
							</Form.Text>
						): (
							<>
								Select the course that is related to this quiz.
							</>
						)}
					</Form.Group>

					{/* Category */}
					<Form.Group className="mb-3">
						<Form.Label>Select Course <Form.Text className='text-danger'>*</Form.Text></Form.Label>
						<Form.Select
							placeholder='Select Course' 
							value={formData.step1.course}
							onChange={(e) => handleInputChange('course', e.target.value)}
						>
							<option value=""></option>
							{data?.data && data?.data?.unpublished?.data.map((item) => (
								<option key={item.id} value={item.id}>
								  {item.title}
								</option>
							))}
						</Form.Select>
						{validationErrors && validationErrors?.errors?.course ? (
							<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
								{validationErrors?.errors?.course[0]}
							</Form.Text>
						): (
							<>
								Select the course that is related to this quiz.
							</>
						)}
					</Form.Group>

					<ModuleSelect
						module={formData.step1.module} 
						handleModule={(e) => handleInputChange('module', e.target.value)}
						validationErrors={validationErrors}
						courseId={formData.step1.course}
					/>

					<LessonSelect
						lesson={formData.step1.lesson} 
						handleModule={(e) => handleInputChange('lesson', e.target.value)}
						validationErrors={validationErrors}
						moduleId={formData.step1.module}
					/>

					{/* <Form.Group className="mb-3">
						<Form.Label>Passing Score </Form.Label>
						<Form.Control
							type="text"
							placeholder="Passing Score"
							id="quiz_passing_score"
							name="quiz_passing_score"
							value={formData.step1.passingScore}
							onChange={(e) => handleInputChange('passingScore', e.target.value)}
						/>
						
						{validationErrors && validationErrors.passingScore ? (
							<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
								{validationErrors.passingScore[0]}
							</Form.Text>
						): (<> Maximum score required to pass this quiz <Form.Text className='text-danger'> default is 70%</Form.Text> </>)}

					</Form.Group> */}

					{/* <Form.Group className="mb-3">
						<Form.Label>Points </Form.Label>
						<Form.Control
							type="text"
							placeholder="Quiz Point"
							id="point_discount"
							name="point_discount"
							value={formData.step1.point}
							onChange={(e) => handleInputChange('point', e.target.value)}
						/>
						
						{validationErrors && validationErrors.point ? (
							<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
								{validationErrors.point[0]}
							</Form.Text>
						): (<>Number of points per question</>)}

					</Form.Group>

					<Form.Group className="mb-3">
						<Form.Label htmlFor="pointTitle">Max Attempt</Form.Label>
						<Form.Control
							type="text"
							placeholder="Max Attempt"
							id="max_attempt"
							name="maxAttempt"
							value={formData.step1.maxAttempt}
							onChange={(e) => handleInputChange('maxAttempt', e.target.value)}
						/>
						
						{validationErrors && validationErrors.maxAttempt ? (
							<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
								{validationErrors.maxAttempt[0]}
							</Form.Text>
						): (<> Number of times required by student to take this quiz if failed. <Form.Text className='text-danger'>default is 2</Form.Text> </>)}

					</Form.Group> */}
					
					{/*Course Description*/}
					<Form.Group className="mb-3">
						<Form.Label>About Quiz</Form.Label>
						<RichTextEditor
							editorValue={formData.step1.description}
							onChange={
								(event, editor) => {
									const newData = editor.getData();
									handleInputChange("description", newData);
								}
							}
						/>
						<Form.Text className="text-muted">
							Any additional information about this quiz.
						</Form.Text>
					</Form.Group>
					{validationErrors?.errors?.description && (
						<Form.Group className="mb-2">
							<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
								{validationErrors?.errors?.description[0]}
							</Form.Text>
						</Form.Group>
					)}

				</Card.Body>
			</Card>
			{/* Button */}
			<AileadButton type="button" variant="primary" onClick={nextStep} loading={loadingValidation} text="Next" />
		</>
	);
};
export default QuizDetail;
