import { useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { notifyError } from 'helper/utils';
import CurriculumCard from '../../add-new-course/dragndrop/CurriculumCard';
import AileadButton from 'components/marketing/common/button/AileadButton';
import { notifySuccess } from 'helper/utils';
import { useNavigate } from 'react-router-dom';
import { courseData } from 'data/dashboard/CourseData';
import { usePostData } from 'hooks/usePostData';

  const UpdateCurriculum = (props) => {
	const { previous, formData, setFormData } = props;

	const navigate = useNavigate();

	const { postData: submitCourse, response: courseResponse, loading: loadingCourse } = usePostData("/course/create"); 

	useEffect(() => {
		const submitData = async () => {
			if (courseResponse && courseResponse.status === "Success") {
				notifySuccess("Update successful!");
				localStorage.removeItem("formDataKey");
				navigate('/dashboard/courses/all-courses');
			}

		};
		
		submitData();

	}, [courseResponse]);


	const validateNext = () => {
		let hasError = false;
		formData.step4.modules.forEach((item, index) => {
			if (item.lessons.length === 0) {
				notifyError("Module " + (index + 1) + " cannot have an empty Lesson");
				hasError = true;
				return;
			}
		});

		// Check if there was an error, and only call next() if there was no error
		if (!hasError) {
			const coursesData = courseData(formData);
			submitCourse(coursesData);
		}
	}


	return (
		<Form>
			<Card className="mb-3 border-0">
				<Card.Header className="border-bottom px-4 py-3">
				<h4 className="mb-0">Update Curriculum</h4>
				</Card.Header>
				<CurriculumCard setFormData={setFormData} formData={formData} />
			</Card>
			<div className="d-flex justify-content-between">
				<AileadButton variant="secondary" type="button" onClick={previous} text="Previous" />
				<AileadButton variant="primary" onClick={validateNext} loading={loadingCourse} text="Submit for Review" type="button" />
			</div>
		</Form>
	);
};
export default UpdateCurriculum;
