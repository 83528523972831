import { useState, useCallback } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import useUserTimezone from './useUserTimezone';


export const useFileUpload = (uploadUrl) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [serverResponse, setServerResponse] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [refreshToken, setRefreshToken] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const {user} = useSelector(state => state.auth);
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const authToken = user && user?.token;

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleUpload = useCallback(
    async (otherFormData) => {
      if (!selectedFile) {
        setUploadError('Please select a file.');
        return;
      }

      try {
        setIsUploading(true);
        setUploadProgress(0);
        setUploadError(null);

        const formData = new FormData();

        // Add dynamically provided form parameters
        for (const key in otherFormData) {
          formData.append(key, otherFormData[key]);
        }

        formData.append('url', selectedFile);

        if (authToken !== null) {
          axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
        }
        // Using Axios for file upload
        const response = await axios.post(BASE_URL+uploadUrl, formData, {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            setUploadProgress(progress);
          },
        });

        setServerResponse(response?.data);

        if (!response.data.status) {
          throw new Error('Upload failed. Please try again.');
        }

        setUploadProgress(100);
      } catch (error) {
        if (error.response && error.response.status === 422) {
          const receivedValidationErrors = error.response.data.errors;
          // Update state with validation errors
          setUploadError(receivedValidationErrors);
        } else {
          setUploadError(error.message);
          console.error('Unexpected Error:', error.message);
        }
      } finally {
        setIsUploading(false);
      }
    },
    [authToken, selectedFile, refreshToken]
  );

  // Function to manually trigger a refresh
  const refreshData = () => {
    setRefreshToken(previous => previous + 1);
  }

  return {
    selectedFile,
    uploadProgress,
    isUploading,
    uploadError,
    serverResponse,
    handleFileChange,
    handleUpload,
    refreshData
  };
};
