import { useDispatch, useSelector } from 'react-redux';
import { settings } from 'redux/actions/appSettings';
import { useFetchData } from 'hooks/useFetchData';
import { useEffect } from 'react';

export const FetchSettings = () => {
  const dispatch = useDispatch();
  const storedSettings = useSelector(state => state.setting.setting);
  const { data } = useFetchData("/settings");
  
  useEffect(() => {
    if (!storedSettings && data && data.data) { // Ensure data and data.data are defined
      dispatch(settings(data.data));
    }
  }, [dispatch, storedSettings, data]);

  return null;
};