import { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';
import { useSelector } from 'react-redux';

const RichTextEditor = ({ editorValue, onChange }) => {

    const BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const { user } = useSelector(state => state.auth);
    const authToken = user && user.token;

    const api_url = BASE_URL;

    const upload_endpoint = 'media/image';

    function uploadAdapter(loader) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const formData = new FormData();
                    loader.file.then((file) => {
                        formData.append('file', file);

                        if (authToken !== null) {
                            axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
                        }
                        axios.post(`${api_url}/${upload_endpoint}`, formData)
                            .then((response) => {
                              resolve({ default: `${response?.data?.data?.url}` })
                            })
                            .catch((error) => {
                                console.log(error);
                                reject(error);
                            });
                    })
                })
            }
        }
    }

    function uploadPlugin(editor) {
      editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return new uploadAdapter(loader)
      }
    }

    function tableClasses(editor) {
        editor.conversion.for('upcast').add((dispatcher) => {
            dispatcher.on('element:table', (evt, data, conversionApi) => {
                const viewElement = data.viewItem;
                conversionApi.writer.addClass('table table-bordered', viewElement);
            });
        });
    }

    return (
        <CKEditor
            config={{
                extraPlugins: [uploadPlugin, tableClasses],
                image: {
                    resizeUnit: 'px',
                    toolbar: ['imageStyle:full', 'imageStyle:side', '|', 'imageResize']
                },
                imageResize: {
                    resizeOptions: [
                        {
                            name: 'resizeImage:original',
                            value: null,
                            icon: 'original'
                        },
                        {
                            name: 'resizeImage:50',
                            value: '50',
                            icon: 'medium'
                        },
                        {
                            name: 'resizeImage:75',
                            value: '75',
                            icon: 'large'
                        }
                    ],
                    toolbar: ['resizeImage:50', 'resizeImage:75', 'resizeImage:original']
                },
                table: {
                    contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
                }
            }}
            editor={ClassicEditor}
            data={editorValue}
            onChange={onChange}
        />
    );

}

export default RichTextEditor;
