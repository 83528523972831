// import node module libraries
import React, { Fragment } from 'react';
import { Col, Row, Table } from 'react-bootstrap';

// import custom components
import NewPagination from 'components/elements/advance-table/NewPagination';

const PaymentTable = ({
    paymentdata,
    handlePageChange,
    currentPage,
    lastPage,
    searchValue,
    onSearchChange
}) => {
	
	return (
		<Fragment>
			<div className="overflow-hidden">
				<Row>
					<Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 py-4 px-5 ">
                    <input
                        type="search"
                        className="form-control"
                        placeholder={"Search Payment"}
                        value={searchValue}
                        onChange={onSearchChange}
                    />
					</Col>
				</Row>
			</div>

			<div className="table-responsive overflow-y-hidden">
				<Table
					hover
					className="text-nowrap table-centered"
				>
					<thead className="table-light">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Payment</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Method</th>
							<th scope="col">Paid At</th>
							<th scope="col">Ref</th>
							<th scope="col">Status</th>
                            <th scope="col"></th>
                        </tr>
					</thead>
					<tbody>
                        {paymentdata?.data && paymentdata?.data.map((payment, index) => (
                            <tr key={payment.id}>
                                <td>{index + 1}</td>
                                <td>{payment.description}</td>
                                <td>{payment.currency}{payment.amount}</td>
                                <td>{payment.method}</td>
                                <td>{payment.paidAt}</td>
								<td>{payment.reference}</td>
								<td>{payment.status}</td>
                                <td></td>
                            </tr>
                        ))}
					</tbody>
				</Table>
			</div>

			{/* Pagination @ Footer */}
			<NewPagination
                currentPage={currentPage}
                lastPage={lastPage}
                onPageChange={handlePageChange}
			/>
		</Fragment>
	);
};

export default PaymentTable;
