import React, {useState} from 'react';

const DropArea = ({title, onDrop }) => {

    const styleShow = {
        width: "100%",
        height: "100%",
        color: "#dcdcdc",
        border: "1px dashed #dcdcdc",
        borderRadius: "10px",
        padding: "15px",
        marginBottom: "15px",
        opacity: 1,
        transition: "all 0.2s ease-in-out"
    }

    const styleHide = {
        opacity: 0
    }

    const [showDrop, setShowDrop] = useState(false);

    return (
      <section 
        onDragEnter={() => setShowDrop(true)}
        onDragLeave={() => setShowDrop(false)}
        onDrop={() => {
                onDrop();
                setShowDrop(false)
            }
        }
        onDragOver={(e) => {
            e.preventDefault();
        }}
        style={showDrop ? styleShow: styleHide}
      >
        Drop {title} Here
      </section>
    );    
};
export default DropArea;
