// import node module libraries
import React, { Fragment } from 'react';
import {
	ListGroup,
} from 'react-bootstrap';
import MailRow from './MailRow';
import { useFetchData } from 'hooks/useFetchData';

const MailBox = () => {

	const {data, refreshData, loading, error} = useFetchData('/mail/received');
	
	return (
		<Fragment>
			<div className="">
				<ListGroup
					variant="flush"
					className={`list-group list-group-mail`}
				>
				    {data?.data?.data?.length > 0 ? (
						data?.data?.data?.map((mail, index) => 
						<ListGroup.Item
							className={`list-group-item-action px-4 list-mail ${
								mail.isRead ? 'bg-light' : ''
							}`}
							key={mail.id}
						>
							<MailRow
								mail={mail}
							/>
						</ListGroup.Item>)) : loading ? (
					<ListGroup.Item className="list-group-item-action px-4 list-mail bg-light">
						Loading mail box, please wait ...... .....
					</ListGroup.Item>) : ( 
					<ListGroup.Item className="list-group-item-action px-4 list-mail bg-light">
						No matching mails found.
					</ListGroup.Item>)}
				</ListGroup>
			</div>
			{/* Add pagination here */}
		</Fragment>
	);
};

export default MailBox;
