// usePaystack.js
import React from 'react';
import { usePaystackPayment } from 'react-paystack';
import AileadButton from 'components/marketing/common/button/AileadButton';

export function usePaystack() {
  const PaystackComponent = ({ onSuccess, onError, amount, email }) => {
    const paystackPublicKey = process.env.REACT_APP_PAYSTACK_TEST_PUB_KEY;

    const config = {
      reference: new Date().getTime(),
      email: email,
      amount: amount * 100,
      publicKey: paystackPublicKey,
    };

    const initializePayment = usePaystackPayment(config);

    return (
			<AileadButton type="button" variant="primary" onClick={() => initializePayment(onSuccess, onError)} text="Make a Payment" />
    );
  };

  return PaystackComponent;
}
