import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

// Import sub/custom components
import Question from 'components/marketing/common/quiz/Question';
import QuizProgress from './QuizProgress';
import QuizPagination from './QuizPagination';
import QuizTimer from './QuizTimer';

import { usePostData } from 'hooks/usePostData';
import { notifyError } from 'helper/utils';

const QuizStart = () => {
    const location = useLocation();
    const { attempts } = location.state || {};
    const navigate = useNavigate();

    const { response: nextResponse, postData: nextQuiz } = usePostData("/quiz/question/next");
    const { response: answerResponse, postData: answerQuiz } = usePostData("/quiz/save/response");

    const [attempt, setAttempt] = useState(attempts);
    const [currentQuestion, setCurrentQuestion] = useState(attempts.question);
    const [options, setOptions] = useState(attempts.options);
    const [timeLeft, setTimeLeft] = useState(attempts.timeRemaining);
    const [questionIndex, setQuestionIndex] = useState(attempts.currentQuestionIndex);
    const [totalQuestions, setTotalQuestions] = useState(attempts.totalQuestion);
    const [selectedOptionId, setSelectedOptionId] = useState(null);

    useEffect(() => {
        if (nextResponse?.data?.status === true) {
            const nextData = nextResponse?.data?.data;
            setCurrentQuestion(nextData?.question);
            setOptions(nextData?.options);
            setTimeLeft(nextData?.question?.timer);
            setTotalQuestions(nextData?.totalQuestion); // Make sure this is updated based on the response
            setQuestionIndex(prev => prev + 1);
        }else if (nextResponse?.data?.completed === true) {
            navigate("/student/quiz/result");
        }
    }, [nextResponse?.data?.status, nextResponse?.data?.completed]);

    const loadNextQuestion = async (attemptId) => {
        nextQuiz({ id: attemptId });
    };

    const handleNextPage = async () => {
        // Save the user's answer
        await answerQuiz({
            optionId: selectedOptionId,
            quizId: currentQuestion.quizId,
            questionId: currentQuestion.id
        });

        // Reset the selected option after the response is saved
        setSelectedOptionId(null);

        // Load the next question
        loadNextQuestion(attempt.attemptId);
    };

    const handleAnswerChange = (selectedValue) => {
        setSelectedOptionId(selectedValue);
    };

    return (
        <Row>
            <Col md={4}>
                <Card className="mb-4 mt-7 me-2 ms-2">
                    <Card.Body>
                        {/* Question Title + Timer */}
                        <div className="d-flex justify-content-between align-items-center border-bottom pb-3 mb-3">
                            <div className="d-flex align-items-center">
                                <div className="ms-3">
                                    <h3 className="mb-0">
                                        <p dangerouslySetInnerHTML={{ __html: currentQuestion?.text }}></p>
                                    </h3>
                                </div>
                            </div>
                            <QuizTimer
                                timer={timeLeft}
                                onTimeEnd={handleNextPage}
                            />
                        </div>

                        {/* Progress */}
                        <QuizProgress
                            currentQuestion={questionIndex}
                            totalQuestions={totalQuestions}
                        />

                        {/* Question(s) */}
                        <div className="mt-5">
                            <span>Question {questionIndex + 1}</span> {/* Display 1-based index */}
                            <p dangerouslySetInnerHTML={{ __html: currentQuestion?.text }}></p>
                            <Question item={currentQuestion} onAnswerChange={handleAnswerChange} />
                        </div>

                        {/* Quiz Pagination */}
                        <div className="mt-5">
                            <QuizPagination
                                lastPages={totalQuestions}
                                currentPage={questionIndex}
                                onNextPage={handleNextPage}
                            />
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default QuizStart;