// import node module libraries
import { Fragment, useState } from 'react';
import { Col, Row, Card, Tab, Breadcrumb } from 'react-bootstrap';

// import custom components
import GridListViewButton from 'components/elements/miscellaneous/GridListViewButton';

// import sub components
import StudentsGridCard from './StudentsGridCard';
import { useSelector } from 'react-redux';
import { useServerFilter } from 'hooks/useServerFilter';
import axios from 'axios';
import AileadButton from 'components/marketing/common/button/AileadButton';
import AddNewEmployee from './AddNewEmployee';

const Students = () => {
	const BASE_URL = process.env.REACT_APP_API_BASE_URL;
	const {user} = useSelector(state => state.auth);
	const authToken = user && user?.token;
	const [show, setShow] = useState(false);

	const handleClose = () => {
		setShow(false);
	}
	const handleShow = () => {
		setShow(true);
	}

	const fetchUsers = async (searchQuery, pageNumber) => {
		if (authToken !== null) {
			axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
			const response = await axios.get(`${BASE_URL}/business/employees?page=${pageNumber}&searchQuery=${searchQuery}`);
			return response.data;
		}
	};

	const {
		filteredData, 
		searchQuery,
		pageNumber,
		handlePageChange, 
		handleSearchChange
	} = useServerFilter('', fetchUsers);

	const lastPage = filteredData && filteredData?.data && filteredData?.data?.pagination?.lastPage;
	const totalStudent = filteredData && filteredData?.data && filteredData?.data?.pagination?.total;

	return (
		<Fragment>
			<Tab.Container defaultActiveKey="grid">
				<Row>
					<Col lg={12} md={12} sm={12}>
						<div className="border-bottom pb-4 mb-4 d-flex align-items-center justify-content-between">
							<div className="mb-3 mb-md-0">
								<h1 className="mb-1 h2 fw-bold">
									Students <span className="fs-5 text-muted">({totalStudent})</span>
								</h1>
								<Breadcrumb>
									<Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
									<Breadcrumb.Item href="#">User</Breadcrumb.Item>
									<Breadcrumb.Item active>Students</Breadcrumb.Item>
								</Breadcrumb>
							</div>
							<AileadButton onClick={handleShow} className="btn btn-outline-dark ms-2" text='Add Employee' />
						</div>
					</Col>
				</Row>
				<Tab.Content>
					<Tab.Pane eventKey="grid" className="pb-4">
						{/* students in list view */}
						<StudentsGridCard 
							userData={filteredData}
							lastPage={lastPage}
							currentPage={pageNumber}
							handlePageChange={handlePageChange}
							searchQuery={searchQuery}
							handleSearchChange={handleSearchChange}
						/>
						{/* end of students in list view */}
					</Tab.Pane>
				</Tab.Content>
			</Tab.Container>
			<AddNewEmployee show={show} handleClose={handleClose} />
		</Fragment>

	);
};
export default Students;
