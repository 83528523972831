import React, { Fragment } from 'react';
import { Avatar } from 'components/elements/bootstrap/Avatar';
import { useFetchData } from 'hooks/useFetchData';

const ChatHeader = ({ groupId }) => {
	const { data: groupData } = useFetchData(`/group/item/${groupId}`);

	return (
		<Fragment>
			<div className="bg-white border-top border-bottom px-4 py-3 sticky-top">
				<div className="d-flex justify-content-between align-items-center">
					<div className="d-flex align-items-center">
						<Avatar
							size="md"
							className="rounded-circle"
							type={groupData?.icon ? 'image' : 'initial'}
							src={groupData?.icon}
							status={groupData?.status.toLowerCase()}
							alt={groupData?.fullName}
							name={groupData?.fullName}
						/>
					</div>
					{/* <div>
						<GKTippy content="Create New Thread">
							<i
							className="fe fe-plus text-muted ms-3 cursor-pointer"
							onClick={handleShowModal}
							style={{ fontSize: '1.5rem' }}
							></i>
						</GKTippy>
					</div> */}
				</div>
			</div>
		</Fragment>
	);
};
export default ChatHeader;
