// reducer.js
import { APP_SETTINGS } from '../actions/appSettings';

const initialState = {
  setting: JSON.parse(localStorage.getItem('global_setting')) || null,
};

const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case APP_SETTINGS:
      return {
        ...state,
        setting: action.setting,
      };
    default:
      return state;
  }
};

export default settingReducer;
